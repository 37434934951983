import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { addChannel } from 'services/state/actions/channelActions';
import { RootState, useAppDispatch } from 'services/state/store/configureStore';

interface FormValues {
  channel_id: string;
  channel_name: string;
}

const initialValues: FormValues = {
  channel_id: '',
  channel_name: '',
};

const validate = (values: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {};
  if (!values.channel_id) {
    errors.channel_id = 'Required';
  } else if (values.channel_id.length !== 24) {
    errors.channel_id = 'The YouTube channel ID must be 24 characters';
  }
  if (!values.channel_name) {
    errors.channel_name = 'Required';
  }
  return errors;
};

interface ChannelFormProps {
  handleClose?: () => void;
}

const useChannelForm = ({ handleClose }: ChannelFormProps = {}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.channel);
  const dispatch = useAppDispatch();

  const multipleArray = [
    'Tester',
    'Creator',
    'Broadcast',
    'Enterprise',
    'personamusic_tier2',
    'personamusic_tier2_2',
    'personamusic_tier3',
    'personamusic_tier4_3',
  ];
  const multiple = multipleArray.includes(user.subscription?.type);

  const handleSubmit = async (
    values: FormValues,
    { setErrors, resetForm }: FormikHelpers<FormValues>
  ) => {
    const message = `Once this channel is added, it can not be removed.  Are you sure?`;

    try {
      if (multiple || window.confirm(message)) {
        await dispatch(addChannel(values));
      }
      handleClose?.();
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((err) => setErrors({ [err[0]]: err[1] }));
      }
      resetForm();
      console.error(error);
    }
  };
  const formik = useFormik({ initialValues, validate, onSubmit: handleSubmit });

  return { formik, loading, multiple };
};

export default useChannelForm;
