import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledBanner = styled.div`
  background: #ffbc00;
  padding-inline: 0.8rem;
  color: #000;
  font-size: 1rem;
  block-size: var(--banner-height);
  display: grid;
  place-items: center;
  font-weight: bold;

  @media ${device.mobile} {
    font-size: 0.625rem;
    padding-inline: 0.55rem;
  }
`;
