import styled from 'styled-components';

export const StyledSortSelect = styled.select`
  display: block;
  background: #1d1d1d;
  border: 0;
  color: #888;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0 0.8rem;
  font-family: inherit;
  letter-spacing: 0.5px;
  height: 32px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
`;
