const initialState = {
  partner: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PARTNER_PRELOAD':
      return {
        ...state,
        partner: action.payload,
      };

    case 'GET_PARTNER_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_PARTNER_SUCCESS':
      const imageDetails = {
        image_src: action.payload.image_src || state.partner?.image_src,
        defaultImg: !action.payload.image_src,
      };
      return {
        ...state,
        partner: { ...action.payload, ...imageDetails },
        loading: false,
        error: null,
      };

    case 'GET_PARTNER_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CLEAR_PARTNER':
      return {
        ...state,
        partner: null,
      };

    default:
      return state;
  }
};

export default reducer;
