import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledMoreButton = styled.div`
  margin: 3.5rem 0;
  text-align: center;
  position: relative;

  .offset-intersect {
    position: absolute;
    block-size: 0;
    inline-size: 0;
    inset-block-start: max(-30rem, -400px);
  }

  button {
    inline-size: 9.2rem;
    opacity: 0.7;
  }
  @media ${device.mobile} {
    padding-inline-end: 0;
  }
`;
