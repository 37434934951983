import axios, { download } from './config/axiosConfig';

export const getDownloads = async () => {
  try {
    const res = await axios.get(`/api/accounts/downloads`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const downloadTrack = async (track, payment_intent_id) => {
  try {
    const res = await axios.get(`/api/content/tracks/${track.id}/download`, {
      params: { payment_intent_id },
    });
    if (!res.data?.asset_src) {
      throw new Error('Something went wrong');
    }
    return download(res.data.asset_src);
  } catch (error) {
    throw error;
  }
};
