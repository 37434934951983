import React, { useState } from 'react';
import { StyledFAQItem } from './FAQSection.styled';
import AnimateHeight from 'react-animate-height';
import { ReactComponent as Plus } from 'assets/images/icons/plus.svg';

interface FAQItemProps {
  item: {
    q: string;
    a: string[];
  };
  defaultOpen?: boolean;
}

const FAQItem: React.FC<FAQItemProps> = ({ item, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <StyledFAQItem $active={open}>
      <div className="question">
        {item.q}
        <button onClick={() => setOpen((o) => !o)} className="open">
          <Plus />
        </button>
      </div>
      <AnimateHeight duration={160} height={open ? 'auto' : 0}>
        <div className="answer">
          {item.a.map((a, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: a }} />
          ))}
        </div>
      </AnimateHeight>
    </StyledFAQItem>
  );
};

export default FAQItem;
