import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { device } from 'assets/styles/device';

export const StyledLandingQualityMusic = styled(StyledLandingSection)`
  .section-content {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(10, auto);
    gap: 0 5.875rem;

    h2 {
      margin-block-start: 0;
    }

    .bullet-points {
      margin-block: 1.5rem;
    }

    .media {
      inline-size: 100%;
      block-size: 100%;
      border-radius: 2rem;
      overflow: hidden;
      grid-column: 2/2;
      grid-row: 1/10;

      img {
        inline-size: 100%;
        block-size: 100%;
        object-fit: cover;
      }
    }
  }

  @media ${device.mobile} {
    text-align: center;

    .section-content {
      gap: 0;
      grid-template-columns: initial;

      .bullet-points {
        margin-block: 0.75rem 0;
      }

      .media {
        block-size: auto;
        margin-block-end: 2rem;
        grid-column: auto;
        grid-row: auto;
      }
    }
  }
`;
