import { StyledLandingSubscribe } from './SubscribeSection.styled';
import SubscribeForm from './SubscribeForm';

const SubscribeSection = () => (
  <StyledLandingSubscribe>
    <div className="section-header">
      <h2>Stay Tuned - Subscribe To Our Newsletter!</h2>
      <h4>
        Get the inside track on exclusive offers, new releases, and insider
        updates.
      </h4>
    </div>
    <div className="section-content">
      <SubscribeForm />
    </div>
  </StyledLandingSubscribe>
);

export default SubscribeSection;
