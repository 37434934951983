import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledUserMenu } from 'components/Layout/Header/Menu/UserMenu.styled';
import { ReactComponent as Menu } from 'assets/images/icons/menu.svg';
import { HEADER_NAV } from 'constants/navigation';
import Dropdown from 'components/Dropdown/Dropdown';

const SiteMenu: React.FC = () => (
  <StyledUserMenu>
    <Dropdown target={<Menu />}>
      {HEADER_NAV.map((item) => (
        <NavLink key={item.title} to={item.path}>
          {item.title}
        </NavLink>
      ))}
      <NavLink to="/pricing">Pricing</NavLink>
      <NavLink to="/faq">FAQ</NavLink>
    </Dropdown>
  </StyledUserMenu>
);
export default SiteMenu;
