import { createGlobalStyle } from 'styled-components';
import { device } from './device';

export const GlobalStyles = createGlobalStyle`
  html {
    /* --header-height: 8rem; */
    --header-height: 8rem;
    --search-height: 3.5rem;
    --banner-height: 2.625rem;
  }
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  *, *::before, *::after {
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  
  body {
    background: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.textPrimary};
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
    width: 100%;

    &.freeze {
      position: fixed;
      
      &.with-scroll {
        overflow-y: scroll;
      }
    }

  }

  svg, img {
    max-inline-size: 100%;
    max-block-size: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: ${({ theme }) => theme.textHighlight};
  }

  h1 {
    font-weight: 900;
    font-size: 3.5rem;
    margin: 1rem 0;
    line-height: 120%;
  }

  h2 {
    font-size: 2.4rem;
    margin: 1.5rem 0;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  h4 {
    margin-bottom: 1.5rem;
  }

  p {
    margin: 0 0 1rem;
    line-height: 1.5rem;
  }

  a {
    color: inherit;
  }
  
  p a {
    text-decoration: underline;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  button {
    font-size: 1rem;
    border: 0;
    cursor: pointer;
    padding: 0;
    outline-offset: 4px;
    background: none;
    color: inherit;
  }
  
  button:focus:not(:focus-visible) {
    outline: none;
  }

  select:focus:not(:focus-visible) {
    outline: none;
  }
  
  button + button {
    margin-left: 1.5rem;
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .center {
    text-align: center;
  }

  .no-content {
    text-align: center;
  }


  .highlight {
    color: ${({ theme }) => theme.textHighlight};
  }

  .content-loading {
    --loading-background-color: #fff1;
    color: transparent;
    background-color: var(--loading-background-color) !important;
    background-image: linear-gradient(90deg, var(--loading-background-color), #ffffff08 48%, #ffffff08 52%, var(--loading-background-color)) !important;
    background-repeat: no-repeat !important;
    background-position: -100vw;
    background-size: 100vw !important;
    animation: loader 2s linear infinite !important;
  }
  h1, h2, h3, h4, h5, p {
    &.content-loading {
      clip-path: inset(15% 0);
    }
  }
  @keyframes loader {
    0% {
      background-position: -100vw;
    }
    80% {
      background-position: 100vw;
    }
    100% {
      background-position: 100vw;
    }
  }

  // ReactDOM.createPortal element
  #modal {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 999999;
    overflow: auto;

    &:before {
      content: '';
      position: fixed;
      inset: 0;
      backdrop-filter: blur(0);
      background-color: none;
      transition: all 0.4s;
    }
    &.backdrop {
      &:before {
        backdrop-filter: blur(3px);
        background-color: rgba(15, 15, 15, 0.8);
        transition: all 0.05s;
      }
    }
    &.secondary {
      overflow: hidden;
    }
  }

  // Tooltip portal global styles
  .ToolTipPortal {
    --tooltip-background: #282828;

    & > div {
      background: var(--tooltip-background) !important;
      padding: 6px 12px !important;
      transition: none !important;
      font-size: 0.8rem;
      font-weight: bold;
      border: 0;
      z-index: 9999999999 !important;

      span {
        transition: none !important;
      }
    }
  }

  // toastr global styles
  #toast-container.toast-bottom-center .toast {
    --toast-color: 255, 255, 255;
    --toast-bar-opacity: 0.4;
    background-position: initial;
    opacity: 1;
    background-color: #111;
    border-radius: 0;
    margin-bottom: 0;
    margin-top: 4px;
    opacity: 1;
    width: 420px;
    max-width: 100%;
    padding: 24px 30px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    background-position: 0% 50%;
    background-size: 270%;
    background-image: linear-gradient( 59deg, transparent 48%, white 0, white 48%, white 50%, rgba(var(--toast-color), 0.15) 50%, transparent 80% ) !important;
    border-bottom: 3px solid rgba(var(--toast-color), var(--toast-bar-opacity));
    animation: shine 400ms 300ms forwards;

    .toast-message {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        margin-right: 24px;
        flex-shrink: 0;
        color: rgba(var(--toast-color), 1);
      }
      p {
        margin: 0;
        opacity: 0.9;
      }
    }
    &.toast-success {
      --toast-color: 0, 255, 0;
    }
    &.toast-info {
      --toast-color: 100, 142, 255;
      --toast-bar-opacity: 0.5;
    }
    &.toast-warning {
      --toast-color: 255, 255, 0;
    }
    &.toast-error {
      --toast-color: 255, 0, 0;
      --toast-bar-opacity: 0.5;
    }
  }

  @keyframes shine {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

  // Tooltip global styles 
  .__react_component_tooltip {
    background: #101116 !important;
    color: ${({ theme }) => theme.textPrimary} !important;
    border: 0 !important;
    font-weight: bold;
    transition: none !important;
  }
  .__react_component_tooltip.show {
    opacity: 1 !important;
  }
  .__react_component_tooltip:after {
    border-top-color: #101116 !important;
  }

  @media ${device.laptop} { 
    html { 
      --header-height: 6rem;
      font-size: 14px;
    }
  }
  @media ${device.mobile} { 
    html { 
      --header-height: 5.75rem;
      --search-height: 2.5rem;
      --banner-height: 1.875rem;
      font-size: 16px;
    }
  } 

`;
