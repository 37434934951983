import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledStars = styled.div`
  display: flex;
  gap: 0.375rem;
  color: #ffbc00;

  & > svg {
    block-size: 0.8125rem;
  }
`;

export const StyledAppSumoRating = styled.div`
  color: #fff;
  display: inline-block;
  align-items: center;
  font-size: 1rem;
  gap: 0.625rem;
  line-height: 150%;

  ${StyledStars} {
    display: inline-flex;
    margin-inline-end: 0.75rem;
  }

  .appsumo-logo {
    block-size: 1rem;
    color: #ffbc00;
    vertical-align: middle;
    margin-block-start: -2px;
    margin-inline-start: 0.5rem;
  }

  .mobile {
    display: none;
  }

  @media ${device.mobile} {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline;
    }

    .best-text {
      font-weight: inherit;
    }

    &:not(.best) {
      text-align: center;
      font-size: 0.75rem;

      ${StyledStars} {
        gap: 0.125rem;

        & > svg {
          inline-size: 0.75rem;
          block-size: 0.65rem;
        }
        margin-inline-end: 0.4rem;
      }

      .appsumo-logo {
        block-size: 0.75rem;
        margin-inline-start: 0.375rem;
      }
    }
  }
`;
