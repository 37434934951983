const initialState = {
  playlist: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PLAYLIST_PRELOAD':
      return {
        ...state,
        playlist: action.payload,
      };

    case 'GET_PLAYLIST_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_PLAYLIST_SUCCESS':
      const imageDetails = {
        image_src: action.payload.image_src || state.playlist?.image_src,
        defaultImg: !action.payload.image_src,
      };
      return {
        ...state,
        playlist: { ...action.payload, ...imageDetails },
        loading: false,
        error: null,
      };

    case 'GET_PLAYLIST_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CLEAR_PLAYLIST':
      return {
        ...state,
        playlist: null,
      };

    default:
      return state;
  }
};

export default reducer;
