import { StyledIconButton } from 'components/Button/IconButton/IconButton.styled';
import styled from 'styled-components';
import { device } from 'assets/styles/device';

interface StyledTrackListProps {
  $offset?: number;
}

interface StyledTrackProps {
  $compact?: boolean;
  $highlighted?: boolean;
  $index?: number;
}

interface StyledTrackDetailsProps {
  $art?: string;
  $compact?: boolean;
}

interface StyledBlobProps {
  $bpm?: number;
}

export const StyledTrackList = styled.div<StyledTrackListProps>`
  width: 100%;
  margin-top: ${({ $offset }) => ($offset ? `-2.5rem` : 0)};

  .list-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .list-filters {
      display: none;
      margin-right: 24px;
      border-radius: 3px;
      background: #1d1d1d;
      padding: 4px;
      box-sizing: initial;
      color: #888;
      cursor: pointer;
    }
  }
  @media ${device.mobile} {
    .list-controls {
      margin-bottom: 1rem;

      .list-filters {
        display: block;
      }
    }
  }
`;

export const StyledTrack = styled.div<StyledTrackProps>`
  --content-space: 6%;
  display: ${({ $compact }) => ($compact ? 'flex' : 'grid')};
  grid-template-columns: 38% 6rem minmax(40px, 33%) max-content;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  inline-size: 100%;
  block-size: 5rem;
  padding: 0 1rem;
  box-shadow: ${({ $highlighted }) =>
    $highlighted && '0px 80px 62px -48px inset #ffffff18'};
  border-top: ${({ $index }) => $index !== 0 && `1px solid #ffffff22`};
  color: ${({ $highlighted, theme }) => $highlighted && theme.textHighlight};
  opacity: 0;
  margin-inline-start: 2rem;
  transition: opacity 0.3s, margin 0.3s;

  &.visible {
    opacity: 1;
    margin-inline-start: 0;
  }

  &:first-child {
    border-color: transparent;
  }
  button + button {
    margin-left: 4px;
  }

  &.loading {
    border-color: transparent;

    .details {
      flex: 1;

      .title {
        height: 16px;
        width: 100%;
        margin-bottom: 8px;
      }
      .artist {
        height: 12px;
        width: 80%;
      }
    }
    .tag {
      height: 10px;
      width: 40px;
    }
    .track-length {
      height: 10px;
    }
    .controls {
      --loading-control-size: 20px;
      display: grid;
      grid-template-columns: repeat(6, var(--loading-control-size));
      height: var(--loading-control-size);
      gap: 10px;
      padding: 0 2px;
      justify-self: right;

      > div {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media ${device.mobile} {
    block-size: 4.5rem;
    grid-template-columns: 1fr max-content;
    padding: 0;
    border-color: transparent;
  }
`;

export const StyledLoadingTrack = styled.div`
  width: 100%;
  padding: 0.5rem 0;

  &:not(:first-child) {
    border-top: 1px solid transparent;
  }
  > div {
    width: 100%;
    height: calc(60px + 1rem);
  }
`;

export const StyledTrackDetails = styled.div<StyledTrackDetailsProps>`
  --album-size: ${({ $compact }) => ($compact ? 2.25 : 3)}rem;
  --play-size: 1.5rem;
  display: flex;
  align-items: center;
  flex: 2;
  position: relative;

  .album-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;

    .album-art {
      width: var(--album-size);
      height: var(--album-size);
      background: url(${({ $art }) => $art});
      background-color: #222;
      background-size: cover;
      background-position: center;
      flex-shrink: 0;
      position: relative;
      margin-right: 1.2rem;

      .premium-icon {
        color: gold;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        position: absolute;
        left: -6px;
        top: -6px;
        background: #000;
        padding: 4px;
      }
    }
    .play-button {
      color: ${({ theme }) => theme.textHighlight};
      position: relative;
      cursor: pointer;
      width: var(--play-size);
      height: var(--play-size);
      flex-shrink: 0;

      svg {
        width: 100%;
        height: 100%;
      }

      &.content-loading {
        color: ${({ theme }) => theme.contentLoading};
      }
    }
  }
  .details {
    .title {
      color: ${({ theme }) => theme.textHighlight};
      margin-bottom: 0.25rem;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;

      &.content-loading {
        color: ${({ theme }) => theme.contentLoading};
      }
    }
    .artist {
      opacity: 0.7;
      font-size: 0.8rem;

      &.content-loading {
        color: ${({ theme }) => theme.contentLoading};
      }
      a:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  @media ${device.mobile} {
    --album-size: 2.25rem;

    .album-wrapper {
      margin-right: 1rem;

      .album-art {
        margin-right: 0;
      }

      .play-button {
        position: absolute;
        width: var(--album-size);
        height: var(--album-size);
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);

        svg {
          width: var(--play-size);
          height: var(--play-size);
        }
      }
    }
    .details {
      .title {
        white-space: initial;
        inline-size: auto;
      }
    }
  }
`;

export const StyledTrackTags = styled.div`
  margin-right: var(--content-space);

  span {
    font-size: 0.625rem;
    opacity: 0.7;
    display: block;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-block-end: 0.125rem;
    }

    &.content-loading {
      color: ${({ theme }) => theme.contentLoading};
    }
    &:hover {
      text-decoration: underline;
    }
  }
  ${StyledIconButton} {
    span {
      margin: 0;
    }
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const StyledControls = styled.div`
  display: flex;
  align-items: center;
  flex: 3;

  .waveform {
    flex: 1;
    height: 30px;
  }
  .track-length {
    width: 3.6rem;
    margin-left: var(--content-space);
    text-align: center;
    font-size: 0.6rem;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

export const StyledUserControls = styled.div`
  justify-self: right;
  align-items: center;

  .badge {
    position: absolute;
    top: -0.15rem;
    right: 0.15rem;
    font-size: 0.8rem;
    opacity: 0.6;
    font-weight: bold;
  }

  svg {
    transition: transform 0.3s cubic-bezier(0, 1, 0.07, 1);
    display: block;

    &.open {
      transform: rotate(180deg);
    }
    &.loading {
      animation: loading 1.4s ease-out infinite;
    }
  }

  @keyframes loading {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(1800deg);
    }
  }

  @media ${device.mobile} {
    .badge {
      font-size: 0.7rem;
      right: 50%;
      transform: translateX(50%);
    }
  }
`;

export const StyledTrackDrawer = styled.div`
  box-shadow: 0px -80px 62px -48px inset #ffffff18;
  font-size: 0.75rem;

  .drawer-details {
    padding: 1.5rem calc(64px + 2.4vw);
    display: flex;
    gap: 0 2rem;

    & > div {
      flex: 1;
    }
    h4 {
      margin-bottom: 0.7rem;
      color: ${({ theme }) => theme.textPrimary};
    }
    .album {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
    .drawer-meta {
      .drawer-meta-item {
        h4 {
          display: inline-block;
          width: 100px;
          margin-right: 24px;
        }
        div {
          display: inline-block;
        }
      }
    }
    .drawer-tags {
      span {
        margin-right: 24px;
        margin-bottom: 4px;
        display: inline-block;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  ${StyledTrackList} {
    ${StyledTrack} {
      border-top: 0;
      opacity: 0.8;

      .album-art {
        --album-size: 30px;
        margin-left: 18px;
        opacity: 0.9;
      }
      .play-button {
        --play-size: 20px;
        margin-inline: 2px;
        opacity: 0.9;
      }
      .details .title {
        font-size: 0.8rem;
      }
    }
  }
  @media ${device.mobile} {
    .drawer-details {
      padding: 1.5rem 16px;
      flex-direction: column;
    }
  }
`;

export const StyledBlob = styled.div<StyledBlobProps>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  transform: scale(2) translate(-50%, -50%);
  animation: pulse-black ${({ $bpm }) => ($bpm ? 60 / $bpm : 2)}s infinite;

  @keyframes pulse-black {
    0% {
      transform: scale(1.6);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(3);
      box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(1.6);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
`;
