import axios, { getWaveform } from './config/axiosConfig';

export const getPlaylist = async (id) => {
  try {
    const res = await axios.get(`/api/content/playlists/${id}`);
    const playlist = res.data;

    if (playlist.tracks) {
      const waveform = getWaveform();
      const tracks = playlist.tracks.map((track) => {
        track.waveform = waveform;
        return track;
      });
      playlist.tracks = tracks;
    }
    return playlist;
  } catch (error) {
    throw error;
  }
};

export const getPlaylists = async ({ limit, ptype, nextPage, order }) => {
  try {
    const res = await axios.get(`/api/content/playlists`, {
      params: { limit, page: nextPage, playlist_type: ptype, order },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
