import acoustic from './acoustic.jpg';
import classical from './classical.jpg';
import country from './country.jpg';
import electronic from './electronic.jpg';
import hiphop from './hiphop.jpg';
import pop from './pop.jpg';

interface Images {
  [key: string]: string;
}

const images: Images = {
  acoustic,
  classical,
  country,
  electronic,
  hiphop,
  pop,
};

export default images;
