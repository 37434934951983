import axios from 'axios';
import { getLsToken, setLsToken, clearLsToken } from 'utils';
import waveformData from 'services/api/data/waveform';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { access } = getLsToken();

    if (access) {
      config.headers['Authorization'] = `Bearer ${access}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Catch if cancelled to not throw error
    if (axios.isCancel(error)) return;

    // TODO: assign site-level error
    // Network error (most likely).  Return.
    if (!error.response?.status || error.response.status === 500) {
      throw new Error('Something went wrong on the server');
    }

    // Return so this can be intercepted to sign a user out
    if (error.response.status === 403) {
      throw error;
    }

    const originalRequest = error.config;
    if (!originalRequest) return;

    const { refresh } = getLsToken();
    const tokenRoute = originalRequest.url.includes('/api/token');

    if (error.response.status === 401 && !tokenRoute && refresh) {
      try {
        // Get new access token with refresh token
        const res = await axiosInstance.post('/api/token/refresh', { refresh });

        if (+res.status >= 200) {
          // Set new access token
          setLsToken(res.data);

          // Re-run original request
          return axiosInstance(originalRequest);
        }
      } catch (error) {
        clearLsToken();
        throw error;
      }
    }
    if (error.response?.data) {
      const data = error.response.data;
      const formErrors = Object.keys(data);

      if (data.detail) {
        throw data.detail;
      }
      if (data.message) {
        throw data.message;
      }
      if (formErrors?.length) {
        throw formErrors.map((key) => [key, data[key][0]]);
      }
    }
    return Promise.reject(error);
  }
);

const getWaveform = (wave) => {
  return wave || waveformData;
};

const getOffset = (str) => {
  if (!str) return 0;
  const url = new URL(str);
  return +url.searchParams.get('offset');
};

const delay = (amount) => new Promise((resolve) => setTimeout(resolve, amount));

const download = (uri, name, external = false) => {
  const link = document.createElement('a');
  link.download = name || uri;
  link.href = uri;
  if (external) {
    link.target = '_blank';
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const root = axios;

export { setLsToken, getWaveform, getOffset, delay, download, root };

export default axiosInstance;
