const SocialMediaTooltip = () => (
  <>
    <h3>Social Media</h3>
    <p>
      For usage on all social and user generated content platforms, including:
    </p>
    <ul>
      <li>Youtube</li>
      <li>Facebook</li>
      <li>Instagram</li>
      <li>Twitch</li>
      <li>Tik Tok</li>
      <li>Vimeo</li>
      <li>Podcasts </li>
      <li>Any future platform</li>
    </ul>
  </>
);

const ExtendedUsageTooltip = () => (
  <>
    <h3>Extended Usage</h3>
    <p>All usage types other than broadcast, including:</p>
    <ul>
      <li>Youtube</li>
      <li>Facebook</li>
      <li>Instagram</li>
      <li>Twitch</li>
      <li>Tik Tok</li>
      <li>Vimeo</li>
      <li>Podcasts</li>
      <li>Any future platform</li>
      <li>Wedding video</li>
      <li>Film festivals</li>
      <li>Websites</li>
      <li>Games</li>
      <li>Applications</li>
      <li>Presentations</li>
      <li className="not">
        Broadcast
        <p>
          (For Broadcast, please see the Broadcast & VOD subscription in
          pricing)
        </p>
      </li>
    </ul>
  </>
);

const PasswordTooltip = () => (
  <p>
    At least 8 characters with at least one number, uppercase letter, lowercase
    letter and special character
  </p>
);

export const usageTooltips = {
  'Social Media': SocialMediaTooltip,
  'Extended Usage': ExtendedUsageTooltip,
};

export const formTooltips = {
  password: PasswordTooltip,
};
