import SearchNoResults from './SearchNoResults';
import SearchResultGroup from './SearchResultGroup';
import SearchResultItem from './SearchResultItem';
import SearchResultPill from './SearchResultPill';

const SearchResultsList = ({
  results,
  term,
  loading,
  activeTags,
  handleTrackPlay,
  handleNav,
  handleSearch,
  handleToggleTag,
}) => {
  const { tracks, artists, albums, playlists } = results;
  const { partners, tags, count } = results;

  return (
    <>
      {!count && <SearchNoResults term={term} />}
      <SearchResultGroup title="Tags" count={tags.length} loading={loading}>
        {tags.map((tag) => (
          <SearchResultPill
            key={tag.id}
            // active={activeTags.some((t) => t.id === tag.id)}
            title={tag.name}
            handleClick={() => handleToggleTag(tag)}
          />
        ))}
      </SearchResultGroup>
      <SearchResultGroup
        title="Tracks"
        loading={loading}
        count={tracks.length}
        handleSearch={() => handleSearch('tracks')}
      >
        {tracks.map((track) => (
          <SearchResultItem
            key={track.id}
            title={track.title}
            text={track.primary_artists.map((a) => a.name).join(', ')}
            image={track.album_cover_src}
            handlePlay={() => handleTrackPlay(track)}
            handleClick={() =>
              handleNav('album', {
                id: track.album_id,
                artists: track.primary_artists,
                title: track.album_title,
                cover_src: track.album_cover_src,
              })
            }
          />
        ))}
      </SearchResultGroup>
      <SearchResultGroup
        title="Artists"
        loading={loading}
        count={artists.length}
        handleSearch={() => handleSearch('artists')}
      >
        {artists.map((artist) => (
          <SearchResultItem
            key={artist.id}
            title={artist.name}
            image={artist.image_src}
            handleClick={() => handleNav('artist', artist)}
            round
          />
        ))}
      </SearchResultGroup>
      <SearchResultGroup
        title="Albums"
        loading={loading}
        count={albums.length}
        handleSearch={() => handleSearch('albums')}
      >
        {albums.map((album) => (
          <SearchResultItem
            key={album.id}
            title={album.title}
            text={album.artists.map((a) => a.name).join(', ')}
            image={album.cover_src}
            handleClick={() => handleNav('album', album)}
          />
        ))}
      </SearchResultGroup>
      <SearchResultGroup
        title="Creators"
        loading={loading}
        count={partners.length}
        handleSearch={() => handleSearch('partners')}
      >
        {partners.map((partner) => (
          <SearchResultItem
            key={partner.id}
            title={partner.name}
            image={partner.image_src}
            handleClick={() => handleNav('creator', partner)}
            round
          />
        ))}
      </SearchResultGroup>
      <SearchResultGroup
        title="Playlists"
        loading={loading}
        count={playlists.length}
        handleSearch={() => handleSearch('playlists')}
      >
        {playlists.map((playlist) => (
          <SearchResultItem
            key={playlist.id}
            title={playlist.title}
            text={playlist.partner?.name}
            image={playlist.image_src}
            handleClick={() => handleNav('playlist', playlist)}
          />
        ))}
      </SearchResultGroup>
    </>
  );
};

export default SearchResultsList;
