import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { device } from 'assets/styles/device';

export const StyledLandingUsedIn = styled(StyledLandingSection)`
  .cards-row {
    block-size: 32vw;
    margin-block-end: 1rem;
  }

  @media ${device.mobile} {
    .cards-row {
      block-size: 46vw;
      margin-block-end: 0.5rem;
    }
  }
`;
