import * as masterclassApi from 'services/api/masterclassApi.js';
import toastr from 'utils/toastr';

const types = {
  GET_MASTERCLASS_LOADING: 'GET_MASTERCLASS_LOADING',
  GET_MASTERCLASS_SUCCESS: 'GET_MASTERCLASS_SUCCESS',
  GET_MASTERCLASS_ERROR: 'GET_MASTERCLASS_ERROR',
  GET_MASTERCLASS_PRELOAD: 'GET_MASTERCLASS_PRELOAD',
};

export const getMasterclassById = (id) => {
  return (dispatch) => {
    dispatch({ type: types.GET_MASTERCLASS_LOADING });
    return masterclassApi
      .getMasterclass(id)
      .then((masterclass) => {
        dispatch({ type: types.GET_MASTERCLASS_SUCCESS, payload: masterclass });
      })
      .catch((error) => {
        toastr.error(`There's no masterclass with ID ${id}`);
        dispatch({ type: types.GET_MASTERCLASS_ERROR, error: error.message });
        throw error;
      });
  };
};

export const preloadMasterclass = (payload) => (dispatch) => {
  dispatch({ type: types.GET_MASTERCLASS_PRELOAD, payload: payload });
};
