import { FC } from 'react';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import ActionArrowButton from 'components/Button/ActionArrowButton/ActionArrowButton';

const OpenSearch: FC = () => {
  return (
    <div className="section-content">
      <div>
        <h2>Open Search</h2>
        <h4>
          Interpret any word, concept, or idea you throw at it and convert that
          into a musical direction
        </h4>
        <div className="media mobile">
          <video controls>
            <source
              src="https://storage.googleapis.com/persona-music-prod-public/Open%20Search%20Demo_Edited.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <p>
          Open Search sifts through the Persona library using sophisticated
          algorithms to match your unique descriptions with sound profiles of
          our music, delivering a selection of tracks that closely align with
          your creative vision, saving you hours of search time.
        </p>
        <p>
          Open Search is adaptable, constantly learning from your choices to
          refine future suggestions even more precisely.
        </p>
        <ActionArrowButton $primary>Start Free Now</ActionArrowButton>
        <AppSumoRating />
      </div>
      <div className="media desktop">
        <video controls>
          <source
            src="https://storage.googleapis.com/persona-music-prod-public/Open%20Search%20Demo_Edited.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default OpenSearch;
