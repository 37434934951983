import React from 'react';
import { StyledActionArrowButton } from './ActionArrowButton.styled';
import { ReactComponent as Arrow } from 'assets/images/icons/arrow.svg';
import { openModal } from 'services/state/actions/modalActions';
import { useAppDispatch } from 'services/state/store/configureStore';
import { ButtonProps } from '../Button';

const ActionArrowButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const handleSignUp = () => {
    return dispatch(openModal('signup', { redirect: `/pricing` }));
  };

  return (
    <StyledActionArrowButton {...props} onClick={onClick || handleSignUp}>
      {children} <Arrow />
    </StyledActionArrowButton>
  );
};

export default ActionArrowButton;
