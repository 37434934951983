import axios, { getOffset, getWaveform } from './config/axiosConfig';

export const getArtists = async (payload) => {
  try {
    const res = await axios.get(`/api/content/artists`, { params: payload });
    const { results, next } = res.data;
    const nextOffset = getOffset(next);

    return { results, offset: nextOffset };
  } catch (error) {
    throw error;
  }
};

export const getArtist = async (id) => {
  try {
    const res = await axios.get(`/api/content/artists/${id}`);
    const artist = res.data;

    if (artist.track_set) {
      const track_set = artist.track_set.map((track) => {
        const waveform = getWaveform(track.waveform);
        track.waveform = waveform;
        return track;
      });
      artist.track_set = track_set;
    }
    return artist;
  } catch (error) {
    throw error;
  }
};
