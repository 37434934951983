import { forwardRef } from 'react';
import { ReactComponent as Search } from 'assets/images/icons/search.svg';
import { ReactComponent as Clear } from 'assets/images/icons/clear.svg';
import SearchResults from './Results/SearchResults';
import { StyledTextInput } from './TextInput.styled';
import useTextInput from './useTextInput';

const TextInput = forwardRef((_, inputRef) => {
  const {
    results,
    loading,
    selectedTags,
    term,
    debouncedValue,
    handleChange,
    handleClose,
  } = useTextInput();

  return (
    <>
      <StyledTextInput $loading={+loading} $content={term}>
        <Search className="search-icon" />
        <input
          ref={inputRef}
          value={term}
          onChange={handleChange}
          placeholder="Search a keyword, artist, genre, mood..."
        />
        {term && <Clear className="input-icon" onClick={handleClose} />}
      </StyledTextInput>
      {(results || loading) && (
        <SearchResults
          results={results}
          term={debouncedValue}
          tags={selectedTags}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  );
});

export default TextInput;
