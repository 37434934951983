import { useMemo } from 'react';
import { ReactComponent as Play } from 'assets/images/icons/play.svg';
import { ReactComponent as Pause } from 'assets/images/icons/pause.svg';
import { ReactComponent as Premium } from 'assets/images/icons/premium.svg';
import { ReactComponent as Check } from 'assets/images/icons/check.svg';
import { StyledTrackDetails } from '../Track.styled';
import { IconButton, ModalLink } from 'components/Button';
import { randomDefault400Image } from 'utils';
import useTrackControls from '../Controls/TrackControls.hooks';

const PremiumIcon = ({ id, paid }) =>
  paid ? (
    <IconButton id={id} aria-label="premium track" tooltip="Purchased">
      <Check className="premium-icon" />
    </IconButton>
  ) : (
    <IconButton id={id} aria-label="premium track" tooltip="Premium">
      <Premium className="premium-icon" />
    </IconButton>
  );

const TrackDetails = ({
  track,
  isCurrent,
  playing,
  compact,
  handleArtist,
  handlePlay,
}) => {
  const defaultCover = useMemo(() => randomDefault400Image(), []);
  const { paid } = useTrackControls(track);

  return (
    <StyledTrackDetails
      $art={track.album_cover_src || defaultCover}
      $compact={compact}
    >
      <div className="album-wrapper">
        <div className="album-art">
          {track.is_premium && (
            <PremiumIcon id={track.id + '-premium'} paid={paid} />
          )}
        </div>
        <button className="play-button" onClick={handlePlay}>
          {isCurrent && playing ? <Pause /> : <Play />}
        </button>
      </div>
      <div className="details">
        <div className="title" onClick={handlePlay}>
          {track.title}
        </div>
        <div className="artist">
          {track.primary_artists.map((artist, i) => [
            i > 0 && ', ',
            <ModalLink
              to={`/artist/${artist.id}`}
              key={i}
              onClick={(e) => handleArtist(e, artist)}
            >
              {artist.name}
            </ModalLink>,
          ])}
        </div>
      </div>
    </StyledTrackDetails>
  );
};

export default TrackDetails;
