import { FormGroup, Label, Input, Error } from '../Form.styled';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import { formTooltips } from 'constants/tooltips';
import PasswordRules from 'components/PasswordRules/PasswordRules';
import { FC, useState } from 'react';

interface InputFieldProps {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  formik: any;
  autoComplete?: string;
  tooltip?: string;
  inlinebutton?: any;
  disabled?: boolean;
  className?: string;
}

const InputField: FC<InputFieldProps> = ({
  label,
  name,
  type = 'text',
  placeholder,
  formik,
  autoComplete,
  tooltip,
  inlinebutton,
  disabled,
  className,
}) => {
  const [passwordRulesOpen, setPasswordRulesOpen] = useState(false);
  const isPassword = name === 'password';

  const handlePasswordRules = () => {
    isPassword && setPasswordRulesOpen(true);
  };

  return (
    <FormGroup $inlinebutton={!!inlinebutton}>
      <Label>
        <span>{label}</span>
        {tooltip && (
          <InfoTooltip
            id="tooltip"
            tooltip={formTooltips[tooltip as keyof typeof formTooltips]}
            position="top"
          />
        )}
      </Label>
      <span className="input-wrapper">
        <Input
          className={className}
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={handlePasswordRules}
          autoComplete={autoComplete}
          disabled={disabled}
        />
        {inlinebutton}
      </span>
      {isPassword && (
        <PasswordRules value={formik.values[name]} open={passwordRulesOpen} />
      )}
      {formik.errors[name] && formik.touched[name] && (
        <Error>{formik.errors[name]}</Error>
      )}
    </FormGroup>
  );
};

export default InputField;
