import { FormGroup, Select, Label, Error } from '../Form.styled';

const SelectField = ({
  label,
  name,
  options,
  other,
  required = true,
  placeholder,
  formik,
  onChange,
  disabled,
}) => (
  <FormGroup>
    <Label>{label}</Label>
    <Select
      id={name}
      name={name}
      value={formik.values[name]}
      onChange={(e) => {
        if (onChange) onChange(e);
        return formik.handleChange(e);
      }}
      onBlur={formik.handleBlur}
      disabled={disabled}
    >
      <option disabled={required} value="">
        {placeholder || 'Select...'}
      </option>
      {other && <option value="other">{other}</option>}
      {options.map((option) => (
        <option
          key={option.id || option.value}
          value={option.id || option.value}
        >
          {option.value}
        </option>
      ))}
    </Select>
    {formik.errors[name] && formik.touched[name] && (
      <Error>{formik.errors[name]}</Error>
    )}
  </FormGroup>
);

export default SelectField;
