import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'hooks';
// import { useQuery, useScrollPosition } from "hooks";
import { openArtist } from 'services/state/actions/artistActions';
import { openPartner } from 'services/state/actions/partnerActions';
import { openPlaylist } from 'services/state/actions/playlistActions';
import { closeModal } from 'services/state/actions/modalActions';
import { useAppDispatch } from 'services/state/store/configureStore';

const RouteChange = () => {
  const {
    artist: aId,
    creator: cId,
    playlist: pId,
  } = useQuery() as {
    artist?: string;
    creator?: string;
    playlist?: string;
  };
  // const scrollPosition = useScrollPosition();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  // const history = useHistory();

  useEffect(() => {
    const element = document.querySelector('.content');

    if (element) {
      element.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    const runAction = () => {
      const modal = document.getElementById('modal');
      const state = location.state;

      if (pId) {
        const playlist = state?.playlist || { id: pId };
        return dispatch(openPlaylist(playlist, state?.values));
      }
      if (aId) {
        const artist = state?.artist || { id: aId };
        return dispatch(openArtist(artist, state?.values));
      }
      if (cId) {
        const creator = state?.creator || { id: cId };
        return dispatch(openPartner(creator, state?.values));
      }
      dispatch(closeModal());

      // TODO: this would make more sense in the action
      if (modal) {
        modal.style.display = 'none';
      }
    };
    runAction();
  }, [dispatch, location.state, aId, cId, pId]);

  return null;
};

export default RouteChange;
