import { useState, useEffect, useRef } from 'react';

const useAudio = (time = false) => {
  const [playing, setPlaying] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setTime] = useState(0);
  const audioRef = useRef();

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = document.getElementById('central-audio');
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      const setAudioData = () => {
        setLoaded(true);
        setDuration(audioRef.current.duration);
        setTime(time ? audioRef.current.currentTime : 0);
        if (!audioRef.current.paused) setPlay();
      };
      const setPlay = () => setPlaying(true);
      const setPause = () => setPlaying(false);
      const setAudioTime = () => setTime(() => audioRef.current.currentTime);

      if (audioRef.current.readyState === 4) setAudioData();

      audioRef.current.addEventListener('play', setPlay);
      audioRef.current.addEventListener('pause', setPause);
      audioRef.current.addEventListener('loadeddata', setAudioData);
      if (time) {
        audioRef.current.addEventListener('timeupdate', setAudioTime);
      }
      return () => {
        audioRef.current.removeEventListener('play', setPlay);
        audioRef.current.removeEventListener('pause', setPause);
        audioRef.current.removeEventListener('loadeddata', setAudioData);
        if (time) {
          audioRef.current.removeEventListener('timeupdate', setAudioTime);
        }
      };
    }
  }, [time]);

  const controls = {
    play: () => {
      audioRef.current.play();
    },
    pause: () => {
      if (playing) {
        return audioRef.current.pause();
      }
      audioRef.current.play();
    },
    stop: () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    },
    volume: (value) => {
      audioRef.current.volume = value;
    },
    seek: (percent) => {
      audioRef.current.currentTime = audioRef.current.duration * percent;
    },
    load: (source, start = 0) => {
      audioRef.current.src = source;
      audioRef.current.currentTime = start;
      audioRef.current.play().catch((err) => {
        if (err.code !== DOMException.ABORT_ERR) throw err;
      });
    },
  };

  return {
    audio: audioRef.current,
    controls,
    playing,
    loaded,
    duration,
    currentTime,
  };
};

export { useAudio };
