import privacy from 'assets/docs/privacy-policy.pdf';
import conditions from 'assets/docs/terms-conditions.pdf';

const faqItems = [
  {
    q: `Where can I use the music?`,
    a: [
      `Anywhere except for feature films, broadcast TV and VOD. For these uses please see the broadcast and VOD library.`,
    ],
  },
  {
    q: `Does my license expire?`,
    a: [
      `Your license for videos published during your subscription term will not expire. Videos published outside of your term will be subject to copyright claim.`,
    ],
  },
  {
    q: `Will I need to pay more if I monetize my content?`,
    a: [
      `No, your license is unlimited, so you won’t owe anything more to anyone else.`,
    ],
  },
  {
    q: `Can I use music for my clients’ projects?`,
    a: [`Yes, your license covers them, too.`],
  },
  {
    q: `I'm a freelancer. Is the Creator Plan for me?`,
    a: [
      `Yes, you have full sub licensing rights, which covers them, too. As long as you’re producing an online campaign and your content is outside of feature film, broadcast TV and VOD.`,
      `If you produce content for others to publish on their channels and they want to avoid copyright claims, simply add the URLs of the published videos and we'll clear them for you.`,
    ],
  },
  {
    q: `How do I know the Creator plan is good enough for my projects?`,
    a: [
      `If your content will be used for feature films, broadcast TV, VOD or by a company with 250+ employees, get in touch with us for a quick solution.`,
    ],
  },
  {
    q: `Can I cancel my subscription?`,
    a: [
      `Yes, just login to your account and cancel anytime before your renewal date.`,
    ],
  },
  {
    q: `If I cancel my plan, does my license remain active?`,
    a: [
      `Yes, you can cancel at any time and your ability to license will remain active until your term ends. Anything licensed during the term remains covered even after you cancel.`,
    ],
  },
  {
    q: `Can I get a refund?`,
    a: [`Yes, within 14 days if you haven't downloaded anything.`],
  },
  {
    q: `What happens if I receive a Content ID Claim?`,
    a: [
      `It is completely normal for a video using legally licensed music to be claimed for a short period of time (up to an hour) and then released. This is not cause for alarm, and in some cases it is how the Youtube Content Management system recognises a license.`,
      `The Youtube Content Management System may occasionally experience a high volume of licenses being validated at once. Even if the release period is longer (a few hours), all of the monetization that results from views is still yours 100% and will not be diminished or withheld from your channel.`,
      `If there is any concern during this process, please feel free to reach out at <a href="mailto:persona_support@personamusic.io">persona_support@personamusic.io</a> and we will let you know what stage of the content release process your video is in and personally guide you through the experience.`,
    ],
  },
  {
    q: `Commercial License Agreement and Privacy Policy`,
    a: [
      `<a href="${conditions}" target="_blank">Commercial License Agreement</a>`,
      `<a href="${privacy}" target="_blank">Privacy Policy</a>`,
    ],
  },
];

export default faqItems;
