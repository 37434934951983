import axios from './config/axiosConfig';
import { defaultOrder } from 'constants/trackSorting';

export const getTracks = async ({ limit, nextPage, tags, ordering, album }) => {
  try {
    const res = await axios.get(`/api/content/tracks`, {
      params: {
        track_limit: limit,
        track_page: nextPage,
        tags,
        ordering: ordering || defaultOrder,
        album,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTrackById = async (id) => {
  try {
    const res = await axios.get(`/api/content/tracks/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAltsByTrackId = async (id) => {
  try {
    const res = await axios.get(`/api/content/tracks/${id}/alts`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
