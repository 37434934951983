import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from './Drawer';
import { StyledFilters } from './TagFilters.styled';
import filterList from 'constants/filters';
import {
  setSelectedFilter,
  clearTagsSearch,
  toggleShowFilters,
} from 'services/state/actions/searchActions';
import { ReactComponent as Chevron } from 'assets/images/icons/chevron-down.svg';
import { ReactComponent as Close } from 'assets/images/icons/clear.svg';
import UsageTags from './UsageTags';
import useSearchPage from 'pages/SearchPage/useSearchPage';
import { getTags } from 'services/state/actions/tagsActions';

const TagFilters = () => {
  const { show, selectedFilter } = useSelector((state) => state.searchTags);
  const { tags, included, excluded } = useSelector((state) => state.tags);
  const { applyTag, clearTags } = useSearchPage();
  const dispatch = useDispatch();

  const inc = useMemo(() => included ?? [], [included]);
  const exc = useMemo(() => excluded ?? [], [excluded]);

  const selected = useMemo(() => {
    return [...inc, ...exc].reduce((acc, tag) => {
      return { ...acc, [tag.grouping]: (acc[tag.grouping] || 0) + 1 };
    }, {});
  }, [inc, exc]);

  const allTags = useMemo(() => {
    return tags
      .filter((tag) => tag.grouping === selectedFilter)
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((tag) => {
        return {
          ...tag,
          included: inc.some((t) => t.id === tag.id),
          excluded: exc.some((t) => t.id === tag.id),
        };
      });
  }, [tags, selectedFilter, inc, exc]);

  const usageTags = useMemo(() => {
    return tags
      .filter((tag) => tag.grouping === 'usage')
      .map((tag) => {
        // TODO: change this to predictable value like ID after migration of usage tags
        if (tag.name === 'Social Media') {
          return { ...tag, checked: true, disabled: true };
        }
        const checked = inc.some((inc) => inc.id === tag.id);
        return { ...tag, checked };
      });
  }, [tags, inc]);

  useEffect(() => {
    if (!tags?.length) dispatch(getTags());
  }, [tags, dispatch]);

  const handleSelectedFilter = (type) => {
    dispatch(setSelectedFilter(type));
  };

  const handleToggleTag = (tag, include) => {
    applyTag(tag, include);
  };

  const handleReset = () => {
    if (inc.length + exc.length) {
      dispatch(clearTagsSearch());
    }
    clearTags();
  };

  const handleShow = () => {
    dispatch(toggleShowFilters(false));
  };

  const handleCollapse = () => {
    dispatch(setSelectedFilter(null));
  };

  return (
    <StyledFilters $show={+show} $open={selectedFilter}>
      <div className="filters-wrapper">
        <Close className="close" onClick={handleShow} />
        <ul className="filters">
          <li className="usage">
            Usage
            <UsageTags tags={usageTags} handleToggleTag={handleToggleTag} />
          </li>
          {filterList.map((list, i) =>
            list.hidden ? null : (
              <li
                key={i}
                className={
                  selectedFilter === list.grouping ? 'active' : undefined
                }
                onClick={() => handleSelectedFilter(list.grouping)}
              >
                {list.title}
                {selected[list.grouping] && (
                  <span className="badge">{selected[list.grouping]}</span>
                )}
              </li>
            )
          )}
          <li onClick={handleReset}>Reset</li>
          {selectedFilter && (
            <Chevron className="collapse" onClick={handleCollapse} />
          )}
        </ul>
        <Drawer
          open={selectedFilter}
          tags={allTags}
          handleToggleTag={handleToggleTag}
        />
      </div>
    </StyledFilters>
  );
};

export default TagFilters;
