import * as favoritesApi from 'services/api/favoritesApi';
import { openModal } from './modalActions';

const types = {
  GET_FAVORITES_LOADING: 'GET_FAVORITES_LOADING',
  GET_FAVORITES_SUCCESS: 'GET_FAVORITES_SUCCESS',
  GET_FAVORITES_ERROR: 'GET_FAVORITES_ERROR',
  ADD_FAVORITE_LOADING: 'ADD_FAVORITE_LOADING',
  ADD_FAVORITE_SUCCESS: 'ADD_FAVORITE_SUCCESS',
  ADD_FAVORITE_ERROR: 'ADD_FAVORITE_ERROR',
  REMOVE_FAVORITE_LOADING: 'REMOVE_FAVORITE_LOADING',
  REMOVE_FAVORITE_SUCCESS: 'REMOVE_FAVORITE_SUCCESS',
  REMOVE_FAVORITE_ERROR: 'REMOVE_FAVORITE_ERROR',
};

export const getFavorites = (payload) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user) return;
    dispatch({ type: types.GET_FAVORITES_LOADING });
    return favoritesApi
      .getFavorites(payload)
      .then((ids) => {
        dispatch({
          type: types.GET_FAVORITES_SUCCESS,
          payload: ids,
        });
      })
      .catch((message) => {
        dispatch({ type: types.GET_FAVORITES_ERROR, error: message });
        return message;
      });
  };
};

export const addFavorite = (id) => {
  return (dispatch, getState) => {
    if (!getState().auth.user) {
      return dispatch(openModal('signin'));
    }
    dispatch({ type: types.ADD_FAVORITE_LOADING });
    return favoritesApi
      .addFavorite(id)
      .then(() => {
        dispatch({
          type: types.ADD_FAVORITE_SUCCESS,
          payload: id,
        });
      })
      .catch((message) => {
        dispatch({ type: types.ADD_FAVORITE_ERROR, error: message });
        return message;
      });
  };
};

export const removeFavorite = (id) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_FAVORITE_LOADING });
    return favoritesApi
      .removeFavorite(id)
      .then(() => {
        dispatch({
          type: types.REMOVE_FAVORITE_SUCCESS,
          payload: id,
        });
      })
      .catch((message) => {
        dispatch({ type: types.REMOVE_FAVORITE_ERROR, error: message });
        return message;
      });
  };
};
