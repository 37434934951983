import styled from 'styled-components';

export const StyledMusic = styled.div`
  h3 {
    margin: 2rem 4% 0;
  }
  & > div {
    padding: 1% 4% 2%;
    display: flex;
  }
`;
