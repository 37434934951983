import styled from 'styled-components';

export const StyledSectionTabsButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  block-size: 3rem;
  user-select: none;
  padding-inline: 1.125rem;
  border-radius: 3rem;
  font-size: 1rem;
  transition: color 160ms, background-color 160ms;
  margin: 0;

  &.active {
    background-color: #fff;
    color: #000;
  }

  svg {
    inline-size: 1.5rem;
    block-size: 1.5rem;
  }

  @media (max-width: 768px) {
    flex-shrink: 0;
    font-size: 0.875rem;
    font-weight: 400;
    padding-inline: 0.75rem;
    block-size: 2.5rem;
    border-radius: 0;
    box-shadow: 0px -1px 0px 0px #ffffff2b inset;

    &.active {
      background-color: inherit;
      color: inherit;
      box-shadow: 0px -2px 0px 0px #fff inset;
    }

    svg {
      inline-size: 1.25rem;
      block-size: 1.25rem;
    }
  }
`;

export const StyledSectionTabsButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-block-end: 4rem;

  .button-wrapper {
    padding: 1rem;
    display: inline-flex;
    gap: 0.5rem;
    border: 1px solid #ffffff2b;
    background: #101116;
    border-radius: 4rem;
  }

  @media (max-width: 768px) {
    inline-size: 100%;
    overflow: auto;
    justify-content: flex-start;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-block-end: 2rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .button-wrapper {
      padding: 0;
      border-radius: 0;
      border: 0;
      background: none;
      gap: 0;
    }
  }
`;

export const StyledSectionTabs = styled.div``;
