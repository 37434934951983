import { StyledLandingExplore } from './ExploreSection.styled';
import ExploreGenre from './ExploreGenre';
import { StyledBackgroundEllipse } from 'pages/LandingPage/LandingPage.styled';

const ExploreSection = () => {
  const genres = [
    { name: 'Pop', slug: 'pop', id: 1573 },
    { name: 'Hip Hop', slug: 'hiphop', id: 1575 },
    { name: 'Electronic', slug: 'electronic', id: 1566 },
    { name: 'Classical', slug: 'classical', id: 1565 },
    { name: 'Acoustic', slug: 'acoustic', id: 1760 },
    { name: 'Country', slug: 'country', id: 1590 },
  ];

  return (
    <StyledLandingExplore>
      <StyledBackgroundEllipse
        $size="34.375rem"
        $color="#5937B0"
        $top="3%"
        $right="16%"
        $blur="24.125rem"
      />
      <div className="section-header">
        <h2>Explore Our Diverse Music Library</h2>
        <h4>Find the perfect soundtrack for your next project</h4>
      </div>
      <div className="section-content">
        <div className="explore-genres">
          {genres.map((genre) => (
            <ExploreGenre key={genre.name} tag={genre} />
          ))}
        </div>
      </div>
    </StyledLandingExplore>
  );
};

export default ExploreSection;
