import React from 'react';
import { ReactComponent as Info } from 'assets/images/icons/info.svg';
import { StyledInfo } from './InfoTooltip.styled';
import { mobile } from 'hooks/viewport.signal';
import { Tooltip } from 'react-tooltip';

interface InfoTooltipProps {
  id: string;
  tooltip: React.FC;
  style?: React.CSSProperties;
  position?: string;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  id,
  tooltip: TC,
  style,
  position = 'right-start',
}) =>
  !mobile.value ? (
    <StyledInfo style={style}>
      <Info data-tooltip-id={id} data-tooltip-place={position} />
      <Tooltip id={id} className="info-tooltip">
        <TC />
      </Tooltip>
    </StyledInfo>
  ) : null;

export default InfoTooltip;
