import styled from "styled-components";
import { StyledLandingSection } from "pages/LandingPage/LandingPage.styled";
import { StyledStars } from "components/AppSumo/AppSumoRating.styled";
import { device } from "assets/styles/device";

export const StyledLandingTestimonials = styled(StyledLandingSection)``;

export const StyledTestimonial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  font-size: 0.875rem;
  gap: 1.5rem;
  padding: 1.5rem;
  border: 1px solid #ffffff;
  border-radius: 1rem;
  scroll-snap-align: center;

  ${StyledStars} {
    justify-content: center;
    margin-block-end: 1rem;
  }

  .test-title {
    font-size: 1.25rem;
    margin-block-end: 1rem;
  }

  .test-footer {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;

    .test-avatar {
      inline-size: 1.5rem;
      block-size: 1.5rem;
      border-radius: 1.5rem;
      overflow: hidden;
      flex-shrink: 0;

      & > img {
        inline-size: 100%;
        block-size: 100%;
        object-fit: cover;
      }
    }

    .verified {
      display: flex;
      align-items: center;
      gap: 0.125rem;
      font-size: 0.875rem;

      & > svg {
        color: #ffbc00;
        inline-size: 1rem;
        block-size: 1rem;
      }
    }
  }

  @media ${device.mobile} {
    padding: 1rem;

    .test-title {
      font-size: 1.25rem;
      margin-block-end: 0.5rem;
    }
  }
`;
