import { StyledLandingQualityMusic } from './QualityMusicSection.styled';
import BulletPoint from 'components/BulletPoint/BulletPoint';
import { ReactComponent as Shield } from 'assets/images/icons/arrow-shield.svg';
import { ReactComponent as Music } from 'assets/images/icons/music-sheet.svg';
import { ReactComponent as Arrow } from 'assets/images/icons/arrow-download.svg';
import ActionArrowButton from 'components/Button/ActionArrowButton/ActionArrowButton';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import quality from 'assets/images/backgrounds/quality-music.jpg';

const QualityMusicSection = () => (
  <StyledLandingQualityMusic>
    <div className="section-content">
      <h2>Music That Hits Different</h2>
      <h4>Take your content to new heights with Persona</h4>
      <div className="media">
        <img src={quality} alt="Quality Music" />
      </div>
      <p>
        Ever felt the frustration of clicking through mediocre music libraries
        for hours only to end up with a soundtrack that just doesn’t cut it?
        Creators need high-quality, evocative music that elevates their work to
        professional levels, and they need to find that music quickly. The
        reality? Not every platform offers audio that meets the required
        standard, or the search tools needed to find the perfect music for your
        video within minutes.
      </p>
      <p>
        With Persona, you get access to the kind of music that powers
        blockbusters and hit TV shows, and the search tools to find that perfect
        track in minutes, not hours. Now, your projects can feel as epic as a
        Hollywood production, with a soundtrack that matches your creative
        vision. Consider mediocre music and search fatigue a thing of the past.
        Welcome to Persona Music.
      </p>
      <div className="bullet-points">
        <BulletPoint icon={Shield}>
          <strong>Safe For All Usage: </strong>Secure for all types of global
          commercial use.
        </BulletPoint>
        <BulletPoint icon={Music}>
          <strong>Huge Music Library: </strong>20,000+ fully customizable
          tracks.
        </BulletPoint>
        <BulletPoint icon={Arrow}>
          <strong>Unlimited Downloads: </strong>Gain access to a free diverse
          range of music tracks.
        </BulletPoint>
      </div>

      <ActionArrowButton $primary>Start Free Now</ActionArrowButton>
      <AppSumoRating />
    </div>
  </StyledLandingQualityMusic>
);

export default QualityMusicSection;
