import axios from './config/axiosConfig';

export const getProjects = async () => {
  try {
    const res = await axios.get(`/api/accounts/projects`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createProjectAddTrack = async ({ trackId, ...project }) => {
  try {
    const res = await axios.post(`/api/accounts/projects`, {
      ...project,
      track_add: trackId,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addTrackToProject = async ({ id, trackId }) => {
  try {
    const res = await axios.put(`/api/accounts/projects/${id}`, {
      track_add: trackId,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const removeTrackFromProject = async ({ id, trackId }) => {
  try {
    const res = await axios.put(`/api/accounts/projects/${id}`, {
      track_remove: trackId,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (id) => {
  try {
    await axios.delete(`/api/accounts/projects/${id}`);
    return id;
  } catch (error) {
    throw error;
  }
};
