import { imagePaths, imagePaths400 } from 'assets/images/cards';
import { UserInterface } from 'types/user';

export { randomTags } from './randomTags';
export { loadBuffer } from './buffer-loader';
export { loadSource } from './source-loader';
export { renderMetaPixel } from './renderMetaPixel';

export {
  getLsToken,
  setLsToken,
  clearLsToken,
  clearLsStore,
} from './localStorage';
export { mixpanel } from './mixpanel';

export const randomDefaultImage = () => {
  return imagePaths[Math.floor(Math.random() * imagePaths.length)];
};

export const randomDefault400Image = () => {
  return imagePaths400[Math.floor(Math.random() * imagePaths.length)];
};

export const numToTime = (num: number): string => {
  const mins = Math.floor(num / 60);
  const secs = String(Math.round(num % 60));
  return `${mins}:${secs.length !== 1 ? secs : '0' + secs}`;
};

interface JwtPayload {
  sub: string;
  iat: number;
  exp: number;
}

export const parseJwt = (token: string): JwtPayload | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Failed to parse JWT:', error);
    return null;
  }
};

interface Coupon {
  amount_off?: number;
  percent_off?: number;
}

export const couponValue = (coupon: Coupon): string | false => {
  if (coupon?.amount_off) {
    return `$${coupon.amount_off}`;
  }
  if (coupon?.percent_off) {
    return `${coupon.percent_off}%`;
  }
  return false;
};

interface Tag {
  type: string;
  id: string;
}

interface FormattedTags {
  [key: string]: string[];
}

export const formatTags = (tags: { [key: string]: Tag[] }): FormattedTags => {
  return Object.entries(tags).reduce((obj: FormattedTags, [type, items]) => {
    // Iterate through each item in the 'inc' and 'exc' arrays
    items.forEach((item) => {
      // Generate the key for the formatted tag based on the item type and 'inc'/'exc'
      const key = `${item.type}s_${type}`;
      // Append the item ID to the corresponding key's array in the 'obj' object
      obj[key] = [...(obj[key] || []), item.id];
    });
    // Return the updated 'obj' object for the next iteration
    return obj;
  }, {});
};

export const formatPrice = (price: number): string => {
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);

  return formattedPrice;
};

export const getPermissionFromUser = (user: UserInterface) => {
  // TODO: this needs reworking
  if (user?.subscription?.type === 'personamusic_tier3') {
    return 3;
  }
  return +user?.subscription?.type?.split('_')[2] || 0;
};
