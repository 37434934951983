import { StyledButton } from 'components/Button/Button.styled';
import styled from 'styled-components';

export const StyledFeatureDisabled = styled.div<{ $small?: boolean }>`
  max-inline-size: ${({ $small }) => $small && '10rem'};
  margin-inline: auto;

  p {
    line-height: 1.2rem;

    &.error {
      color: #be0e0e;
      text-align: center;
      font-weight: 700;
    }
  }

  ${StyledButton} {
    font-size: 1rem;
    block-size: 2.2rem;
  }
`;
