import { defaultOrder } from 'constants/trackSorting';

const initialState = {
  tracks: [],
  trackLimit: 20,
  trackNext: null,
  ordering: defaultOrder,
  selectedFilter: null,
  show: false,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_SEARCH_SORT_ORDER':
      return { ...state, tracks: [], ordering: action.payload };

    case 'SEARCH_TAGS_LOADING':
    case 'SEARCH_MORE_TAGS_LOADING':
      return {
        ...state,
        tracks: action.clearTracks ? [] : state.tracks,
        loading: true,
        error: null,
      };

    case 'SEARCH_SIMILAR_TRACK_LOADING':
      return {
        ...state,
        tracks: action.clear ? [] : state.tracks,
        loading: true,
        error: null,
      };

    case 'SEARCH_TAGS_SUCCESS':
      return {
        ...state,
        tracks: action.payload.tracks,
        trackNext: action.payload.trackNext,
        loading: false,
        error: null,
      };

    case 'SEARCH_MORE_TAGS_SUCCESS':
      return {
        ...state,
        tracks: [...state.tracks, ...action.payload.tracks],
        trackNext: action.payload.trackNext,
        loading: false,
        error: null,
      };

    case 'SEARCH_SIMILAR_TRACK_SUCCESS':
      return {
        ...state,
        tracks: action.payload.tracks,
        trackNext: null,
        loading: false,
        error: null,
      };

    case 'SET_SELECTED_FILTER':
      return {
        ...state,
        selectedFilter: action.payload,
      };

    case 'TOGGLE_SHOW_FILTERS':
      const value = action.payload ?? !state.show;
      return {
        ...state,
        show: value,
        selectedFilter: !value ? null : state.selectedFilter,
      };

    case 'SEARCH_TAGS_ERROR':
    case 'SEARCH_MORE_TAGS_ERROR':
    case 'SEARCH_SIMILAR_TRACK_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'SEARCH_TAGS_RESET':
      return initialState;

    default:
      return state;
  }
};

export default reducer;
