const initialState = {
  creator: {
    limit: 12,
    playlists: null,
    nextPage: null,
    loading: false,
    error: null,
  },
  editor: {
    limit: 12,
    playlists: null,
    nextPage: null,
    loading: false,
    error: null,
  },
  filmmaker: {
    limit: 12,
    playlists: null,
    nextPage: null,
    loading: false,
    error: null,
  },
  artist: {
    limit: 12,
    playlists: null,
    nextPage: null,
    loading: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PLAYLISTS_LOADING':
    case 'GET_MORE_PLAYLISTS_LOADING':
      return {
        ...state,
        [action.ptype]: {
          ...state[action.ptype],
          loading: true,
          error: null,
        },
      };

    case 'GET_PLAYLISTS_SUCCESS':
      return {
        ...state,
        [action.ptype]: {
          ...state[action.ptype],
          playlists: action.payload.results,
          nextPage: action.payload.next_page,
          loading: false,
          error: null,
        },
      };

    case 'GET_MORE_PLAYLISTS_SUCCESS':
      return {
        ...state,
        [action.ptype]: {
          ...state[action.ptype],
          playlists: [
            ...state[action.ptype].playlists,
            ...action.payload.results,
          ],
          nextPage: action.payload.next_page,
          loading: false,
          error: null,
        },
      };

    case 'GET_PLAYLISTS_ERROR':
    case 'GET_MORE_PLAYLISTS_ERROR':
      return {
        ...state,
        [action.ptype]: {
          ...state[action.ptype],
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default reducer;
