import styled from "styled-components";

export const StyledLargeSwitch = styled.div`
  display: flex;
  border: ${({ theme }) => theme.formFieldBorder};
  position: relative;
  border-radius: 4px;

  .switch {
    width: ${({ $items }) => 100 / $items}%;
    position: absolute;
    height: 100%;
    left: ${({ $selected, $items }) => ($selected * 100) / $items}%;
    transition: 300ms left;
    padding: 4px;
    transition: all 0.3s;

    .switch-color {
      --switch-button-background: linear-gradient(
        59deg,
        ${({ theme }) => theme.highlightSecondary},
        ${({ theme }) => theme.highlightPrimary} 20%,
        ${({ theme }) => theme.highlightPrimary} 48%,
        ${({ theme }) => theme.highlightPrimary} 0,
        ${({ theme }) => theme.highlightSecondary} 80%,
        ${({ theme }) => theme.highlightSecondary}
      );
      background: ${({ account }) =>
        account ? "#ffffff38" : "var(--switch-button-background)"};
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background-position: ${({ $selected, $items }) =>
          $selected * (100 / ($items - 1))}%
        50%;
      background-size: 270%;
      transition: all 0.3s;
    }
  }

  .switch-item {
    min-height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    text-align: center;
    cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
    z-index: 999;
    transition: color 0.2s;

    &.active {
      color: ${({ theme }) => theme.textHighlight};
    }
    &:hover {
      color: ${({ theme, $disabled }) => !$disabled && theme.textHighlight};
    }

    span {
      display: block;
      font-size: x-small;
      opacity: 0.6;
    }
  }
`;
