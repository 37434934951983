import { defaultOrder } from 'constants/trackSorting';

const initialState = {
  playlists: [],
  tracks: [],
  count: 0,
  limit: 20,
  nextPage: null,
  ordering: defaultOrder,
  loading: {
    playlists: false,
    tracks: false,
  },
  error: {
    playlists: null,
    tracks: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_MUSIC_SORT_ORDER':
      return { ...state, tracks: [], ordering: action.payload };

    case 'GET_MUSIC_PLAYLISTS_LOADING':
      return {
        ...state,
        loading: { ...state.loading, playlists: true },
        error: { ...state.error, playlists: null },
      };

    case 'GET_MUSIC_TRACKS_LOADING':
    case 'GET_MORE_MUSIC_TRACKS_LOADING':
      return {
        ...state,
        loading: { ...state.loading, tracks: true },
        error: { ...state.error, tracks: null },
      };

    case 'GET_MUSIC_PLAYLISTS_SUCCESS':
      return {
        ...state,
        playlists: action.payload,
        loading: { ...state.loading, playlists: false },
        error: { ...state.error, playlists: null },
      };

    case 'GET_MUSIC_TRACKS_SUCCESS':
      return {
        ...state,
        tracks: action.payload.results,
        count: action.payload.count,
        nextPage: action.payload.track_next,
        loading: { ...state.loading, tracks: false },
        error: { ...state.error, tracks: null },
      };

    case 'GET_MORE_MUSIC_TRACKS_SUCCESS':
      return {
        ...state,
        tracks: [...state.tracks, ...action.payload.results],
        count: action.payload.count,
        nextPage: action.payload.track_next,
        loading: { ...state.loading, tracks: false },
        error: { ...state.error, tracks: null },
      };

    case 'GET_MUSIC_PLAYLISTS_ERROR':
    case 'GET_MUSIC_TRACKS_ERROR':
    case 'GET_MORE_MUSIC_TRACKS_ERROR':
      return {
        ...state,
        loading: initialState.loading,
        error: action.error,
      };

    case 'CLEAR_MUSIC_TRACKS':
      return {
        ...initialState,
        loading: state.loading,
        playlists: state.playlists,
      };

    default:
      return state;
  }
};

export default reducer;
