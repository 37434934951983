import { preloadArtist } from './artistActions';
import { preloadAlbum } from './albumActions';
import { preloadPartner } from './partnerActions';
import { preloadPlaylist } from './playlistActions';
import { preloadMasterclass } from './masterclassActions';

export const preloadContent = ({ type, item }) => {
  let action = null;

  if (type === 'artist') {
    action = preloadArtist;
  }
  if (type === 'album') {
    action = preloadAlbum;
  }
  if (type === 'creator') {
    action = preloadPartner;
  }
  if (type === 'playlist') {
    action = preloadPlaylist;
  }
  if (type === 'masterclass') {
    action = preloadMasterclass;
  }
  return (dispatch) => {
    dispatch(action(item));
  };
};
