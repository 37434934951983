const initialState = {
  youtube: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CHANNELS_LOADING':
    case 'ADD_CHANNEL_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_CHANNELS_SUCCESS':
      return {
        ...state,
        youtube: action.payload,
        loading: false,
        error: null,
      };

    case 'ADD_CHANNEL_SUCCESS':
      return {
        ...state,
        youtube: [action.payload, ...state.youtube],
        loading: false,
        error: null,
      };

    case 'REMOVE_CHANNEL_SUCCESS':
      const remaining = state.youtube.filter((ch) => {
        return ch.channel_id !== action.payload;
      });
      return {
        ...state,
        youtube: remaining,
        loading: false,
        error: null,
      };

    case 'GET_CHANNELS_ERROR':
    case 'ADD_CHANNEL_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
