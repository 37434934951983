const filterList = [
  { type: 'genre', grouping: 'genre', title: 'Genres' },
  { type: 'subgenre', grouping: 'genre', title: 'Subgenres', hidden: true },
  { type: 'mood', grouping: 'mood', title: 'Moods' },
  { type: 'instrument', grouping: 'instrument', title: 'Instruments' },
  { type: 'videotype', grouping: 'videotype', title: 'Video Types' },
  { type: 'usage', grouping: 'usage', title: 'Usage', hidden: true },
];

export default filterList;
