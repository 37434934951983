import styled from 'styled-components';
import { device } from 'assets/styles/device';

interface StyledHeaderProps {
  $authed?: number;
}

export const StyledHeader = styled.header<StyledHeaderProps>`
  min-block-size: var(--header-height);
  inline-size: 100%;
  color: ${({ theme }) => theme.textHighlight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: auto;
  isolation: isolate;
  z-index: 3;

  .header-content-wrapper {
    background: ${({ theme }) => theme.backgroundPrimary};
  }

  .header-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    inline-size: ${({ $authed }) => ($authed ? '92%' : `min(1246px, 90%)`)};
    margin-inline: auto;
    min-block-size: 4rem;

    .site-logo {
      display: block;
      inline-size: 12.76144rem;
      flex-shrink: 0;
      outline-offset: 4px;
    }

    nav ul {
      display: flex;
      gap: 1.75rem;
      align-items: center;

      li {
        .login {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }

  @media ${device.mobile} {
    .header-content {
      font-size: 0.875rem;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      min-block-size: 3.25rem;

      .site-logo {
        inline-size: 6.75rem;
        block-size: 1rem;
      }

      nav {
        justify-self: flex-end;

        ul li .login {
          font-size: 0.875rem;
          gap: 0.375rem;

          & > svg {
            block-size: 1.25rem;
            inline-size: 1.25rem;
          }
        }
      }
    }
  }
`;
