import React, { FC } from 'react';
import {
  StyledSectionTabsButton,
  StyledSectionTabsButtons,
} from './SectionTabs.styled';

interface TabsButtonsProps {
  sections: {
    title: string;
    icon?: React.ReactNode;
    component: React.ReactNode;
  }[];
  active: number;
  handleChange: (index: number) => void;
}

const SectionTabsButtons: FC<TabsButtonsProps> = ({
  sections,
  active,
  handleChange,
}) => {
  return (
    <StyledSectionTabsButtons>
      <div className="button-wrapper">
        {sections.map((section, i) => (
          <StyledSectionTabsButton
            key={section.title}
            className={i === active ? 'active' : ''}
            onClick={() => handleChange(i)}
          >
            {section.icon}
            {section.title}
          </StyledSectionTabsButton>
        ))}
      </div>
    </StyledSectionTabsButtons>
  );
};

export default SectionTabsButtons;
