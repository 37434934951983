import Slider from "@mui/material/Slider";
import { ReactComponent as VolOff } from "assets/images/icons/volume-mute.svg";
import { ReactComponent as VolLow } from "assets/images/icons/volume-down.svg";
import { ReactComponent as VolHigh } from "assets/images/icons/volume-up.svg";
import { StyledVolume } from "../Player.styled";

const Volume = ({ volume, handleVolume }) => (
  <StyledVolume>
    <div className="volume-icon">
      {volume === 0 ? <VolOff /> : volume < 0.5 ? <VolLow /> : <VolHigh />}
    </div>
    <Slider
      min={0}
      max={1}
      step={0.01}
      value={volume}
      onChange={(e, value) => handleVolume(value)}
    />
  </StyledVolume>
);

export default Volume;
