import { StyledInfiniteCarousel } from './InfiniteCarousel.styled';

const InfiniteCarousel = ({ items, ...props }) => {
  return (
    <StyledInfiniteCarousel {...props}>
      <div className="carousel-track">
        {[...items, ...items].map((item, i) => (
          <img key={i + item} src={item} alt="logo" />
        ))}
      </div>
    </StyledInfiniteCarousel>
  );
};

export default InfiniteCarousel;
