import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledSnapCarousel = styled.div`
  --cells: ${({ $cells }) => $cells || '3'};
  --total: ${({ $total }) => $total || '9'};
  --gap: 24px;
  position: relative;

  .scroll {
    display: grid;
    place-items: center;
    background-color: #fff;
    color: #000;
    inline-size: 24px;
    block-size: 24px;
    border-radius: 24px;
    position: absolute;
    inset-inline: min(-4%, 24px) auto;
    inset-block: 0;
    margin: auto;
    transition: opacity 160ms;

    &.next {
      inset-inline: auto min(-4%, 24px);
      rotate: 180deg;
    }

    &:disabled {
      opacity: 0;
    }
  }

  .carousel-content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(
      calc(var(--total)),
      calc(
        (100% / var(--cells)) - (var(--gap) / var(--cells)) * (var(--cells) - 1)
      )
    );
    gap: var(--gap);
    overflow-x: scroll;
    padding: 1px; /* Prevents scroll-snap from breaking on Firefox and Safari */
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media ${device.mobile} {
    padding-block-end: 3.5rem;

    .scroll {
      inset: auto auto 0 calc(50% - 2.25rem);

      &.next {
        inset: auto calc(50% - 2.25rem) 0 auto;
      }

      &:disabled {
        opacity: 0.3;
      }
    }
  }
`;
