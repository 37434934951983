const initialState = {
  masterclass: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MASTERCLASS_PRELOAD':
      return {
        ...state,
        masterclass: action.payload,
        loading: true,
      };

    case 'GET_MASTERCLASS_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_MASTERCLASS_SUCCESS':
      return {
        ...state,
        masterclass: action.payload,
        loading: false,
        error: null,
      };

    case 'GET_MASTERCLASS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
