const types = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_SECOND_MODAL: 'OPEN_SECOND_MODAL',
  CLOSE_SECOND_MODAL: 'CLOSE_SECOND_MODAL',
  USER_RESET_ERROR: 'USER_RESET_ERROR',
};

export const openModal =
  (kind, values, force = false) =>
  (dispatch, getState) => {
    const modalState = getState().modal;
    const { open } = modalState;

    const potentialInternals = ['project', 'download', 'subscribe', 'signin'];

    if (open && potentialInternals.includes(kind) && !force) {
      if (modalState[kind]) return;

      return dispatch({
        type: types.OPEN_SECOND_MODAL,
        payload: { kind, values },
      });
    }

    const htmlTag = document.getElementsByTagName('html')[0];
    const hasScroll = htmlTag.scrollHeight > htmlTag.clientHeight;
    let pos = 0;

    if (hasScroll) {
      pos = window.pageYOffset || document.body.scrollTop;
      document.body.classList.add('with-scroll');
    }
    document.body.style.top = `-${pos}px`;
    document.body.classList.add('freeze');

    dispatch({ type: types.USER_RESET_ERROR });
    dispatch({ type: types.OPEN_MODAL, payload: { kind, values } });
  };

export const closeModal = () => {
  const scrollY = document.body.style.top;

  document.body.classList.remove('freeze', 'with-scroll');
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  return (dispatch) => dispatch({ type: types.CLOSE_MODAL });
};

export const closeSecondaryModal = () => (dispatch) =>
  dispatch({ type: types.CLOSE_SECOND_MODAL });
