import { StyledSubscribeModal } from './SubscribeModal.styled';
import { Link, useNavigate } from 'react-router-dom';

const SubscribeModal = ({ handleClose }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    handleClose();
    navigate('/pricing');
  };

  return (
    <StyledSubscribeModal>
      <div className="modal-header">
        <h2>Subscribe</h2>
        <p>
          You're currently not subscribed to any plans. You must be subscribed
          to download and use tracks.
        </p>
        <p>
          Check out our plans{' '}
          <Link to="/pricing" onClick={handleClick}>
            here
          </Link>{' '}
          to gain access to unlimited downloads!
        </p>
      </div>
    </StyledSubscribeModal>
  );
};

export default SubscribeModal;
