import { forwardRef } from 'react';
import { StyledOpenInput } from './OpenInput.styled';
import useOpenInput from './useOpenInput';
import { ReactComponent as Clear } from 'assets/images/icons/clear.svg';
import { ReactComponent as Search } from 'assets/images/icons/search.svg';

const OpenInput = forwardRef((_, inputRef) => {
  const { value, handleChange, handleSubmit, handleClear, expired } =
    useOpenInput();

  return (
    <StyledOpenInput onSubmit={handleSubmit}>
      <Search className="search-icon" />
      <input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        placeholder={
          expired
            ? 'Please upgrade to continue using this feature'
            : 'Search anything...'
        }
        disabled={expired}
      />
      {value && <Clear className="input-icon" onClick={handleClear} />}
    </StyledOpenInput>
  );
});

export default OpenInput;
