import { mixpanel } from 'utils';

const types = {
  SET_QUEUE_AND_PLAY_SUCCESS: 'SET_QUEUE_AND_PLAY_SUCCESS',
  SET_NEXT_TRACK_SUCCESS: 'SET_NEXT_TRACK_SUCCESS',
  SET_PREVIOUS_TRACK_SUCCESS: 'SET_PREVIOUS_TRACK_SUCCESS',
  CLOSE_PLAYER: 'CLOSE_PLAYER',
};

export const setQueueAndPlay = (payload) => (dispatch) => {
  const { list, index } = payload;
  const { id, title } = list[index];

  dispatch({ type: types.SET_QUEUE_AND_PLAY_SUCCESS, payload });
  mixpanel.track('Track Play', { 'Track ID': id, 'Track Title': title });
  mixpanel.people.increment('Track Plays');
};

export const setNextTrack = (index) => (dispatch) => {
  dispatch({ type: types.SET_NEXT_TRACK_SUCCESS, payload: index });
};

export const setPreviousTrack = (index) => (dispatch) => {
  dispatch({ type: types.SET_PREVIOUS_TRACK_SUCCESS, payload: index });
};

export const closePlayer = () => (dispatch) => {
  dispatch({ type: types.CLOSE_PLAYER });
};
