import styled from 'styled-components';

interface StyledRippleProps {
  $background?: string;
  $color?: string;
  $size?: number;
}

interface StyledPlaybackControlsProps {
  $border?: boolean;
}

interface StyledPlayProps {
  $border?: boolean;
}

interface StyledControlProps {
  $disable?: boolean;
}

export const StyledRipple = styled.span<StyledRippleProps>`
  --ripple-size: ${({ $size }) => $size || 40}px;
  position: absolute;
  width: var(--ripple-size);
  height: var(--ripple-size);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: ${({ $background }) =>
    $background ? $background + '33' : 'none'};
  box-shadow: ${({ $background }) =>
    $background ? `0 0 50px 50px ${$background}33` : 0};

  span {
    position: absolute;
    border: 2px solid ${({ $color }) => $color || '#fff'};
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }

  @keyframes lds-ripple {
    0% {
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: var(--ripple-size);
      height: var(--ripple-size);
      opacity: 0;
    }
  }
`;

export const StyledPlaybackControls = styled.div<StyledPlaybackControlsProps>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
`;

export const StyledPlay = styled.button<StyledPlayProps>`
  --play-size: ${({ $border }) => ($border ? '48px' : '24px')};
  width: var(--play-size);
  height: var(--play-size);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin: 0;
  flex-shrink: 0;

  ${({ $border }) =>
    $border &&
    `
    padding: 10px;
    border: 2px solid;
  `};
`;

export const StyledControl = styled.button<StyledControlProps>`
  margin: 0;

  &.prev {
    margin-right: 1.5rem;
  }
  &.next {
    margin-left: 1.5rem;
  }

  ${({ $disable }) =>
    $disable &&
    `
    opacity: 0.5;
    cursor: default;
  `}
`;
