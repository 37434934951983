import React from 'react';
import { StyledNavButton, StyledDropdown } from './Dropdown.styled';
import useDropdown from './Dropdown.hooks';

interface DropdownProps {
  target: React.ReactNode;
  position?: 'left' | 'right';
  children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ target, position, children }) => {
  const { dropdownRef, toggleRef, open, show, toggleOpen } = useDropdown();

  return (
    <>
      <StyledNavButton
        ref={toggleRef}
        onClick={toggleOpen}
        aria-label="user menu"
      >
        {target}
      </StyledNavButton>
      {open ? (
        <StyledDropdown
          ref={dropdownRef}
          onClick={toggleOpen}
          className={show ? 'open' : ''}
          $position={position}
        >
          {children}
        </StyledDropdown>
      ) : null}
    </>
  );
};

export default Dropdown;
