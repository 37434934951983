import React from 'react';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import { StyledFeatureDisabled } from './FeatureDisabled.styled';

interface FeatureDisabledProps {
  upgrade?: boolean;
  attempts?: number;
  small?: boolean;
}

const FeatureDisabled: React.FC<FeatureDisabledProps> = ({
  upgrade,
  attempts,
  small,
}) => {
  const navigate = useNavigate();

  return (
    <StyledFeatureDisabled $small={small}>
      {attempts ? (
        <p className="attempts">
          You can access this feature {attempts} more time
          {attempts === 1 ? '' : 's'} before upgrading
        </p>
      ) : (
        <p className="error">This isn't available at your subscription level</p>
      )}
      {upgrade ? (
        <Button
          $primary
          style={{ margin: '1.5rem auto 0.5rem', display: 'block' }}
          onClick={() => navigate('/pricing/upgrade')}
        >
          Upgrade Now!
        </Button>
      ) : null}
    </StyledFeatureDisabled>
  );
};

export default FeatureDisabled;
