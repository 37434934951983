import { StyledLandingSteps } from './StepsSection.styled';
import { ReactComponent as Step1 } from 'assets/images/icons/steps-1.svg';
import { ReactComponent as Step2 } from 'assets/images/icons/steps-2.svg';
import { ReactComponent as Step3 } from 'assets/images/icons/steps-3.svg';
import { StyledBackgroundEllipse } from 'pages/LandingPage/LandingPage.styled';

const StepsSection = () => (
  <StyledLandingSteps>
    <StyledBackgroundEllipse
      $size="21.875rem"
      $color="#4661CE"
      $top="5%"
      $left="5%"
    />
    <StyledBackgroundEllipse
      $size="18.125rem"
      $color="#4661CE"
      $top="-5%"
      $right="8%"
      $blur="21.625rem"
    />
    <div className="section-header">
      <h2>Get Royalty-Free Music In 3 Simple Steps</h2>
      <h4>Accessing amazing audio couldn't be easier</h4>
    </div>
    <div className="section-content">
      <div className="steps">
        <div className="step">
          <Step1 />
          <div className="step-num">1</div>
          <h3>Create Account</h3>
          <p>
            Choose a pricing plan that works for you, or start your free # day
            trial.
          </p>
        </div>
        <div className="step">
          <Step2 />
          <div className="step-num">2</div>
          <h3>Find Music</h3>
          <p>Our AI tools will help you find tracks quickly and easily.</p>
        </div>
        <div className="step">
          <Step3 />
          <div className="step-num">3</div>
          <h3>Start Creating </h3>
          <p>
            Unleash your talent - with our music empowering your creative
            vision.
          </p>
        </div>
      </div>
    </div>
  </StyledLandingSteps>
);

export default StepsSection;
