import React from 'react';
import { StyledBanner } from './Banner.styled';

const Banner: React.FC = () => (
  <StyledBanner>
    Sign up now for instant access to our music library
  </StyledBanner>
);

export default Banner;
