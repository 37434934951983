import styled from 'styled-components';

export const StyledBasicModalDialog = styled.dialog`
  position: fixed;
  inset: 0;
  margin: auto;
  translate: 0 60px;
  opacity: 0;
  transition: 160ms;
  background: none;
  overflow: initial;
  border: none;
  background: #282828;
  color: #999;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0);
    transition: 160ms;
  }

  &.show {
    translate: 0 0;
    opacity: 1;

    &::backdrop {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;
