import * as downloadApi from 'services/api/downloadsApi';
import { mixpanel } from 'utils';
import { openModal } from './modalActions';

const types = {
  GET_DOWNLOADS_LOADING: 'GET_DOWNLOADS_LOADING',
  GET_DOWNLOADS_SUCCESS: 'GET_DOWNLOADS_SUCCESS',
  GET_DOWNLOADS_ERROR: 'GET_DOWNLOADS_ERROR',
  DOWNLOAD_TRACK_LOADING: 'DOWNLOAD_TRACK_LOADING',
  DOWNLOAD_TRACK_SUCCESS: 'DOWNLOAD_TRACK_SUCCESS',
  DOWNLOAD_TRACK_ERROR: 'DOWNLOAD_TRACK_ERROR',
  SET_ACTIVE_TRACK: 'SET_ACTIVE_TRACK',
  CLEAR_ACTIVE_TRACK: 'CLEAR_ACTIVE_TRACK',
};

export const getDownloads = (payload) => (dispatch) => {
  dispatch({ type: types.GET_DOWNLOADS_LOADING });
  return downloadApi
    .getDownloads(payload)
    .then((downloads) => {
      dispatch({
        type: types.GET_DOWNLOADS_SUCCESS,
        payload: downloads,
      });
    })
    .catch((message) => {
      dispatch({ type: types.GET_DOWNLOADS_ERROR, error: message });
      return message;
    });
};

export const downloadTrack = (track) => (dispatch, getState) => {
  const { user } = getState().auth;
  const { purchases, intent } = getState().purchases;

  if (!user) {
    return dispatch(openModal('signin'));
  }
  if (!user.subscription) {
    dispatch({ type: types.SET_ACTIVE_TRACK, payload: track });
    return dispatch(openModal('subscribe'));
  }
  if (track.is_premium && !purchases.some((p) => p.id === track.id)) {
    dispatch({ type: types.SET_ACTIVE_TRACK, payload: track });
    return dispatch(openModal('purchase'));
  }
  dispatch({ type: types.DOWNLOAD_TRACK_LOADING, payload: track.id });
  return downloadApi
    .downloadTrack(track, intent)
    .then(() => {
      dispatch({
        type: types.DOWNLOAD_TRACK_SUCCESS,
        payload: track,
      });
      mixpanel.track('Track Download', {
        'Track ID': track.id,
        'Track Title': track.title,
      });
      mixpanel.people.increment('Track Downloads');
    })
    .catch((message) => {
      dispatch({ type: types.DOWNLOAD_TRACK_ERROR, error: message });
      return message;
    });
};

export const clearActiveTrack = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ACTIVE_TRACK });
};
