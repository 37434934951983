import { useState, useEffect } from 'react';

const useLazyLoad = (src, speed = 260) => {
  const [show, setShow] = useState(false);
  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    if (src) {
      let removedTimeout = null;
      const image = new Image();

      const reveal = () => {
        // setShow reduces the opacity, but setRemoved actually removes
        // the element from the page after the length of the fade
        setShow(true);
        removedTimeout = setTimeout(() => setRemoved(true), speed);
      };
      image.src = src;
      image.addEventListener('load', reveal);

      return () => {
        image.removeEventListener('load', reveal);
        clearTimeout(removedTimeout);
      };
    }
  }, [src, speed]);

  return { show, removed, speed };
};

export { useLazyLoad };
