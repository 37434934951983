import { ReactComponent as Star } from 'assets/images/icons/star.svg';
import { StyledStars } from './AppSumoRating.styled';

interface StarsProps {
  className?: string;
}

const Stars: React.FC<StarsProps> = (props) => (
  <StyledStars {...props}>
    {[...Array(5)].map((_, i) => (
      <Star key={i} />
    ))}
  </StyledStars>
);

export default Stars;
