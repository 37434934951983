import React from 'react';
import { StyledSearchButtons } from './SearchButtons.styled';
import SearchButton from './SearchButton';
import useSearchBar from 'components/SearchBar/useSearchBar';

interface SearchButtonsProps {
  selected: string;
  handleSelect: (name: string) => void;
  access: boolean;
  upgrade?: boolean;
}

const SearchButtons: React.FC<SearchButtonsProps> = ({
  selected,
  handleSelect,
  access,
  upgrade,
}) => {
  const { tooltips, trial } = useSearchBar();

  return (
    <StyledSearchButtons>
      <SearchButton
        path="/search"
        name="general"
        selected={selected}
        handleClick={handleSelect}
        tooltip={tooltips.general}
      >
        General Search
      </SearchButton>
      <SearchButton
        path="/search"
        name="open"
        selected={selected}
        handleClick={handleSelect}
        tooltip={tooltips.open}
        disabled={!access}
        upgrade={upgrade}
        trialAttempts={trial.open}
      >
        Open Search
      </SearchButton>
      <SearchButton
        path="/search"
        name="similar"
        selected={selected}
        handleClick={handleSelect}
        tooltip={tooltips.similar}
        disabled={!access}
        upgrade={upgrade}
        trialAttempts={trial.similar}
      >
        Sounds Similar
      </SearchButton>
      <SearchButton
        path="/aisearch"
        name="ai"
        selected={selected}
        handleClick={handleSelect}
        tooltip={tooltips.ai}
        disabled={!access}
        upgrade={upgrade}
        trialAttempts={trial.ai}
      >
        <span>
          Persona <span className="highlight">AI</span>
        </span>
        <span className="sub">Powered by ChatGPT</span>
      </SearchButton>
    </StyledSearchButtons>
  );
};

export default SearchButtons;
