const initialState = {
  purchases: [],
  intent: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PURCHASES_LOADING':
    case 'PURCHASE_TRACK_LOADING':
      return {
        ...state,
        loading: true,
        intent: null,
      };

    case 'GET_PURCHASES_SUCCESS':
      return {
        ...state,
        purchases: action.payload,
        loading: false,
        error: null,
      };

    case 'PURCHASE_TRACK_SUCCESS':
      return {
        ...state,
        purchases: [...state.purchases, action.payload.track],
        intent: action.payload.intent,
        loading: false,
        error: null,
      };

    case 'GET_PUCHASES_ERROR':
    case 'PURCHASE_TRACK_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
