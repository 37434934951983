import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { userSignOut } from 'services/state/actions/userActions';
import axiosInstance from 'services/api/config/axiosConfig';
import toastr from 'utils/toastr';
import store from 'services/state/store/configureStore';

interface StoreProviderProps {
  children: ReactNode;
}

const StoreProvider = ({ children }: StoreProviderProps) => {
  // Intercept 403 specifically to sign out a user from an area that
  // has access to the store (not in config file)
  axiosInstance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      if (error.response?.status === 403) {
        store.dispatch(userSignOut({ signin: true }));
        toastr.error(
          'Something went wrong and you have been signed out, please sign in and try again'
        );
      }
      return Promise.reject(error);
    }
  );

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
