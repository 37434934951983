import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Waveform from './Waveform/Waveform';
import TrackControls from './Controls/TrackControls';
import TrackDetails from './Details/TrackDetails';
import TrackDrawer from './Drawer/TrackDrawer';
import { StyledTrack, StyledControls } from './Track.styled';
import { useAudio } from 'hooks';
import { numToTime } from 'utils';
import { openAlbum, preloadAlbum } from 'services/state/actions/albumActions';
import { openArtist } from 'services/state/actions/artistActions';
import { getAltsByTrackId } from 'services/api/trackApi';
import filters from 'constants/filters';
import TrackTags from './Tags/TrackTags';
import useSearchActions from 'pages/SearchPage/useSearchActions';

const Track = (props) => {
  const { track, active, trackListIndex, trackModals, count } = props;
  const { handleQueue, handleRemove, compact, alternate, hasSegment } = props;

  const { track: playerTrack } = useSelector((state) => state.player);

  const isCurrent = playerTrack?.id === track?.id;

  // This will rerender only the playing track for the counter, rather than every track
  const { controls, playing, currentTime } = useAudio(isCurrent);
  const [alternates, setAlternates] = useState([]);
  const [open, setOpenDrawer] = useState(false);
  const { applyTag } = useSearchActions();
  const trackRef = useRef();
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const fetchAlts = useCallback(
    async (scroll) => {
      if (track.alternate_count || alternates.length) {
        const res = await getAltsByTrackId(track.id);
        setAlternates(res);
      }
      setOpenDrawer(true);

      if (scroll) {
        setTimeout(() => {
          const top = trackRef.current.getBoundingClientRect().top;

          document.querySelector('.content').scrollTo({
            top: top - 120,
            behavior: 'smooth',
          });
        }, 300);
      }
    },
    [track, alternates.length]
  );

  useEffect(() => {
    if (active && trackRef.current) {
      fetchAlts(true);
    }
  }, [active, fetchAlts]);

  useEffect(() => {
    if (trackListIndex >= count) return setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 50 * trackListIndex);

    return () => clearTimeout(timer);
  }, [trackListIndex, count]);

  // TODO: Mid-step to support only passing IDs or full tags
  const { tags } = useSelector((state) => state.tags);

  const trackTags = track.tagIds
    ? tags.filter((tag) => track.tagIds.some((t) => t === tag.id))
    : track.tags;

  const handlePlay = (e, seek) => {
    e.stopPropagation();
    if (isCurrent) {
      if (seek !== undefined) {
        return controls.seek(seek);
      }
      return controls.pause();
    }
    controls.load(track.asset_src, (seek || 0) * +track.length);
    handleQueue(trackListIndex);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    handleRemove(track);
  };

  const handleDrawer = (e) => {
    e.stopPropagation();

    if (open) return setOpenDrawer(false);
    fetchAlts();
  };

  const handleArtist = (e, artist) => {
    if (!trackModals) return;

    e.preventDefault();
    return dispatch(openArtist(artist));
  };

  const handleAlbum = (e, album) => {
    if (trackModals) {
      e.preventDefault();
      return dispatch(openAlbum(album));
    }
    dispatch(preloadAlbum(album));
  };

  const handleTag = (e, tag) => {
    e.stopPropagation();

    // TODO: this needs cleaning up once everything is the same
    const filterType =
      tag.type || filters.find((f) => f.title === tag.category)?.type;
    applyTag({ ...tag, type: filterType }, true);
  };

  const segmentStart =
    hasSegment && track.segment_start !== undefined
      ? track.segment_start
      : null;

  return (
    <>
      <StyledTrack
        className={isVisible ? 'visible' : ''}
        ref={trackRef}
        $compact={compact}
        $highlighted={active?.main || open ? 1 : 0}
      >
        <TrackDetails
          track={track}
          isCurrent={isCurrent}
          playing={playing}
          compact={compact}
          handlePlay={handlePlay}
          handleArtist={handleArtist}
        />
        <TrackTags
          tags={trackTags}
          handleTag={handleTag}
          handleDrawer={handleDrawer}
        />
        {!compact && (
          <StyledControls>
            <div className="waveform">
              <Waveform
                id={track.id}
                peaks={track.waveform}
                segmentStart={segmentStart}
                trackLength={+track.length}
                progress={currentTime / +track.length}
                handleSeek={(e, seek) => handlePlay(e, seek)}
              />
            </div>
            <div className="track-length">{numToTime(+track.length)}</div>
          </StyledControls>
        )}
        <TrackControls
          track={track}
          open={open}
          handleRemove={handleRemove ? handleDelete : false}
          handleDrawer={!alternate && handleDrawer}
        />
      </StyledTrack>
      {open ? (
        <TrackDrawer
          track={track}
          alts={alternates}
          tags={trackTags}
          open={open}
          handleTag={handleTag}
          handleAlbum={handleAlbum}
        />
      ) : null}
    </>
  );
};

// TODO: re-memoize track, but needs to be cleaned up and separated out
// Track was not being re-rendered when playing, showing no signs of activity

// const areEqual = (prevProps, nextProps) => {
//   console.log(prevProps, nextProps);
//   return JSON.stringify(prevProps.track) === JSON.stringify(nextProps.track);
// };

export default Track;
