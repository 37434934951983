import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledLandingSteps = styled(StyledLandingSection)`
  text-align: center;

  .steps {
    display: flex;
    justify-content: space-between;
    gap: 4.0625rem;

    & > h3,
    * {
      line-height: normal;
      margin: 0;
    }

    .step {
      & > *:not(:last-child) {
        margin-block-end: 1.125rem;
      }

      & > svg {
        inline-size: 7.5rem;
      }

      .step-num {
        inline-size: 1.625rem;
        block-size: 1.625rem;
        display: grid;
        place-items: center;
        background-color: #3371c5;
        border-radius: 1.625rem;
        margin-inline: auto;
        font-weight: 700;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }

  @media ${device.mobile} {
    .steps {
      flex-direction: column;

      .step {
        & > svg {
          inline-size: 5rem;
        }

        h3 {
          font-size: 1.25rem;
        }
      }
    }
  }
`;
