import React from 'react';
import { Tooltip } from 'react-tooltip';
import {
  StyledSearchButton,
  StyledSearchButtonToolTip,
} from './SearchButtons.styled';
import { mobile } from 'hooks/viewport.signal';
import FeatureDisabled from '../../FeatureDisabled/FeatureDisabled';
import BasicModal from 'components/BasicModal/BasicModal';
import useModalControls from 'components/BasicModal/ModalControls.hooks';

interface SearchButtonProps {
  name: string;
  path?: string;
  selected: string;
  children: React.ReactNode;
  handleClick: (name: string) => void;
  tooltip?: React.ReactNode;
  disabled?: boolean;
  upgrade?: boolean;
  trialAttempts?: number;
}

const SearchButton: React.FC<SearchButtonProps> = ({
  name,
  path,
  selected,
  children,
  handleClick,
  tooltip,
  disabled,
  upgrade,
  trialAttempts,
}) => {
  const { open, handleOpen, handleClose } = useModalControls();

  const disabledWithNoTrial = disabled && !trialAttempts;
  const click = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!path || disabledWithNoTrial) {
      e.preventDefault();
    }
    if (disabledWithNoTrial) {
      handleOpen();
      return;
    }
    handleClick(name);
  };
  const classes: string[] = [];

  if (selected === name && !disabledWithNoTrial) classes.push('active');
  if (disabledWithNoTrial) classes.push('disabled');

  return (
    <>
      <StyledSearchButton
        data-tooltip-id={name + '-search-button'}
        to={path || '/search'}
        className={classes.join(' ')}
        onClick={click}
      >
        {children}
      </StyledSearchButton>

      {!mobile.value ? (
        <Tooltip
          id={name + '-search-button'}
          className="search-button-tooltip"
          clickable={!!upgrade}
        >
          <StyledSearchButtonToolTip>
            {tooltip}
            {disabled ? (
              <FeatureDisabled upgrade={upgrade} attempts={trialAttempts} />
            ) : null}
          </StyledSearchButtonToolTip>
        </Tooltip>
      ) : (
        <BasicModal open={open} handleClose={handleClose}>
          <StyledSearchButtonToolTip>
            {tooltip}
            {disabled ? (
              <FeatureDisabled upgrade={upgrade} attempts={trialAttempts} />
            ) : null}
          </StyledSearchButtonToolTip>
        </BasicModal>
      )}
    </>
  );
};

export default SearchButton;
