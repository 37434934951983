import { FC } from 'react';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import ActionArrowButton from 'components/Button/ActionArrowButton/ActionArrowButton';

const SoundsSimilar: FC = () => {
  return (
    <div className="section-content">
      <div>
        <h2>Sounds Similar Search</h2>
        <h4>Find Music That Sounds Like Your Favorite Tracks</h4>
        <div className="media mobile">
          <video controls>
            <source
              src="https://storage.googleapis.com/persona-music-prod-public/PERSONA%20AI%20DEMO%20VID_Sounds%20Similiar_Edited.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <p>
          Discover songs in the Persona catalog that sound and feel the same as
          any reference track you have in mind. If the track exists on Spotify
          or YouTube, we can find you an equivalent, comparing hundreds of
          acoustic features (key, tempo, instruments, and more) to identify a
          perfect match.
        </p>
        <ActionArrowButton $primary>Start Free Now</ActionArrowButton>
        <AppSumoRating />
      </div>
      <div className="media desktop">
        <video controls>
          <source
            src="https://storage.googleapis.com/persona-music-prod-public/PERSONA%20AI%20DEMO%20VID_Sounds%20Similiar_Edited.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default SoundsSimilar;
