import { Link } from 'react-router-dom';

const ModalLink = ({ to, onClick, children, ...props }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (e.metaKey || e.ctrlKey) return;
    onClick?.(e);
  };

  return (
    <Link to={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export default ModalLink;
