import React from 'react';
import { StyledLandingPage } from './LandingPage.styled';
import StepsSection from 'components/SharedSections/Steps/StepsSection';
import HeroSection from 'components/SharedSections/Hero/HeroSection';
import UseCasesSection from 'components/SharedSections/UseCases/UseCasesSection';
import ExploreSection from 'components/SharedSections/Explore/ExploreSection';
import LatestTracksSection from 'components/SharedSections/LatestTracks/LatestTracksSection';
import QualityMusicSection from 'components/SharedSections/QualityMusic/QualityMusicSection';
import TestimonialsSection from 'components/SharedSections/Testimonials/TestimonialsSection';
import UsedInSection from 'components/SharedSections/UsedIn/UsedInSection';
import FAQSection from 'components/SharedSections/FAQ/FAQSection';
import SignUpSection from 'components/SharedSections/SignUp/SignUpSection';
import SubscribeSection from 'components/SharedSections/Subscribe/SubscribeSection';
import Footer from 'components/Layout/Footer/Footer';
import AISection from 'components/SharedSections/AI/AISection';

const LandingPage: React.FC = () => (
  <StyledLandingPage>
    <HeroSection />
    <StepsSection />
    <UseCasesSection />
    <ExploreSection />
    <AISection />
    <LatestTracksSection />
    <QualityMusicSection />
    <TestimonialsSection />
    <UsedInSection />
    <FAQSection />
    <SignUpSection />
    <SubscribeSection />
    <Footer />
  </StyledLandingPage>
);

export default LandingPage;
