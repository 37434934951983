import React from 'react';
import {
  StyledControls,
  StyledTrack,
  StyledTrackDetails,
  StyledTrackTags,
} from '../Track.styled';

interface LoadingTrackProps {
  index: number;
}

const LoadingTrack: React.FC<LoadingTrackProps> = ({ index }) => (
  <StyledTrack $index={index} className="loading visible">
    <StyledTrackDetails>
      <div className="album-wrapper">
        <div className="album-art content-loading"></div>
        <div className="play-button content-loading"></div>
      </div>
      <div className="details">
        <div className="title content-loading"></div>
        <div className="artist content-loading"></div>
      </div>
    </StyledTrackDetails>
    <StyledTrackTags>
      <span className="tag content-loading"></span>
      <span className="tag content-loading"></span>
      <span className="tag content-loading"></span>
    </StyledTrackTags>
    <StyledControls>
      <div className="waveform content-loading"></div>
      <div className="track-length content-loading"></div>
    </StyledControls>
    <div className="controls">
      <div className="content-loading"></div>
      <div className="content-loading"></div>
      <div className="content-loading"></div>
      <div className="content-loading"></div>
      <div className="content-loading"></div>
      <div className="content-loading"></div>
    </div>
  </StyledTrack>
);

export default LoadingTrack;
