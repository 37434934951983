import { IconButton } from 'components/Button';

import { StyledDrawer } from './TagFilters.styled';
import { ReactComponent as Check } from 'assets/images/icons/check.svg';
import { ReactComponent as Block } from 'assets/images/icons/block.svg';

const Drawer = ({ open, tags, handleToggleTag }) => (
  <StyledDrawer $open={open}>
    {open && (
      <ul>
        {tags.map((tag) => (
          <li key={tag.id}>
            <span
              className={`tag-name${
                tag.included ? ' active' : tag.excluded ? ' inactive' : ''
              }`}
              onClick={() => handleToggleTag(tag, true)}
            >
              {tag.name}
            </span>
            <span
              className={`controls${
                tag.included || tag.excluded ? ' active' : ''
              }`}
            >
              <IconButton
                id={tag.id + '-include'}
                aria-label="include tag"
                tooltip="Include"
                handleClick={() => handleToggleTag(tag, true)}
              >
                <Check className={tag.included ? 'included' : undefined} />
              </IconButton>
              <IconButton
                id={tag.id + '-exclude'}
                aria-label="exclude tag"
                tooltip="Exclude"
                handleClick={() => handleToggleTag(tag, false)}
              >
                <Block className={tag.excluded ? 'excluded' : undefined} />
              </IconButton>{' '}
            </span>
          </li>
        ))}
      </ul>
    )}
  </StyledDrawer>
);

export default Drawer;
