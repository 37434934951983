import { StyledLandingLatestTracks } from './LatestTracksSection.styled';
import MusicComponent from 'components/MusicComponent/MusicComponent';
import { StyledBackgroundEllipse } from 'pages/LandingPage/LandingPage.styled';

const LatestTracksSection = () => (
  <StyledLandingLatestTracks>
    <StyledBackgroundEllipse
      $size="28.25rem"
      $color="#4661CE"
      $bottom="23%"
      $left="7%"
    />
    <div className="section-header">
      <h2>Explore The Latest Tracks Perfect For Your Creative Projects</h2>
      <h4>
        Check out our latest and greatest sounds to take your artistic vision to
        the next level
      </h4>
    </div>
    <div className="section-content">
      <MusicComponent more={false} />
    </div>
  </StyledLandingLatestTracks>
);

export default LatestTracksSection;
