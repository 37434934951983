import styled from 'styled-components';

export const StyledBulletPoint = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: start;

  & > svg {
    flex-shrink: 0;
  }

  &:not(:last-of-type) {
    margin-block-end: 0.5rem;
  }
`;
