import { useContext } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { FormGroup, Label, Error } from '../Form.styled';
import { ThemeContext } from 'styled-components';

const CreditCardField = ({ label, error, handleChange }) => {
  const theme = useContext(ThemeContext);

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: theme.textPrimary,
        color: theme.textPrimary,
        '::placeholder': {
          color: theme.textPlaceholder,
        },
      },
      invalid: {
        iconColor: theme.textPrimary,
        color: theme.textError,
      },
    },
  };

  return (
    <FormGroup>
      <Label>{label}</Label>
      <CardElement
        style={{ height: '40px' }}
        options={CARD_OPTIONS}
        onChange={(e) => handleChange(e)}
      />
      {error && <Error>{error}</Error>}
    </FormGroup>
  );
};

export default CreditCardField;
