import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearSearchBar } from 'services/state/actions/searchBarActions';
import { mixpanel } from 'utils';

const useSearchBar = () => {
  const { loading } = useSelector((state) => state.searchBar);
  const { user, permission } = useSelector((state) => state.auth);
  const location = useLocation();
  const [selected, setSelected] = useState(
    location.pathname === '/aisearch' ? 'ai' : 'general'
  );
  const dispatch = useDispatch();
  const inputRef = useRef();

  const upgrade =
    user?.appsumo?.id &&
    !['personamusic_tier3', 'personamusic_tier4_3'].includes(
      user?.subscription?.type
    );

  const handleSelect = (val) => {
    const buttonMap = {
      ai: 'Persona AI',
      similar: 'Sounds Similar',
      open: 'Open Search',
      general: 'General Search',
    };

    mixpanel.track(`${buttonMap[val]} Button Click`);

    setSelected(val);
    dispatch(clearSearchBar());

    setTimeout(() => {
      inputRef.current?.focus();
    }, 300);
  };

  // TODO: Move this out of here
  const tooltips = {
    general: (
      <h3>
        Search for any <span className="underline">specific</span> song, artist,
        album, or filter tag in the Persona library.
      </h3>
    ),
    open: (
      <>
        <h3>
          Describe the sound you're looking for using any word, concept, or
          idea.
        </h3>
        <p>
          Literally, type in anything you are thinking and we will find the
          perfect track.
        </p>
      </>
    ),
    similar: (
      <>
        <h3>Find music that sounds like your favorite tracks</h3>
        <p>
          Discover songs in the Persona catalog that sound and feel the same as
          any reference track you have in mind. If the track exists on streaming
          services or YouTube, we can find you an equivalent, comparing hundreds
          of acoustic features (key, tempo, instruments, and more) to identify a
          perfect match in our library.
        </p>
      </>
    ),
    ai: (
      <>
        <h3>Your own AI-powered music search assistant</h3>
        <p>
          Harness the power of OpenAI in your search with your own
          ChatGPT-trained music agent. Effortlessly find the perfect track with
          a conversational search interface that helps you pinpoint the exact
          sound you're looking for - reducing traditional search times by 96%.
        </p>
      </>
    ),
  };

  const trial = {
    open: user?.subscription?.trial?.open_search,
    similar: user?.subscription?.trial?.sounds_similar,
    ai: user?.subscription?.trial?.persona_ai,
  };

  return {
    loading,
    inputRef,
    selected,
    permission,
    upgrade,
    tooltips,
    trial,
    handleSelect,
  };
};

export default useSearchBar;
