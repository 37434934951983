import { Button } from 'components/Button';
import React from 'react';

interface SubmitButtonProps {
  children: React.ReactNode;
  loadingLabel?: string;
  loading?: boolean;
  disabled?: boolean;
  name?: string;
  width?: string;
  outline?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  loadingLabel,
  loading,
  disabled,
  name,
  width,
  outline,
}) => (
  <Button
    $primary={!outline}
    $outline={outline}
    type="submit"
    disabled={disabled || loading}
    $full={!width}
    $width={width}
    aria-label={name}
  >
    {loading ? loadingLabel || 'Loading ...' : children}
  </Button>
);

export default SubmitButton;
