import { setQueueAndPlay } from 'services/state/actions/playerActions';
import { toggleShowFilters } from 'services/state/actions/searchActions';
import SortSelect from '../SortSelect/SortSelect';
import Track from '../Track';
import MoreButton from 'components/Button/MoreButton/MoreButton';
import { ReactComponent as Tune } from 'assets/images/icons/tune.svg';
import { StyledTrackList } from '../Track.styled';
import { useAppDispatch } from 'services/state/store/configureStore';
import { useEffect, useMemo, useRef } from 'react';
import NoTracks from './NoTracks';

export interface ITrack {
  id: number;
}

interface TrackListProps {
  tracks?: ITrack[];
  active?: number;
  count?: number;
  loading?: boolean;
  ordering?: string;
  trackModals?: any;
  showFilters?: boolean;
  handleSort?: (ordering: string) => void;
  handleMore?: () => void;
  handleRemove?: (id: number) => void;
  compact?: boolean;
  alternate?: boolean;
  sidebar?: boolean;
  hasSegment?: boolean;
}

const TrackList: React.FC<TrackListProps> = ({
  tracks = [],
  active = false,
  count = 10,
  loading,
  ordering,
  trackModals,
  showFilters,
  handleSort,
  handleMore,
  handleRemove,
  compact,
  alternate,
  hasSegment,
}) => {
  const dispatch = useAppDispatch();
  const trackCountRef = useRef(0);

  const handleQueue = (index: number) =>
    dispatch(setQueueAndPlay({ list: tracks, index, hasSegment }));

  const handleFilters = () => {
    dispatch(toggleShowFilters());
  };

  useEffect(() => {
    if (trackCountRef.current) return;

    trackCountRef.current = tracks.length;
  }, [tracks.length]);

  const minTrackList = useMemo(() => {
    return trackCountRef.current || tracks.length;
  }, [tracks.length]);

  return (
    <StyledTrackList $offset={+!!handleSort}>
      <div className="list-controls">
        {showFilters && (
          <Tune onClick={handleFilters} className="list-filters" />
        )}
        {handleSort && (
          <SortSelect ordering={ordering} handleSort={handleSort} />
        )}
      </div>

      {!tracks.length ? (
        <NoTracks count={count} loading={loading} />
      ) : (
        <div>
          {tracks.map((item, i) => (
            <Track
              key={i}
              active={+active === item.id}
              count={minTrackList}
              track={item}
              trackModals={trackModals}
              trackListIndex={i}
              handleQueue={handleQueue}
              handleRemove={handleRemove}
              compact={compact}
              alternate={alternate}
              hasSegment={hasSegment}
            />
          ))}
        </div>
      )}

      <MoreButton loading={loading} handleClick={handleMore} />
    </StyledTrackList>
  );
};

export default TrackList;
