import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  changeOrder,
  getHomeTracks,
  getMoreHomeTracks,
} from 'services/state/actions/homeActions';
import TagFilters from 'components/Tracks/TagFilters/TagFilters';
import TrackList from 'components/Tracks/List/TrackList';
import { StyledMusic } from './Music.styled';
import { RootState, useAppDispatch } from 'services/state/store/configureStore';

interface MusicComponentProps {
  header?: string;
  more?: boolean;
}

const MusicComponent: React.FC<MusicComponentProps> = ({
  header,
  more = true,
}) => {
  const { tracks, limit, nextPage, loading, ordering } = useSelector(
    (state: RootState) => state.home
  );
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!tracks.length) {
      dispatch(getHomeTracks());
    }
  }, [dispatch, tracks, user]);

  const handleSort = (order: string) => {
    dispatch(changeOrder(order));
  };

  const handleMore = () => {
    dispatch(getMoreHomeTracks());
  };

  return (
    <StyledMusic>
      <h3>{header || '\u00A0'}</h3>
      <div>
        <TagFilters />
        <TrackList
          tracks={tracks}
          loading={loading.tracks}
          count={limit}
          ordering={ordering}
          showFilters={true}
          handleSort={handleSort}
          handleMore={more && nextPage && tracks.length && handleMore}
          sidebar={true}
        />
      </div>
    </StyledMusic>
  );
};

export default MusicComponent;
