import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { device } from 'assets/styles/device';

interface StyledLandingFAQProps {
  $active: boolean;
}

export const StyledLandingFAQ = styled(StyledLandingSection)``;

export const StyledFAQItem = styled.div<StyledLandingFAQProps>`
  color: ${({ $active }) => ($active ? '#fff' : '#B0B0B0')};
  background-color: ${({ $active }) => ($active ? '#000' : 'transparent')};
  margin-block-end: 1.5rem;
  padding: 1.25rem;
  padding-inline-end: 0.9375rem;
  border: 1px solid currentColor;
  font-size: 0.875rem;
  border-radius: 0.75rem;
  transition: all 160ms;

  .question {
    display: grid;
    grid-template-columns: 1fr auto;
    font-size: 1.25rem;
    font-weight: 600;

    button {
      padding-inline: 0.3125rem;

      & > svg {
        inline-size: 1rem;
        block-size: 1rem;
        rotate: ${({ $active }) => $active && '225deg'};
        transition: rotate 160ms;
        display: block;
      }
    }
  }

  .answer {
    padding-block-start: 1.5rem;

    p:last-of-type {
      margin-block-end: 0;
    }
  }

  @media ${device.mobile} {
    padding: 1rem;

    .question {
      font-size: 1.125rem;

      button {
        margin-inline-end: -0.5rem;

        & > svg {
          inline-size: 1.5rem;
          block-size: 1.5rem;
        }
      }
    }

    .answer {
      padding-block-start: 1rem;
    }
  }
`;
