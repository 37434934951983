import { ThemeProvider as Provider } from 'styled-components';
import { GlobalStyles } from 'assets/styles/global';
import { theme } from 'assets/styles/theme';
import 'toastr/build/toastr.min.css';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <Provider theme={theme.dark}>
    <GlobalStyles />
    {children}
  </Provider>
);

export { ThemeProvider };
