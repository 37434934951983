import styled from 'styled-components';

export const StyledInfo = styled.span`
  --info-size: 1.3em;
  width: var(--info-size);
  height: var(--info-size);
  display: inline-block;

  .info {
    display: block;
    width: 100%;
    height: 100%;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .info-tooltip {
    --rt-color-white: #999;
    --rt-color-dark: #282828;
    --rt-opacity: 1;
    --rt-transition-show-delay: 0s;
    --rt-transition-closing-delay: 0s;
    z-index: 1;

    font-size: 1rem;
    font-weight: normal;
    line-height: 1.6;
    padding: 0.6rem;
    max-width: 400px;

    h3 {
      font-size: 1.3rem;
      margin-block-end: 0.5rem;
    }
    li {
      padding-inline-start: 20px;
      position: relative;

      &::before {
        content: '✓';
        position: absolute;
        inset-inline-start: 0;
        color: #337e33;
      }
      &.not::before {
        content: 'x';
        inset-inline-start: 2px;
        color: #e21515;
      }
      p {
        margin: 0;
        font-size: 0.8em;
        opacity: 0.7;
      }
    }
  }
`;

export const StyledTooltip = styled.div`
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6;
  padding: 0.6rem;
  max-width: 400px;

  h3 {
    font-size: 1.3rem;
    margin-block-end: 0.5rem;
  }
  li {
    padding-inline-start: 20px;
    position: relative;

    &::before {
      content: '✓';
      position: absolute;
      inset-inline-start: 0;
      color: #337e33;
    }
    &.not::before {
      content: 'x';
      inset-inline-start: 2px;
      color: #e21515;
    }
    p {
      margin: 0;
      font-size: 0.8em;
      opacity: 0.7;
    }
  }
`;
