import { NavLink } from 'react-router-dom';
import { HEADER_NAV } from 'constants/navigation';
import UserMenu from '../Menu/UserMenu';
import React from 'react';

interface NavProps {
  user: any;
  minimal?: boolean;
  mobile?: boolean;
  sfx?: boolean;
}

const Nav: React.FC<NavProps> = ({ user, minimal, mobile, sfx }) => (
  <nav>
    <ul>
      {!mobile && (
        <>
          {minimal &&
            HEADER_NAV.map((item) => (
              <li key={item.path}>
                <NavLink to={item.path}>{item.title}</NavLink>
              </li>
            ))}

          {sfx ? (
            <li>
              <a
                href="https://sfx.personamusic.io"
                target="_blank"
                rel="noreferrer"
              >
                SFX
              </a>
            </li>
          ) : null}

          {!user?.subscription ? (
            <>
              <li>
                <NavLink to="/pricing">Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/faq">FAQ</NavLink>
              </li>
            </>
          ) : null}
        </>
      )}
      <li>
        <UserMenu />
      </li>
    </ul>
  </nav>
);

export default Nav;
