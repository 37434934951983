import { StyledLandingFAQ } from './FAQSection.styled';
import FAQItem from './FAQItem';
import faqItems from 'constants/faq';
import { StyledBackgroundEllipse } from 'pages/LandingPage/LandingPage.styled';

const items = [0, 1, 2, 4, 9];

const FAQSection = () => (
  <StyledLandingFAQ>
    <StyledBackgroundEllipse
      $size="25.75rem"
      $color="#4661CE"
      $top="82%"
      $left="35%"
      style={{ opacity: 0.8 }}
    />
    <div className="section-header">
      <h2>Frequently Asked Questions</h2>
      <h4>Need more info? We're here to help</h4>
    </div>
    <div className="section-content">
      {items.map((item, i) => (
        <FAQItem key={item} item={faqItems[item]} defaultOpen={i === 0} />
      ))}
    </div>
  </StyledLandingFAQ>
);

export default FAQSection;
