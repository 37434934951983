import image1 from 'assets/images/testimonials/image-1.png';
import image2 from 'assets/images/testimonials/image-2.png';
import image3 from 'assets/images/testimonials/image-3.png';
import image4 from 'assets/images/testimonials/image-4.png';
import image5 from 'assets/images/testimonials/image-5.png';
import image6 from 'assets/images/testimonials/image-6.png';
import image7 from 'assets/images/testimonials/image-7.png';
import imageDefault from 'assets/images/testimonials/default.png';

const testimonials = [
  {
    title: `The music is so good!`,
    text: `I cannot even believe the quality of music. I've already used it to make two different projects in the matter of days. Captivating, studio quality music that goes beyond just sounding professional and takes it to a new level.`,
    user: 'dhari',
    avatar: imageDefault,
  },
  {
    title: `Amazing audio files with cinema quality!`,
    text: `Persona is the best collection of soundtrack/music that I've ever heard. The music they have IS just incredible.`,
    user: 'gisele',
    avatar: imageDefault,
  },
  {
    title: `Fantastic Music`,
    text: `The music is top notch. Crisp and clear. Really glad I bought this`,
    user: 'scrittiwolf',
    avatar: image1,
  },
  {
    title: `Filmmaker`,
    text: `This absolutely deserves 5 stars! It is almost too good to be true. The music is excellent and very easy to edit. I'm very excited to be on the Persona-wave!`,
    user: 'RemcoNL',
    avatar: image2,
  },
  {
    title: `Has a great future`,
    text: `The production quality of the tracks is right at the top.Their library is really one of the best I've seen.`,
    user: 'nickelgr',
    avatar: imageDefault,
  },
  {
    title: `Awesome Music!`,
    text: `Definitely one of the top tier tools in our collection!`,
    user: 'tswconsulting',
    avatar: image3,
  },
  {
    title: `Finally found this`,
    text: `I've been looking for something like this for a while. Each day we livestream a very different bit of gaming content, so having music to match the genre / mood of the game is very valuable. Worth noting also in the month or so of us using Persona Music, we've used music for every weekday, uploading to YouTube and Twitch. Not once have we been copyright striked.`,
    user: 'streamer rick46',
    avatar: image4,
  },
  {
    title: `Must for content creators`,
    text: `I already have a few places to go for music and sound effects, but this is another level. Great music selection! Highly recommended.`,
    user: 'joe2000',
    avatar: imageDefault,
  },
  {
    title: `Impressed`,
    text: `I have several subscriptions to music services because I need royalty-free music for my customers.This one is a cut above in terms of musical quality, really do not miss it if you need music for your customers, or even for your creations. In short, it is a must, for sure`,
    user: 'Fabien',
    avatar: image5,
  },
  {
    title: `One of the Best, if not the Best on the Market`,
    text: `I recently discovered Persona Music, and I cannot express how much of a game-changer it's been for my video content creation. This platform is nothing short of a godsend. It's transformed my content creation experience and has earned my highest approval.`,
    user: 'HLM',
    avatar: image6,
  },
  {
    title: `A Must Have For Content Creators`,
    text: `I create a lot of youtube videos and short-form content.This is a must-have tool for content creators. The music on the platform sounds AMAZING and super quality! Keep up the good work guys!`,
    user: 'Tario',
    avatar: image7,
  },
];

export default testimonials;
