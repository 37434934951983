import { emailRegex } from 'constants/regex';
import { useFormik } from 'formik';

const initialValues = {
  email: '',
  password: '',
};

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Your email is required';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Your password is required';
  }
  return errors;
};

const useSignInForm = (handleSubmit) => {
  return useFormik({ initialValues, validate, onSubmit: handleSubmit });
};

export default useSignInForm;
