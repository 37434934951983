const initialState = {
  masterclasses: [],
  limit: 12,
  offset: 0,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MASTERCLASSES_LOADING':
    case 'GET_MORE_MASTERCLASSES_LOADING':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'GET_MASTERCLASSES_SUCCESS':
    case 'GET_MORE_MASTERCLASSES_SUCCESS':
      return {
        ...state,
        masterclasses: [...state.masterclasses, ...action.payload.results],
        offset: action.payload.offset,
        loading: false,
        error: null,
      };

    case 'GET_MASTERCLASSES_ERROR':
    case 'GET_MORE_MASTERCLASSES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
