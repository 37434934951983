import React, { FC, useState } from 'react';
import SectionTabsButtons from './SectionTabsButtons';

interface SectionTabsProps {
  sections: {
    title: string;
    icon?: React.ReactNode;
    component: React.ReactNode;
  }[];
}

const SectionTabs: FC<SectionTabsProps> = ({ sections }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <SectionTabsButtons
        sections={sections}
        active={activeTab}
        handleChange={(index: number) => setActiveTab(index)}
      />
      {sections[activeTab].component}
    </div>
  );
};

export default SectionTabs;
