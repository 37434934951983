import { options } from 'constants/trackSorting';
import { StyledSortSelect } from './SortSelect.styled';

const SortSelect = ({ ordering, handleSort }) => (
  <StyledSortSelect
    name="sorting"
    defaultValue={ordering}
    onChange={(e) => {
      handleSort(e.target.value);
    }}
  >
    {options.map((option) => (
      <option key={option.id} value={option.id}>
        {option.value}
      </option>
    ))}
  </StyledSortSelect>
);

export default SortSelect;
