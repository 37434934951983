import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSignIn, clearError } from 'services/state/actions/userActions';
import { openModal } from 'services/state/actions/modalActions';
import SignInForm from './SignInForm';
import ForgotForm from './ForgotForm';
import { StyledModalContent } from 'components/Modal/Modal.styled';
import { useNavigate } from 'react-router';
import { useQuery } from 'hooks';
import { getPermissionFromUser } from 'utils';

const SignInModal = ({ redirect, handleClose }) => {
  const { loading, error } = useSelector((state) => state.auth);
  const [forgot, setForgot] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const finalRedirect = query.redirect || redirect;

  useEffect(() => {
    return () => dispatch(clearError());
  }, [dispatch]);

  const handleForgot = () => setForgot(!forgot);
  const handleSignup = () => dispatch(openModal('signup', { finalRedirect }));

  const handleSubmit = (values) => {
    const data = { ...values, email: values.email.toLowerCase() };
    dispatch(userSignIn(data))
      .then((user) => {
        if (finalRedirect) {
          navigate(finalRedirect);
          return handleClose();
        }
        if (getPermissionFromUser(user) >= 2) {
          navigate(`/aisearch`);
        }
        handleClose();
      })
      .catch((err) => {
        if (err) return;
      });
  };

  return (
    <StyledModalContent>
      {forgot ? (
        <>
          <div className="modal-header">
            <h3>Forgot Password</h3>
            <p>Enter your email address to reset your password</p>
          </div>
          <ForgotForm handleClose={handleClose} handleForgot={handleForgot} />
        </>
      ) : (
        <>
          <div className="modal-header">
            <h3>Login</h3>
          </div>
          <SignInForm
            loading={loading}
            error={error}
            handleForgot={handleForgot}
            handleSignup={handleSignup}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </StyledModalContent>
  );
};

export default SignInModal;
