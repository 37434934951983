export const USER = {
  ACCOUNT: [
    { title: 'Settings', path: '/user/account/settings' },
    { title: 'Subscription', path: '/user/account/subscription' },
    { title: 'Linked Accounts', path: '/user/account/linked' },
  ],
  MUSIC: [
    { title: 'Favorites', path: '/user/music/favorites' },
    { title: 'Projects', path: '/user/music/projects' },
    { title: 'Downloads', path: '/user/music/downloads' },
    { title: 'Purchases', path: '/user/music/purchases' },
  ],
};

export const HEADER_NAV = [
  { title: 'Home', path: '/' },
  { title: 'Music', path: '/music' },
  { title: 'Playlists', path: '/playlists' },
];

export const HEADER_AUTH = [
  { title: 'My Music', path: '/user/music', children: USER.MUSIC },
  { title: 'Reports', path: '/user/reports', permission: ['partner'] },
  { title: 'Account', path: '/user/account' },
];
