import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'assets/images/icons/chevron-left.svg';
import { StyledBackButton } from '../Button.styled';
import React from 'react';

interface PageBackButtonProps {
  path?: string;
}

const PageBackButton: React.FC<PageBackButtonProps> = ({ path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
      return;
    }
    navigate(-1);
  };
  return (
    <StyledBackButton>
      <button onClick={handleClick}>
        <ChevronLeft /> Back
      </button>
    </StyledBackButton>
  );
};

export default PageBackButton;
