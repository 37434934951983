import { useFormik } from 'formik';
import Form, { SelectField, SubmitButton } from 'components/Form';
import { Button } from 'components/Button';

const initialValues = { project: '' };

const validate = (values) => {
  const errors = {};

  if (!values.project) {
    errors.project = 'You must choose a project';
  }
  return errors;
};

const AddToProjectForm = ({ projects, handleSwitch, onSubmit, loading }) => {
  const formik = useFormik({ initialValues, validate, onSubmit });

  if (!projects.length) {
    return (
      <Button $outline type="button" onClick={handleSwitch}>
        Create New Project
      </Button>
    );
  }
  return (
    <Form handleSubmit={formik.handleSubmit}>
      <SelectField
        label="Choose your project"
        options={projects}
        name="project"
        placeholder={'Select...'}
        formik={formik}
      />
      <SubmitButton loadingLabel="Saving..." loading={loading}>
        Add to Project
      </SubmitButton>
      <p className="alternate">
        Or create a{' '}
        <span className="link" onClick={handleSwitch}>
          new project
        </span>
      </p>
    </Form>
  );
};

export default AddToProjectForm;
