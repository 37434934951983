import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Form, { CreditCardField, SubmitButton } from 'components/Form';
import { purchaseTrack } from 'services/api/purchasesApi';

const PurchaseForm = ({
  track,
  email,
  loading,
  error,
  handlePurchase,
  handleLoading,
  handleError,
}) => {
  const [localError, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (e) => {
    setError(e.error?.message);
    setCardComplete(e.complete);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = {
      payment_method: {
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: { email },
      },
    };

    if (!stripe || !elements || !cardComplete) return;
    if (localError) return elements.getElement('card').focus();

    handleLoading();

    try {
      const { client_secret } = await purchaseTrack(track);
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        client_secret,
        config
      );
      if (error) throw error;
      elements.getElement('card').clear();
      return handlePurchase(paymentIntent.id);
    } catch (error) {
      handleError(error.message);
    }
  };

  return (
    <Form handleSubmit={handleSubmit}>
      <CreditCardField
        label="Credit Card"
        error={error}
        handleChange={handleChange}
      />
      <SubmitButton
        loading={loading}
        // disabled={loading || !stripe || !cardComplete}
        loadingLabel="Processing..."
      >
        Continue
      </SubmitButton>
    </Form>
  );
};

export default PurchaseForm;
