import axios, { getWaveform, getOffset } from './config/axiosConfig';

export const getPartners = async (payload) => {
  try {
    const res = await axios.get(`/api/points/partners`, { params: payload });
    const { results, next } = res.data;
    const nextOffset = getOffset(next);

    return { results, offset: nextOffset };
  } catch (error) {
    throw error;
  }
};

export const getPartner = async (id) => {
  try {
    const res = await axios.get(`/api/points/partners/${id}`);
    const partner = res.data;

    if (partner.tracks) {
      const tracks = partner.tracks.map((track) => {
        const waveform = getWaveform(track.waveform);
        track.waveform = waveform;
        return track;
      });
      partner.tracks = tracks;
    }
    return partner;
  } catch (error) {
    throw error;
  }
};
