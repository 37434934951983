import { signal, effect, computed } from '@preact/signals-react';
import { size } from 'assets/styles/device';

const width = signal(window.innerWidth);

effect(() => {
  const handleResize = () => {
    width.value = window.innerWidth;
  };
  window.addEventListener('resize', handleResize);
});

const mobile = computed(() => width.value <= size.mobile);

export { mobile, width };
