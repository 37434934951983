import { StyledTestimonial } from './TestimonialsSection.styled';
import Stars from 'components/AppSumo/Stars';
import { ReactComponent as Check } from 'assets/images/icons/check-new.svg';
import React from 'react';

interface TestimonialProps {
  title: string;
  text: string;
  user: string;
  avatar: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
  title,
  text,
  user,
  avatar,
}) => (
  <StyledTestimonial>
    <div>
      <Stars />
      <div className="test-title">{title}</div>
      <div className="test-content">{text}</div>
    </div>
    <div className="test-footer">
      <img className="test-avatar" src={avatar} alt={user} />
      <div className="test-username">{user}</div>
      <span className="verified">
        <Check /> Verified Review
      </span>
    </div>
  </StyledTestimonial>
);

export default Testimonial;
