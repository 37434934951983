import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledUserMenu } from './UserMenu.styled';
import { HEADER_NAV, HEADER_AUTH } from 'constants/navigation';
import { ReactComponent as Login } from 'assets/images/icons/login.svg';
import Dropdown from 'components/Dropdown/Dropdown';
import useUserMenu from './UserMenu.hooks';

const UserMenu: React.FC = () => {
  const { user, mobile, subscription, handleClick, handleSignIn } =
    useUserMenu();

  return !user ? (
    <button className="login" onClick={handleSignIn}>
      <Login />
      Login
    </button>
  ) : (
    <StyledUserMenu>
      <Dropdown target={<Login />} position="left">
        <div className="user-details">
          <span>{user.username}</span>
          <span className="user-email">{user.email}</span>
          {subscription ? (
            <span className="user-email">{subscription?.name} Plan</span>
          ) : null}
        </div>
        {mobile &&
          HEADER_NAV.map((item) => (
            <NavLink key={item.title} to={item.path}>
              {item.title}
            </NavLink>
          ))}
        {HEADER_AUTH.map((item) => {
          if (item.permission?.includes('partner') && !user.is_partner)
            return null;

          return item.children ? (
            <div className="nav-group" key={item.title}>
              <span>{item.title}</span>
              {item.children.map((child) => (
                <NavLink key={child.title} to={child.path}>
                  {child.title}
                </NavLink>
              ))}
            </div>
          ) : (
            <NavLink key={item.title} to={item.path}>
              {item.title}
            </NavLink>
          );
        })}
        {!mobile ? <NavLink to="/faq">FAQ</NavLink> : null}
        <NavLink to="/signout" className="sign-out" onClick={handleClick}>
          Sign Out
        </NavLink>
      </Dropdown>
    </StyledUserMenu>
  );
};

export default UserMenu;
