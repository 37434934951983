import * as searchApi from 'services/api/searchApi';
import { decrementAppsumoTrial } from './userActions';
import { mixpanel } from 'utils';

const types = {
  SEARCH_TERM_LOADING: 'SEARCH_TERM_LOADING',
  SEARCH_TERM_SUCCESS: 'SEARCH_TERM_SUCCESS',
  SEARCH_TERM_ERROR: 'SEARCH_TERM_ERROR',
  SEARCH_TAGS_LOADING: 'SEARCH_TAGS_LOADING',
  SEARCH_TAGS_SUCCESS: 'SEARCH_TAGS_SUCCESS',
  SEARCH_TAGS_ERROR: 'SEARCH_TAGS_ERROR',
  SEARCH_MORE_TAGS_LOADING: 'SEARCH_MORE_TAGS_LOADING',
  SEARCH_MORE_TAGS_SUCCESS: 'SEARCH_MORE_TAGS_SUCCESS',
  SEARCH_MORE_TAGS_ERROR: 'SEARCH_MORE_TAGS_ERROR',
  SEARCH_TAGS_RESET: 'SEARCH_TAGS_RESET',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  CLEAR_RESULTS: 'CLEAR_RESULTS',
  OPEN_SEARCH: 'OPEN_SEARCH',
  CLOSE_SEARCH: 'CLOSE_SEARCH',
  SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
  TOGGLE_SHOW_FILTERS: 'TOGGLE_SHOW_FILTERS',
  CHANGE_SEARCH_SORT_ORDER: 'CHANGE_SEARCH_SORT_ORDER',
  SEARCH_SIMILAR_TRACK_LOADING: 'SEARCH_SIMILAR_TRACK_LOADING',
  SEARCH_SIMILAR_TRACK_SUCCESS: 'SEARCH_SIMILAR_TRACK_SUCCESS',
  SEARCH_SIMILAR_TRACK_ERROR: 'SEARCH_SIMILAR_TRACK_ERROR',
  CONVERT_KEYWORDS_SUCCESS: 'CONVERT_KEYWORDS_SUCCESS',
  SET_TAGS: 'SET_TAGS',
};

export const openSearch = () => (dispatch) => {
  dispatch({ type: types.OPEN_SEARCH });
};

export const closeSearch = () => (dispatch) => {
  dispatch({ type: types.CLOSE_SEARCH });
};

export const toggleSearch = (open) => (dispatch) => {
  const type = open ? types.OPEN_SEARCH : types.CLOSE_SEARCH;
  dispatch({ type });
};

export const clearTermSearch = () => (dispatch) => {
  dispatch({ type: types.CLEAR_SEARCH });
};

export const clearSearchResults = () => (dispatch) => {
  dispatch({ type: types.CLEAR_RESULTS });
};

export const clearTagsSearch = () => (dispatch) => {
  dispatch({ type: types.SEARCH_TAGS_RESET });
};

export const clearSearch = () => (dispatch) => {
  dispatch(clearTermSearch());
  dispatch(clearTagsSearch());
};

export const searchTerm = (term) => {
  return (dispatch) => {
    if (term?.length < 3) {
      return dispatch(clearSearchResults());
    }
    dispatch({ type: types.SEARCH_TERM_LOADING });
    return searchApi
      .searchTerm(term)
      .then((results) => {
        mixpanel.track('General Search Query', { Term: term });

        dispatch({
          type: types.SEARCH_TERM_SUCCESS,
          payload: results,
        });
      })
      .catch((message) => {
        dispatch({ type: types.SEARCH_TERM_ERROR, error: message });
        return message;
      });
  };
};

export const searchTags = (payload) => async (dispatch, getState) => {
  const { trackLimit, ordering } = getState().searchTags;
  const { slb, ssp, inc, exc, term, keywords } = payload;
  const { tags, keywordsLookup } = getState().tags;
  const social = tags.find((tag) => tag.name === 'Social Media');

  const apiPayload = {
    tags: [...inc, social.id].join(','),
    track_limit: trackLimit,
    ordering,
  };

  let trialType;

  const setAiSearchType = () => {
    if (slb) {
      trialType = 'similar_tracks';
      return (apiPayload['library_track_id'] = slb);
    }
    if (ssp) {
      trialType = 'sounds_similar';
      return (apiPayload['spotify_track_id'] = ssp);
    }
    if (term?.length) {
      trialType = 'open_search';
      return (apiPayload['term'] = term);
    }
  };

  if (exc?.length) {
    apiPayload['exclude'] = exc.join(',');
  }
  if (keywords?.length) {
    apiPayload['keywords'] = keywords.join(',');
  }

  setAiSearchType();

  dispatch({ type: types.SET_TAGS, payload });
  dispatch({ type: types.SEARCH_TAGS_LOADING, clearTracks: true });

  try {
    if (trialType) {
      await dispatch(decrementAppsumoTrial({ trial: trialType }));
    }
    const results = await searchApi.searchTags(apiPayload);

    if (!results) return;

    if (results.keywordLookup || keywordsLookup) {
      dispatch({
        type: types.CONVERT_KEYWORDS_SUCCESS,
        payload: results.keywordLookup || keywordsLookup,
      });
    }
    dispatch({
      type: types.SEARCH_TAGS_SUCCESS,
      payload: results,
    });
  } catch (error) {
    dispatch({ type: types.SEARCH_TAGS_ERROR, error: error });
    return error;
  }
};

export const searchMoreTags = () => (dispatch, getState) => {
  const { trackLimit, trackNext, ordering } = getState().searchTags;
  const { tags, included, excluded, similarLibrary, similarSpotify } =
    getState().tags;

  const social = tags.find((tag) => tag.name === 'Social Media');

  const apiPayload = {
    tags: [...included, social].map((i) => i.id).join(','),
    ordering,
    track_limit: trackLimit,
    track_page: trackNext,
  };

  if (excluded?.length) {
    apiPayload['exclude'] = excluded.map((i) => i.id).join(',');
  }
  if (similarLibrary) {
    apiPayload['library_track_id'] = similarLibrary;
  }
  if (similarSpotify) {
    apiPayload['spotify_track_id'] = similarSpotify;
  }

  dispatch({ type: types.SEARCH_MORE_TAGS_LOADING });
  return searchApi
    .searchTags(apiPayload)
    .then((results) => {
      dispatch({
        type: types.SEARCH_MORE_TAGS_SUCCESS,
        payload: results,
      });
    })
    .catch((message) => {
      dispatch({ type: types.SEARCH_MORE_TAGS_ERROR, error: message });
      return message;
    });
};

export const setSelectedFilter = (payload) => (dispatch) => {
  dispatch({ type: types.SET_SELECTED_FILTER, payload });
};

export const toggleShowFilters = (payload) => (dispatch) => {
  dispatch({ type: types.TOGGLE_SHOW_FILTERS, payload });
};

export const changeOrder =
  ({ included, excluded, order }) =>
  (dispatch) => {
    dispatch({ type: types.CHANGE_SEARCH_SORT_ORDER, payload: order });
    dispatch(
      searchTags({
        inc: included.map((i) => i.id),
        exc: excluded.map((e) => e.id),
      })
    );
  };

export const searchSimilarByTrack = (payload) => (dispatch) => {
  const { trackId, included, excluded } = payload;
  const tags = {
    inc: included,
    exc: excluded,
  };
  dispatch({ type: types.SEARCH_SIMILAR_TRACK_LOADING, clear: true });

  return searchApi
    .getSimilarByTrack({ trackId, tags })
    .then((results) => {
      dispatch({
        type: types.SEARCH_SIMILAR_TRACK_SUCCESS,
        payload: results,
      });
    })
    .catch((message) => {
      dispatch({ type: types.SEARCH_SIMILAR_TRACK_ERROR, error: message });
      return message;
    });
};

export const searchSimilarBySpotify = (id) => (dispatch) => {
  dispatch({ type: types.SEARCH_SIMILAR_TRACK_LOADING, clear: true });

  return searchApi
    .getSimilarBySpotify(id)
    .then((results) => {
      dispatch({
        type: types.SEARCH_SIMILAR_TRACK_SUCCESS,
        payload: results,
      });
    })
    .catch((message) => {
      dispatch({ type: types.SEARCH_SIMILAR_TRACK_ERROR, error: message });
      return message;
    });
};
