const initialState = {
  projects: [],
  track: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJECTS_LOADING':
    case 'CREATE_PROJECT_LOADING':
    case 'UPDATE_PROJECT_LOADING':
    case 'DELETE_PROJECT_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_PROJECTS_SUCCESS':
      return {
        ...state,
        projects: action.payload,
        loading: false,
        error: null,
      };

    case 'CREATE_PROJECT_SUCCESS':
      return {
        ...state,
        projects: [action.payload, ...state.projects],
        loading: false,
        error: null,
      };

    case 'UPDATE_PROJECT_SUCCESS':
      const projects = [...state.projects];
      const index = state.projects.findIndex((p) => p.id === action.payload.id);
      projects[index] = action.payload;

      return {
        ...state,
        projects: projects,
        loading: false,
        error: null,
      };

    case 'DELETE_PROJECT_SUCCESS':
      const filtered = state.projects.filter(
        (item) => action.payload !== item.id
      );
      return {
        ...state,
        projects: filtered,
        loading: false,
        error: null,
      };

    case 'GET_PROJECTS_ERROR':
    case 'CREATE_PROJECT_ERROR':
    case 'UPDATE_PROJECT_ERROR':
    case 'DELETE_PROJECT_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
