import axios from './config/axiosConfig';
import filters from 'constants/filters';

export const getTags = async () => {
  try {
    const res = await axios.get('/api/search/tags');

    const sortedTags = res.data.reduce((acc, group) => {
      const { type, grouping } = filters.find((f) => f.title === group.name);
      const set = group.tag_set.map((tag) => ({ ...tag, type, grouping }));
      return [...acc, ...set];
    }, []);

    return sortedTags;
  } catch (error) {
    throw error;
  }
};
