const initialState = {
  spotifyResults: null,
  term: null,
  open: false,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_BAR_LOADING':
      return {
        ...state,
        loading: true,
        open: true,
        error: null,
      };

    case 'SEARCH_BAR_SIMILAR_SUCCESS':
      return {
        ...state,
        spotifyResults: action.payload,
        loading: false,
        error: null,
      };

    case 'SEARCH_BAR_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CLEAR_SEARCH_BAR':
      return initialState;

    default:
      return state;
  }
};

export default reducer;
