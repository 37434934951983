import styled from 'styled-components';

export const StyledSegment = styled.span`
  inset-inline-start: ${({ $start }) => $start}%;
  inset-block-start: -3px;
  inline-size: ${({ $width }) => $width}%;
  block-size: 100%;
  position: absolute;
  outline: 1px solid;
  padding-block: 3px;
  box-sizing: initial;

  span {
    white-space: nowrap;
    position: absolute;
    bottom: -17px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 0.85rem;
    color: #aaa;
  }
`;

export const StyledWaveform = styled.div`
  position: relative;
  width: 100%;

  .progress {
    position: relative;
    transition: clip-path 0.2s linear;
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
  svg {
    display: block;
  }
  .waveform-tooltip {
    --rt-color-white: #999;
    --rt-color-dark: #282828;
    --rt-opacity: 1;
    --rt-transition-show-delay: 0s;
    --rt-transition-closing-delay: 0s;
    font-family: inherit;
    z-index: 1;
    font-weight: 700;
  }
`;
