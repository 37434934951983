import styled from 'styled-components';
import {
  StyledTrackDetails,
  StyledUserControls,
} from 'components/Tracks/Track.styled';
import { device } from 'assets/styles/device';

export const StyledPlayer = styled.div`
  color: hsla(0, 0%, 100%, 1);
  background: ${({ theme }) => theme.backgroundPrimary};
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.playerHeight};
  transition: height 0.16s;
  overflow: hidden;
  z-index: 9999999999999999999;
  display: grid;
  grid-template-columns: auto auto minmax(40px, 100%) minmax(200px, auto);
  gap: 2rem;
  padding: 0 1.5rem;
  border-top: 1px solid #222;
  box-shadow: 0px -9px 18px 0px #000;

  &.hide {
    height: 0;
  }
  .icon-button {
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
  .close {
    padding: 8px;
    cursor: pointer;
    box-sizing: initial;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.3;
    transition: opacity 0.2s;
  }
  &:hover {
    .close {
      opacity: 1;
    }
  }
  ${StyledUserControls} {
    display: flex;

    button + button {
      margin-left: 0.5rem;
    }
  }
  @media ${device.mobile} {
    grid-template-columns: calc(50% - 40px) 80px 0 calc(50% - 40px);
    gap: 0;
  }
`;

export const StyledPlayerDetails = styled(StyledTrackDetails)`
  flex: initial;

  .album-art {
    --album-size: 80px;
    background: #ff00ff33;
    width: var(--album-size);
    height: var(--album-size);
    margin: 0 1rem 0 0;
    background: url(${({ $art }) => $art});
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    cursor: pointer;
  }
`;

export const StyledProgress = styled.div`
  display: flex;
  align-items: center;

  .time {
    margin-left: 1rem;
    font-size: 0.8rem;
    min-width: 40px;
    text-align: right;
    word-wrap: nowrap;
    flex-shrink: 0;
  }
`;

export const StyledBarProgress = styled.div.attrs((props) => ({
  style: { width: props.progress + '%' },
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  transition: width 200ms;
  background-attachment: fixed;

  background: ${({ theme }) =>
    `linear-gradient(
      59deg,
      ${theme.highlightSecondary} 20%,
      ${theme.highlightPrimary}
    )`};
`;

export const StyledVolume = styled.div`
  width: 36px;
  display: flex;
  align-items: center;
  padding: 12px;
  overflow: hidden;
  transition: width 0.2s;
  margin-right: 1.5rem;

  &:hover {
    width: 160px;
  }

  .volume-icon {
    width: 32px;
    margin-right: 1rem;
    flex-shrink: 0;

    svg {
      display: block;
    }
  }
  .MuiSlider-root {
    color: currentColor;
    margin-right: 8px;
  }
  .MuiSlider-thumb {
    background-color: currentColor;
  }
  .MuiSlider-rail {
    height: 4px;
  }
  .MuiSlider-track {
    height: 6px;
    border: 0;
    background: linear-gradient(
      59deg,
      hsla(261, 56%, 43%, 1) 20%,
      hsla(228, 58%, 54%, 1)
    );
  }
  @media ${device.mobile} {
    margin-right: 0.5rem;
  }
`;
