import { StyledButton } from 'components/Button/Button.styled';
import styled from 'styled-components';

export const StyledOpenInput = styled.form`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-inline-end: 16px;

  ${StyledButton} {
    inline-size: 5rem;
    block-size: auto;
    padding: 0.5rem 0;
  }
`;
