import { StyledButton } from 'components/Button/Button.styled';
import styled from 'styled-components';

const formDefaults = {
  fieldRadius: '4px',
};

export const Error = styled.p<{ center?: boolean }>`
  color: ${({ theme }) => theme.textError};
  text-align: ${({ center }) => center && 'center'};
  margin: 8px 0 0;
  grid-column-end: span 2;
`;

export const StyledForm = styled.form<{ $grid?: number; $account?: boolean }>`
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  ${({ $grid }) =>
    $grid &&
    `  
      display: grid;
      grid-template-columns: repeat(${$grid}, 1fr);
      gap: 0 36px;

      ${Error} {
        grid-column-end: span ${$grid || 1};
      }
      button[type='submit'] {
        grid-column-end: span ${$grid || 1};
        justify-self: start;
      }
  `}

  .alternate {
    font-size: smaller;
    opacity: 0.8;
    text-align: center;
    line-height: initial;
    margin: 1rem auto;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .StripeElement {
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: min(1.1rem, 16px);
    padding: 8px 18px;
    color: '#ff00ff';
    background: none;
    height: 40px;
    font-weight: 600;
    font-family: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${StyledButton} {
    margin-block: 1rem 2.4rem;
    margin-inline: ${({ $account }) => ($account ? 0 : 'auto')};

    block-size: 3.4rem;
    max-inline-size: ${({ $account }) => ($account ? '160px' : '250px')};

    display: block;

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
      outline: none;
      filter: brightness(1.2);
    }
  }
`;

export const FormGroup = styled.div<{ $inlinebutton: boolean }>`
  width: 100%;
  margin: 0 auto 32px;
  display: inline-block;

  .input-wrapper {
    ${({ $inlinebutton }) =>
      $inlinebutton &&
      `
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1.6rem;
    `}
    ${StyledButton} {
      ${({ $inlinebutton }) =>
        $inlinebutton &&
        `
          height: 100%;
          margin: 0;
        `}
    }
  }

  // ugh...
  .recaptcha {
    & > div {
      & > div {
        margin: 0 auto;
      }
    }
  }
`;

export const Label = styled.label`
  display: grid;
  grid-template-columns: 1fr auto;
  color: inherit;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: ${formDefaults.fieldRadius};
  border: ${({ theme }) => theme.formFieldBorder};
  font-size: min(1.1rem, 16px);
  padding: 8px 18px;
  color: inherit;
  background: ${({ theme }) => theme.backgroundPrimary};
  height: 40px;
  font-weight: 600;
  font-family: inherit;

  &:disabled {
    background: #111;
  }

  &::placeholder {
    color: ${({ theme }) => theme.textPlaceholder};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: ${({ theme }) => theme.formFieldBorder};
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    transition: all 0s 50000s;
  }
`;

export const Select = styled.select`
  width: 100%;
  border-radius: ${formDefaults.fieldRadius};
  border: ${({ theme }) => theme.formFieldBorder};
  font-size: min(1.1rem, 16px);
  padding: 8px 18px;
  color: inherit;
  background: none;
  height: 40px;
  font-weight: 600;
  font-family: inherit;

  option {
    color: initial;
  }

  &::placeholder {
    color: ${({ theme }) => theme.textPlaceholder};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: ${({ theme }) => theme.formFieldBorder};
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    transition: all 0s 50000s;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  border-radius: ${formDefaults.fieldRadius};
  border: ${({ theme }) => theme.formFieldBorder};
  font-size: min(1.1rem, 16px);
  padding: 8px 18px;
  color: inherit;
  background: none;
  font-weight: 600;
  font-family: inherit;

  &::placeholder {
    color: ${({ theme }) => theme.textPlaceholder};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: ${({ theme }) => theme.formFieldBorder};
    -webkit-text-fill-color: ${({ theme }) => theme.textPrimary};
    transition: all 0s 50000s;
  }
`;

export const CheckboxContainer = styled.div<{ $disabled?: boolean }>`
  margin-block-end: 16px;

  &:last-of-type {
    margin-block-end: 0;
  }

  label {
    position: relative;
    padding-left: 35px;
    margin: 0;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-transform: initial;
    font-weight: 400;
    color: inherit;

    a {
      text-decoration: underline;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus-visible + .checkmark {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background: none;
    border-radius: 4px;
    border: ${({ theme }) => theme.formFieldBorder};
    opacity: ${({ $disabled }) => ($disabled ? 0.7 : 1)};

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 8px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid rgba(255, 255, 255, 0.7);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`;
export const Checkbox = styled.input``;
