import { useState, useRef, useEffect } from 'react';

const useDropdown = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open) {
      return setShow(true);
    }
    return setShow(false);
  }, [open]);

  useEffect(() => {
    if (open && dropdownRef.current) {
      const closeExternal = (e: MouseEvent) => {
        if (
          !dropdownRef.current?.contains(e.target as Node) &&
          !toggleRef.current?.contains(e.target as Node)
        ) {
          return setOpen(false);
        }
      };
      document.addEventListener('mousedown', closeExternal);
      setOpen(true);
      return () => document.removeEventListener('mousedown', closeExternal);
    }
  }, [open]);

  const toggleOpen = () => setOpen((open) => !open);

  return { open, show, toggleOpen, dropdownRef, toggleRef };
};

export default useDropdown;
