import { StyledTrackTags } from '../Track.styled';

const TrackTags = ({ tags = [], handleTag }) => (
  <StyledTrackTags>
    {tags.slice(0, 3).map((tag) => (
      <span key={tag.id} onClick={(e) => handleTag(e, tag)}>
        {tag.name}
      </span>
    ))}
  </StyledTrackTags>
);

export default TrackTags;
