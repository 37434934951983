import { combineReducers } from 'redux';
import modal from './modalReducer';
import auth from './userReducer';
import music from './musicReducer';
import player from './playerReducer';
import subscription from './subscriptionReducer';
import search from './searchReducer';
import searchTags from './searchTagsReducer';
import searchBar from './searchBarReducer';
import aiSearch from './aiSearchReducer';
import home from './homeReducer';
import playlist from './playlistReducer';
import playlists from './playlistsReducer';
import partner from './partnerReducer';
import artist from './artistReducer';
import album from './albumReducer';
import masterclass from './masterclassReducer';
import masterclasses from './masterclassesReducer';
import favorites from './favoritesReducer';
import purchases from './purchasesReducer';
import projects from './projectsReducer';
import downloads from './downloadsReducer';
import tags from './tagsReducer';
import channel from './channelReducer';
import report from './reportReducer';
import contact from './contactReducer';
import gate from './gateReducer';

const allReducers = combineReducers({
  modal,
  auth,
  music,
  player,
  subscription,
  searchBar,
  searchTags,
  search,
  aiSearch,
  home,
  playlist,
  playlists,
  partner,
  artist,
  album,
  masterclass,
  masterclasses,
  favorites,
  purchases,
  projects,
  downloads,
  tags,
  channel,
  report,
  contact,
  gate,
});

const rootReducer = (state, action) => {
  // console.log(action.type);
  if (action.type === 'CLEAR_STORE') {
    const { tags } = state;
    return allReducers({ tags }, action);
  }
  return allReducers(state, action);
};

export default rootReducer;
