import {
  StyledPlaybackControls,
  StyledPlay,
  StyledRipple,
  StyledControl,
} from './PlaybackControls.styled';
import { ReactComponent as Play } from 'assets/images/icons/play.svg';
import { ReactComponent as Pause } from 'assets/images/icons/pause.svg';
import { ReactComponent as Prev } from 'assets/images/icons/prev.svg';
import { ReactComponent as Next } from 'assets/images/icons/next.svg';
import React from 'react';

interface PlaybackControlsProps {
  border?: boolean;
  loading?: boolean;
  playing?: boolean;
  disable?: boolean;
  mobile?: boolean;
  handlePause?: () => void;
  handlePrev?: () => void;
  handleNext?: () => void;
}

const PlaybackControls: React.FC<PlaybackControlsProps> = ({
  border,
  loading,
  playing,
  disable,
  mobile,
  handlePause,
  handlePrev,
  handleNext,
}) => (
  <StyledPlaybackControls>
    {!mobile && (
      <StyledControl className="prev" $disable={disable} onClick={handlePrev}>
        <Prev />
      </StyledControl>
    )}
    <StyledPlay $border={border} onClick={handlePause}>
      {loading ? (
        <StyledRipple>
          <span></span>
          <span></span>
        </StyledRipple>
      ) : playing ? (
        <Pause />
      ) : (
        <Play />
      )}
    </StyledPlay>
    {!mobile && (
      <StyledControl className="next" $disable={disable} onClick={handleNext}>
        <Next />
      </StyledControl>
    )}
  </StyledPlaybackControls>
);

export default PlaybackControls;
