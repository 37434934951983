import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { device } from 'assets/styles/device';

interface StyledStyledExploreGenreProps {
  $img: string;
}

export const StyledLandingExplore = styled(StyledLandingSection)`
  .explore-genres {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: stretch;
    gap: 1.5rem;
  }

  @media ${device.mobile} {
    .explore-genres {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }
`;

export const StyledExploreGenre = styled.button<StyledStyledExploreGenreProps>`
  aspect-ratio: 1.5;
  margin: 0;
  display: grid;
  grid-template-areas: 'pile';
  align-items: flex-end;
  background: url(${({ $img }) => $img}) no-repeat center center;
  border-radius: 1rem;

  .card-footer {
    grid-area: pile;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    block-size: max(36%, 4.6875rem);
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    padding-inline: 1.625rem;
    font-size: 1.5rem;
    font-weight: 700;
    transition: all 160ms;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;

    .card-button {
      inline-size: 3rem;
      block-size: 3rem;
      border-radius: 3rem;
      border: 2px solid currentColor;
      display: grid;
      place-items: center;
      background: ${({ theme }) =>
        `linear-gradient(
          59deg,
          transparent 48%,
          hsla(0, 0%, 100%, 1) 0,
          hsla(0, 0%, 100%, 1) 3rem,
          hsla(0, 0%, 100%, 1) 52%,
          ${theme.highlightPrimary} 0,
          ${theme.highlightSecondary} 80%,
          ${theme.highlightSecondary}
          )`};
      background-position: 0% 50%;
      background-size: 270%;
      transition: background-position 220ms, border 160ms;
    }
  }

  &:hover {
    img {
      scale: 1;
    }

    .card-footer {
      background-color: rgba(255, 255, 255, 1);
      color: #000;

      .card-button {
        border: none;
        color: #fff;
        background-position: 100% 50%;
      }
    }
  }

  @media ${device.mobile} {
    aspect-ratio: 1.36;
    border-radius: 0.375rem;

    .card-footer {
      block-size: 2.375rem;
      padding-inline: 0.625rem;
      font-size: 1.25rem;

      .card-button {
        inline-size: 1.125rem;
        block-size: 1.125rem;
        border-width: 1px;

        & > svg {
          inline-size: 0.375rem;
        }
      }
    }
  }
`;
