import axios from './config/axiosConfig';

export const getAlbum = async ({ id, limit }) => {
  try {
    const res = await axios.get(`/api/content/albums/${id}`, {
      params: { track_limit: limit },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
