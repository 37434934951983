const defaults = {
  playerHeight: '120px',
  smallCardShadow:
    'circle at 70% 10%, transparent 28%, hsla(0, 0%, 0%, 0.8) 90%, hsla(0, 0%, 0%, 0.9)',
  formFieldBorder: '1px solid rgba(255,255,255,0.2)',
  loadingBackground: 'radial-gradient(ellipse at 80% 0, #111 28%,#090909)',
  contentLoading: '#131313',
  medium: '900px',
  mobile: '576px',
};

export const theme = {
  dark: {
    textHighlight: '#ffffff',
    textPrimary: '#b0b0b0',
    textError: '#be0e0e',
    textPlaceholder: '#ffffff44',
    backgroundPrimary: '#000000',
    highlightPrimary: 'hsla(228, 58%, 54%, 1)',
    highlightSecondary: 'hsla(261, 56%, 43%, 1)',
    ...defaults,
  },
  light: {
    textHighlight: '#000000',
    textPrimary: '#111111',
    textError: '#be0e0e',
    textPlaceholder: '#ffffff44',
    backgroundPrimary: '#ffffff',
    highlightPrimary: 'hsla(228, 58%, 54%, 1)',
    highlightSecondary: 'hsla(261, 56%, 43%, 1)',
    ...defaults,
  },
};
