const initialState = {
  totals: [],
  chart: [],
  tracks: [],
  period: 'month',
  loading: false,
  updating: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REPORTS_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'UPDATE_PERIOD_LOADING':
      return {
        ...state,
        updating: true,
      };

    case 'GET_REPORTS_SUCCESS':
      return {
        ...state,
        totals: action.payload.totals,
        chart: action.payload.per_period,
        tracks: action.payload.top_tracks,
        loading: false,
        error: null,
      };

    case 'UPDATE_PERIOD_SUCCESS':
      return {
        ...state,
        chart: action.payload.data,
        period: action.payload.type,
        updating: false,
        error: null,
      };

    case 'GET_REPORTS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'UPDATE_PERIOD_ERROR':
      return {
        ...state,
        updating: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
