const initialState = {
  loading: { payment: false, toggle: false },
  errors: { payment: null, toggle: null },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUBSCRIPTION_LOADING':
      return {
        ...state,
        loading: { ...state.loading, payment: true },
        errors: initialState.errors,
      };

    case 'SUBSCRIPTION_TOGGLE_LOADING':
      return {
        ...state,
        loading: { ...state.loading, toggle: true },
        errors: initialState.errors,
      };

    case 'SUBSCRIPTION_CREATE_SUCCESS':
    case 'SUBSCRIPTION_UPDATE_SUCCESS':
    case 'SUBSCRIPTION_TOGGLE_SUCCESS':
      return {
        loading: initialState.loading,
        errors: initialState.errors,
      };

    case 'SUBSCRIPTION_ERROR':
    case 'SUBSCRIPTION_TOGGLE_ERROR':
      return {
        loading: initialState.loading,
        errors: { ...state.errors, ...action.errors },
      };

    case 'CLEAR_SUBSCRIPTION_ERROR':
      return {
        ...state,
        errors: initialState.errors,
      };

    default:
      return state;
  }
};

export default reducer;
