import { StyledExploreGenre } from './ExploreSection.styled';
import useSearchPage from 'pages/SearchPage/useSearchPage';
import { ReactComponent as Arrow } from 'assets/images/icons/arrow-short.svg';
import images from 'assets/images/cards/landing-genres';
import React from 'react';

interface ExploreGenreProps {
  tag: {
    name: string;
    slug: string;
  };
}

const ExploreGenre: React.FC<ExploreGenreProps> = ({ tag }) => {
  const { applyTag } = useSearchPage();

  return (
    <StyledExploreGenre
      onClick={() => applyTag(tag, true)}
      $img={images[tag.slug]}
    >
      <div className="card-footer">
        {tag.name}
        <div className="card-button">
          <Arrow />
        </div>
      </div>
    </StyledExploreGenre>
  );
};

export default ExploreGenre;
