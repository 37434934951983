import { useRef, useState } from 'react';

const useSnapCarousel = () => {
  const [disabled, setDisabled] = useState({ prev: true, next: false });
  const cRef = useRef();

  const handleScroll = (next) => {
    const container = cRef.current;

    container.scrollTo({
      left: container.scrollLeft + container.offsetWidth * (next ? 1 : -1),
      behavior: 'smooth',
    });
  };

  const handleCheck = (e) => {
    const elem = e.target;

    setDisabled({
      prev: elem.scrollLeft === 0,
      next: elem.scrollWidth - 5 <= elem.scrollLeft + elem.clientWidth,
    });
  };

  return {
    cRef,
    disabled,
    handleScroll,
    handleCheck,
  };
};

export default useSnapCarousel;
