import React from 'react';
import { StyledBasicModalDialog } from './BasicModal.styled';
import useBasicModal from './BasicModal.hooks';

interface BasicModalProps {
  open: boolean;
  children: React.ReactNode;
  handleClose: () => void;
  shouldAnimate?: boolean;
}

const BasicModal: React.FC<BasicModalProps> = ({
  open,
  handleClose,
  children,
  shouldAnimate = true,
}) => {
  const { dialogRef, dialogContentRef, show } = useBasicModal({
    open,
    handleClose,
    shouldAnimate,
  });

  return (
    <StyledBasicModalDialog
      className={show ? 'show' : undefined}
      ref={dialogRef}
    >
      <div ref={dialogContentRef}>{children}</div>
    </StyledBasicModalDialog>
  );
};

export default BasicModal;
