import { CheckboxContainer } from 'components/Form/Form.styled';
import { StyledInfo } from 'components/InfoTooltip/InfoTooltip.styled';
import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledDrawer = styled.div`
  overflow: auto;
  max-height: calc(
    100vh - var(--header-height) - var(--search-height) - 12.5rem
  );
  ${({ $open }) => `
    width: ${$open ? '12.5rem' : '0'};
    border-left: ${$open && '1px solid #333'};
    padding-left: ${$open ? '1.5rem' : '0'};
    margin-right: ${$open ? '1rem' : '0'};
  `}

  &::-webkit-scrollbar {
    width: 0.5rem;

    &-thumb {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 1.25rem;
    }
  }

  li {
    cursor: default;
    margin-bottom: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover .controls button svg {
      opacity: 0.7;
    }

    .tag-name {
      cursor: pointer;
      flex: 1;
      padding: 0.625rem 0;
      font-size: 0.75rem;

      &.active {
        color: ${({ theme }) => theme.textHighlight};
      }
      &.inactive {
        opacity: 0.5;
      }
    }
    .controls {
      &.active {
        button {
          svg {
            opacity: 0.5;

            &.included,
            &.excluded {
              opacity: 1;
            }
          }
        }
      }
      button {
        margin: 0 0.125rem 0 0;

        svg {
          padding: 0.1875rem;
          border-radius: 50%;
          opacity: 0;

          &:hover {
            opacity: 1;
          }

          &.included {
            color: rgba(0, 255, 0);
          }
          &.excluded {
            color: rgba(255, 0, 0);
          }
        }
      }
    }
  }
  @media ${device.mobile} {
    max-height: calc(100vh - 8rem);
    width: ${({ $open }) => ($open ? `calc(100% - 8.75rem)` : 0)};
    margin: 0;
    padding: 0 4%;

    li .controls button {
      padding: 0.375rem;
      svg {
        opacity: 0.5;
      }
    }
  }
`;

export const StyledFilters = styled.div`
  --filters-width: 10.75rem;
  font-size: 0.875rem;

  .filters-wrapper {
    display: flex;
    position: sticky;
    inset-block-start: 0;
    padding-block-start: 1.5rem;
    z-index: 2;

    .close {
      position: absolute;
      box-sizing: initial;
      display: none;
      cursor: pointer;
      background: #0e0e0e;
      border-radius: 7rem;
      padding: 0.875rem;
      inset-block-start: ${({ $open }) => ($open ? '-1.25rem' : 0)};
      inset-inline-end: ${({ $open }) => ($open ? 0 : '-1.75rem')};
      transition: all 0.2s;
    }
    ul.filters {
      width: var(--filters-width);
      position: relative;

      & > li {
        margin-bottom: 1rem;
        padding: 0.7rem 0;
        cursor: pointer;

        &.active {
          color: ${({ theme }) => theme.textHighlight};
        }
        &.usage {
          margin-block-end: 0.2rem;
        }
        .badge {
          font-size: 0.6rem;
          width: 0.875rem;
          height: 0.875rem;
          line-height: 0.875rem;
          text-align: center;
          display: inline-block;
          border-radius: 50%;
          margin-left: 0.375rem;
          color: #fff;
          background: #333;
          position: absolute;
        }
      }
      .collapse {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(90deg);
        padding: 0.25rem;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        background: #333;
        cursor: pointer;
      }
    }
  }

  ${CheckboxContainer} {
    margin-block: 0.75rem;
    padding-inline-start: 0.5rem;
    font-size: 0.75rem;

    label {
      font-size: inherit;
      padding-inline-start: 1.8rem;
      display: flex;
      align-items: center;
    }

    .checkmark {
      width: 1.2em;
      height: 1.2em;
      color: #4c4c4c;
      background-color: currentColor;
      border-color: currentColor;
      border-width: 0.1em;
      box-sizing: initial;
      inset: 0;
      margin-block: auto;

      &::after {
        inset: 0;
        margin: auto;
        border-width: 0 0.225em 0.225em 0;
        border-color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  ${StyledInfo} {
    margin-inline-start: 0.5rem;
    color: #555;
  }

  @media ${device.mobile} {
    --filters-width: 8.75rem;
    position: fixed;
    background: #0e0e0e;
    z-index: 99999;
    padding-top: 1.5rem;
    block-size: 100vh;
    block-size: 100dvh;
    inline-size: 100%;
    inset-inline-start: 0;
    inset-block-end: ${({ $show }) => ($show ? 0 : '-120vh')};
    transition: bottom 160ms;

    .filters-wrapper {
      padding-top: 2rem !important;
      position: relative;
      top: 0;

      ul.filters {
        padding: 0 1.5rem;
      }
      .collapse {
        display: none;
      }
      .close {
        display: block;
        inset-block-start: -1.25rem;
        inset-inline-end: 0;
      }
    }
  }
`;
