import image134 from './134-1000x600.jpg';
import image145 from './145-1000x600.jpg';
import image214 from './214-1000x600.jpg';
import image227 from './227-1000x600.jpg';
import image240 from './240-1000x600.jpg';
import image435 from './435-1000x600.jpg';
import image1072 from './1072-1000x600.jpg';
import image1082 from './1082-1000x600.jpg';

import image134_400 from './400x400/134-400x400.jpg';
import image145_400 from './400x400/145-400x400.jpg';
import image214_400 from './400x400/214-400x400.jpg';
import image227_400 from './400x400/227-400x400.jpg';
import image240_400 from './400x400/240-400x400.jpg';
import image435_400 from './400x400/435-400x400.jpg';
import image1072_400 from './400x400/1072-400x400.jpg';
import image1082_400 from './400x400/1082-400x400.jpg';

export const imagePaths = [
  image134,
  image145,
  image214,
  image227,
  image240,
  image435,
  image1072,
  image1082,
];

export const imagePaths400 = [
  image134_400,
  image145_400,
  image214_400,
  image227_400,
  image240_400,
  image435_400,
  image1072_400,
  image1082_400,
];
