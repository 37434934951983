import { getPermissionFromUser } from 'utils';

const initialState = {
  user: null,
  permission: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_SIGN_IN_LOADING':
    case 'USER_SIGN_UP_LOADING':
    case 'USER_VERIFY_LOADING':
    case 'USER_UPDATE_LOADING':
    case 'USER_FORGOT_LOADING':
    case 'USER_RESET_LOADING':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'USER_SIGN_IN_SUCCESS':
    case 'USER_SIGN_UP_SUCCESS':
    case 'USER_VERIFY_SUCCESS':
    case 'USER_SIGN_OUT_SUCCESS':
    case 'USER_FORGOT_SUCCESS':
    case 'USER_RESET_SUCCESS':
      return {
        ...state,
        user: action.payload,
        permission: getPermissionFromUser(action.payload),
        loading: false,
        error: null,
      };

    case 'USER_UPDATE_SUCCESS':
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        permission: getPermissionFromUser(action.payload),
        loading: false,
        error: null,
      };

    case 'USER_SIGN_IN_ERROR':
    case 'USER_SIGN_UP_ERROR':
    case 'USER_VERIFY_ERROR':
    case 'USER_UPDATE_ERROR':
    case 'USER_FORGOT_ERROR':
    case 'USER_RESET_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
