import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { device } from 'assets/styles/device';
import image from 'assets/images/backgrounds/hero-image-min.webp';

export const StyledLandingHero = styled(StyledLandingSection)`
  margin-block-start: 0;
  isolation: isolate;
  position: relative;

  color: ${({ theme }) => theme.textHighlight};

  &::before {
    z-index: -1;
    position: absolute;
    content: '';
    inset: 0;
    background: linear-gradient(
        90deg,
        ${({ theme }) => theme.backgroundPrimary}EE 11.99%,
        ${({ theme }) => theme.backgroundPrimary}CA 43.72%,
        ${({ theme }) => theme.backgroundPrimary}19 73.37%
      ),
      linear-gradient(
        0deg,
        #030306 4.91%,
        ${({ theme }) => theme.backgroundPrimary}BD 24.93%,
        transparent 47.61%
      ),
      linear-gradient(
        0deg,
        ${({ theme }) => theme.backgroundPrimary}4C,
        ${({ theme }) => theme.backgroundPrimary}4C
      ),
      url(${image});
    background-size: cover;
    background-position: 50% 0%;
  }

  h1 {
    font-size: 2.5rem;
    max-inline-size: 26ch;
    margin-block: 0.75rem;
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-block-size: calc(100vh - var(--header-height) - var(--banner-height));
  }

  .used-by {
    text-align: center;
    padding-block-end: 1.25rem;

    .brands {
      inline-size: 100%;
      block-size: 3.875rem;
      margin-block-start: 2.5rem;
      opacity: 0.3;
    }
  }

  @media ${device.mobile} {
    min-block-size: auto;
    padding-block-start: 46%;

    &::before {
      inset-block-start: 0;
      content: '';
      inline-size: 100%;
      aspect-ratio: 1.5;
      background: linear-gradient(
          0deg,
          #030306 4.91%,
          rgba(2, 2, 3, 0.74) 41.11%,
          rgba(0, 0, 0, 0) 61.6%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url(${image});
      background-size: 150%;
      background-position: 50% 0;
    }

    .hero-content {
      align-items: center;
      min-block-size: initial;
    }

    h1 {
      font-size: 1.75rem;
      text-align: center;
      margin-block: 1.75rem;
    }

    .used-by {
      text-align: center;

      .brands {
        block-size: 1.5rem;
        margin-block: 1rem 0.8rem;
        opacity: 0.3;
      }
    }
  }
`;
