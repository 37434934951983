import React, { useEffect } from 'react';

interface IUseBasicModal {
  open: boolean;
  handleClose: () => void;
  shouldAnimate: boolean;
}

const useBasicModal = ({
  open,
  handleClose,
  shouldAnimate,
}: IUseBasicModal) => {
  const dialogRef = React.useRef<HTMLDialogElement>(null);
  const dialogContentRef = React.useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(!shouldAnimate);

  useEffect(() => {
    const closeExternal = (e: MouseEvent | TouchEvent) => {
      const excludeContainers =
        dialogContentRef.current?.contains(e.target as Node) ||
        document.getElementById('player')?.contains(e.target as Node);

      if (!excludeContainers) handleClose();
    };
    if (dialogContentRef.current && open) {
      document.addEventListener('mousedown', closeExternal);
      document.addEventListener('touchstart', closeExternal);
    }
    return () => {
      document.removeEventListener('mousedown', closeExternal);
      document.removeEventListener('touchstart', closeExternal);
    };
  }, [open, handleClose]);

  useEffect(() => {
    const toggleOpen = () => {
      if (!dialogRef.current) return;

      if (!open) {
        if (!shouldAnimate) {
          return dialogRef.current?.close();
        }
        setShow(false);

        setTimeout(() => {
          dialogRef.current?.close();
        }, 160);
        return;
      }
      dialogRef.current.showModal();
      if (shouldAnimate) setShow(true);
    };
    toggleOpen();
  }, [open, shouldAnimate]);

  return { dialogRef, dialogContentRef, show };
};

export default useBasicModal;
