import * as partnerApi from 'services/api/partnerApi';
import * as trackApi from 'services/api/trackApi';
import * as artistApi from 'services/api/artistApi';

const types = {
  GET_MUSIC_PLAYLISTS_LOADING: 'GET_MUSIC_PLAYLISTS_LOADING',
  GET_MUSIC_PLAYLISTS_SUCCESS: 'GET_MUSIC_PLAYLISTS_SUCCESS',
  GET_MUSIC_PLAYLISTS_ERROR: 'GET_MUSIC_PLAYLISTS_ERROR',
  GET_MUSIC_TRACKS_LOADING: 'GET_MUSIC_TRACKS_LOADING',
  GET_MUSIC_TRACKS_SUCCESS: 'GET_MUSIC_TRACKS_SUCCESS',
  GET_MUSIC_TRACKS_ERROR: 'GET_MUSIC_TRACKS_ERROR',
  GET_MORE_MUSIC_TRACKS_LOADING: 'GET_MORE_MUSIC_TRACKS_LOADING',
  GET_MORE_MUSIC_TRACKS_SUCCESS: 'GET_MORE_MUSIC_TRACKS_SUCCESS',
  GET_MORE_MUSIC_TRACKS_ERROR: 'GET_MORE_MUSIC_TRACKS_ERROR',
  UPDATE_MUSIC_TRACKS_LOADING: 'UPDATE_MUSIC_TRACKS_LOADING',
  UPDATE_MUSIC_TRACKS_SUCCESS: 'UPDATE_MUSIC_TRACKS_SUCCESS',
  UPDATE_MUSIC_TRACKS_ERROR: 'UPDATE_MUSIC_TRACKS_ERROR',
  CLEAR_MUSIC_TRACKS: 'CLEAR_MUSIC_TRACKS',
  CHANGE_MUSIC_SORT_ORDER: 'CHANGE_MUSIC_SORT_ORDER',
};

export const getMusicPlaylists = () => {
  return (dispatch) => {
    dispatch({ type: types.GET_MUSIC_PLAYLISTS_LOADING });

    const content = [
      partnerApi
        .getPartners({ limit: 1, partner_type: 'creator' })
        .then(({ results }) => [{ ...results[0], type: 'creator' }]),
      artistApi
        .getArtists({ limit: 3 })
        .then(({ results }) =>
          results.map((result) => ({ ...result, type: 'artist' }))
        ),
    ];
    return Promise.all(content)
      .then((results) => {
        dispatch({
          type: types.GET_MUSIC_PLAYLISTS_SUCCESS,
          payload: results.flat(),
        });
      })
      .catch((message) => {
        dispatch({ type: types.GET_MUSIC_PLAYLISTS_ERROR, error: message });
        return message;
      });
  };
};

export const getMusicTracks = () => {
  return (dispatch, getState) => {
    const { limit, ordering, loading } = getState().music;

    if (loading.tracks) return;

    dispatch({ type: types.GET_MUSIC_TRACKS_LOADING });
    return trackApi
      .getTracks({ limit, ordering })
      .then((results) => {
        dispatch({
          type: types.GET_MUSIC_TRACKS_SUCCESS,
          payload: results,
        });
      })
      .catch((message) => {
        dispatch({ type: types.GET_MUSIC_TRACKS_ERROR, error: message });
        return message;
      });
  };
};

export const getMoreMusicTracks = () => {
  return (dispatch, getState) => {
    const { limit, nextPage, ordering } = getState().music;

    dispatch({ type: types.GET_MORE_MUSIC_TRACKS_LOADING });
    return trackApi
      .getTracks({ limit, nextPage, ordering })
      .then((results) => {
        dispatch({
          type: types.GET_MORE_MUSIC_TRACKS_SUCCESS,
          payload: results,
        });
      })
      .catch((message) => {
        dispatch({ type: types.GET_MORE_MUSIC_TRACKS_ERROR, error: message });
        return message;
      });
  };
};

export const clearMusicTracks = () => (dispatch) => {
  dispatch({ type: types.CLEAR_MUSIC_TRACKS });
};

export const changeOrder = (payload) => (dispatch) => {
  dispatch({ type: types.CHANGE_MUSIC_SORT_ORDER, payload });
  dispatch(getMusicTracks());
};
