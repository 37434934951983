import React, { useEffect, useRef } from 'react';
import { Button } from '..';
import { StyledMoreButton } from './MoreButton.styled';

interface MoreButtonProps {
  loading?: boolean;
  handleClick?: () => void;
}

const MoreButton: React.FC<MoreButtonProps> = ({ loading, handleClick }) => {
  const intersectRef = useRef(null);

  useEffect(() => {
    if (!handleClick) return;

    const offset = intersectRef.current;

    const cbFunc: IntersectionObserverCallback = ([
      entry,
    ]: IntersectionObserverEntry[]) => {
      if (entry?.isIntersecting && !loading) handleClick();
    };
    const observer = new IntersectionObserver(cbFunc);
    if (offset) observer.observe(offset);
    return () => {
      if (offset) observer.unobserve(offset);
    };
  }, [handleClick, loading]);

  if (!handleClick) return null;

  return (
    <StyledMoreButton>
      <div className="offset-intersect" ref={intersectRef}></div>
      <Button
        className="more"
        $outline
        $full={true}
        disabled={loading}
        onClick={handleClick}
      >
        {loading ? 'Loading...' : 'More'}
      </Button>
    </StyledMoreButton>
  );
};

export default MoreButton;
