import Form, { InputField, SubmitButton } from 'components/Form';
import useForgotForm from './useForgotForm';

const ForgotForm = ({ handleClose, handleForgot }) => {
  const { formik, loading } = useForgotForm({ handleClose });

  return (
    <Form handleSubmit={formik.handleSubmit}>
      <InputField label="Email" name="email" type="email" formik={formik} />
      <SubmitButton loading={loading}>Continue</SubmitButton>
      <p className="alternate">
        <span className="link" onClick={handleForgot}>
          Cancel
        </span>
      </p>
    </Form>
  );
};

export default ForgotForm;
