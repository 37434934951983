import axios from './config/axiosConfig';

export const getChannels = async () => {
  try {
    const res = await axios.get(`/api/accounts/youtubechannels`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addChannel = async (payload) => {
  try {
    await axios.post(`/api/accounts/youtubechannels`, payload);
    return payload;
  } catch (error) {
    throw error;
  }
};

export const removeChannel = async (payload) => {
  try {
    await axios.delete(`/api/accounts/youtubechannels/${payload}`);
    return payload;
  } catch (error) {
    throw error;
  }
};
