import { StyledSegment } from './Waveform.styled';
import { Tooltip } from 'react-tooltip';

const SvgWaveOverlay = ({
  id,
  tooltip,
  handleSeek,
  handlePos,
  segment,
  large,
}) => {
  return (
    <>
      {segment ? (
        <StyledSegment {...segment}>
          {large ? <span>Most Representative Segment</span> : null}
        </StyledSegment>
      ) : null}
      <span
        data-tooltip-id={id}
        className="overlay"
        onClick={handleSeek}
        onMouseMove={handlePos}
      ></span>
      <Tooltip id={id} className="waveform-tooltip">
        {tooltip}
      </Tooltip>
    </>
  );
};

export default SvgWaveOverlay;
