import { StyledModalClose } from './Modal.styled';
import { ReactComponent as Plus } from 'assets/images/icons/plus.svg';

const ModalClose = ({ color, handleClick }) => {
  return (
    <StyledModalClose $color={color} onClick={handleClick}>
      <Plus />
    </StyledModalClose>
  );
};

export default ModalClose;
