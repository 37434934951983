import axios from './config/axiosConfig';

export const getFavorites = async () => {
  try {
    const res = await axios.get(`/api/accounts/favorites`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getFavoriteTracks = async () => {
  try {
    const res = await axios.get(`/api/accounts/favorites/tracks`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addFavorite = async (id) => {
  try {
    await axios.post(`/api/accounts/favorites`, { track_id: id });
    return id;
  } catch (error) {
    throw error;
  }
};

export const removeFavorite = async (id) => {
  try {
    await axios.delete(`/api/accounts/favorites/${id}`);
    return id;
  } catch (error) {
    throw error;
  }
};
