import { StyledLandingTestimonials } from './TestimonialsSection.styled';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import Testimonial from './Testimonial';
import SnapCarousel from 'components/SnapCarousel/SnapCarousel';
import testimonials from 'constants/testimonials';
import { StyledBackgroundEllipse } from 'pages/LandingPage/LandingPage.styled';
import { mobile } from 'hooks/viewport.signal';

const TestimonialsSection = () => (
  <StyledLandingTestimonials>
    <StyledBackgroundEllipse
      $size="33.125rem"
      $color="#4661CE"
      $bottom="20%"
      $right="8%"
      style={{ opacity: 0.8 }}
    />
    <StyledBackgroundEllipse
      $size="33.125rem"
      $color="#4661CE"
      $bottom="10%"
      $left="8%"
      style={{ opacity: 0.8 }}
    />
    <div className="section-header">
      <h2>See What Creators Are Saying</h2>
      <AppSumoRating best />
    </div>
    <div className="section-content">
      <SnapCarousel cells={mobile.value ? '1' : '3'}>
        {testimonials.map((testimonial, i) => (
          <Testimonial key={i} {...testimonial} />
        ))}
      </SnapCarousel>
    </div>
  </StyledLandingTestimonials>
);

export default TestimonialsSection;
