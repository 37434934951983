const initialState = {
  downloads: [],
  active: null,
  downloading: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DOWNLOADS_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'DOWNLOAD_TRACK_LOADING':
      return {
        ...state,
        downloading: action.payload,
      };

    case 'GET_DOWNLOADS_SUCCESS':
      return {
        ...state,
        downloads: action.payload,
        loading: false,
        error: null,
      };

    case 'DOWNLOAD_TRACK_SUCCESS':
      const exists = state.downloads.some((dl) => dl.id === action.payload.id);
      const downloads = exists
        ? state.downloads
        : [...state.downloads, action.payload];

      return {
        ...state,
        active: null,
        downloads: downloads,
        downloading: null,
        loading: false,
        error: null,
      };

    case 'SET_ACTIVE_TRACK':
      return {
        ...state,
        active: action.payload,
      };

    case 'GET_DOWNLOADS_ERROR':
    case 'DOWNLOAD_TRACK_ERROR':
      return {
        ...state,
        loading: false,
        downloading: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
