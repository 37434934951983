import { StyledModalContent } from 'components/Modal/Modal.styled';
import ChannelForm from './ChannelForm';
import { FC } from 'react';

interface ChannelModalProps {
  handleClose: () => void;
}

const ChannelModal: FC<ChannelModalProps> = ({ handleClose }) => (
  <StyledModalContent>
    <div className="modal-header">
      <h2>YouTube Channel</h2>
      <p style={{ textAlign: 'start' }}>
        If you plan on using the tracks on YouTube, you'll need to provide some
        details so that we can whitelist your channel. If you don't have it
        handy, don't worry, you can{' '}
        <span className="link" onClick={handleClose}>
          skip this step
        </span>{' '}
        and add it later.
      </p>
    </div>
    <ChannelForm handleClose={handleClose} />
  </StyledModalContent>
);

export default ChannelModal;
