import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'services/state/actions/modalActions';
import { userSignOut } from 'services/state/actions/userActions';
import { getSubscriptionByType } from 'constants/subscriptions';
import { RootState, useAppDispatch } from 'services/state/store/configureStore';
import { mobile } from 'hooks/viewport.signal';

const useUserMenu = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const subscription = getSubscriptionByType(user?.subscription);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    handleSignOut();
  };

  const handleSignIn = () => {
    dispatch(openModal('signin'));
  };

  const handleSignOut = () => {
    navigate('/');
    dispatch(userSignOut());
  };

  return {
    user,
    mobile,
    subscription,
    handleClick,
    handleSignIn,
  };
};

export default useUserMenu;
