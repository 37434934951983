const initialState = {
  cart: false,
  login: false,
  signup: false,
  mixer: false,
  masterclass: false,
  open: false,
  values: null,
  secondary: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...initialState,
        [action.payload.kind]: true,
        values: action.payload.values,
        open: true,
      };

    case 'CLOSE_MODAL':
      return initialState;

    case 'OPEN_SECOND_MODAL':
      return {
        ...state,
        secondary: action.payload.kind,
        secondaryValues: action.payload.values,
      };

    case 'CLOSE_SECOND_MODAL':
      return {
        ...state,
        secondary: null,
      };

    default:
      return state;
  }
};

export default reducer;
