import rootReducer from 'services/state/reducers/rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';
import { useDispatch } from 'react-redux';
import { session } from './middleware';

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: { warnAfter: 128 } }).concat(
      session
    ),
});

store.subscribe(() => {
  // Add in any store state to persist, like cart, favorites, etc
  const { auth, tags, channel } = store.getState();
  if (!auth.user) {
    return saveState({ tags });
  }
  // Add in any store state to persist, like cart, favorites, etc
  saveState({ auth, tags, channel });
});

export default store;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

//export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<any>;
export type AppDispatch = typeof store.dispatch;
