import { StyledLandingAI } from './AISection.styled';
import SoundsSimilar from './Tabs/SoundsSimilar';
import SFXTools from './Tabs/SFXTools';
import AISearch from './Tabs/AISearch';
import StemCreation from './Tabs/StemCreation';
import SectionTabs from 'components/SectionTabs/SectionTabs';
import { ReactComponent as SimilarSoundIcon } from 'assets/images/pricing-options/similar.svg';
import { ReactComponent as AISearchIcon } from 'assets/images/pricing-options/conversational.svg';
import { ReactComponent as StemCreationIcon } from 'assets/images/pricing-options/stems.svg';
import { ReactComponent as SFXToolsIcon } from 'assets/images/pricing-options/sfx.svg';
import { ReactComponent as OpenSearchIcon } from 'assets/images/pricing-options/sfx.svg';
import { ReactComponent as KeywordSearchIcon } from 'assets/images/pricing-options/sfx.svg';
import OpenSearch from './Tabs/OpenSearch';
import KeywordSearch from './Tabs/KeywordSearch';
import { StyledBackgroundEllipse } from 'pages/LandingPage/LandingPage.styled';

const AISection = () => {
  const sections = [
    {
      title: 'Conversation Search',
      icon: <AISearchIcon />,
      component: <AISearch />,
    },
    {
      title: 'Sounds Similar',
      icon: <SimilarSoundIcon />,
      component: <SoundsSimilar />,
    },
    {
      title: 'Stem Creation',
      icon: <StemCreationIcon />,
      component: <StemCreation />,
    },
    {
      title: 'Open Search',
      icon: <OpenSearchIcon />,
      component: <OpenSearch />,
    },
    {
      title: 'Keyword Tags',
      icon: <KeywordSearchIcon />,
      component: <KeywordSearch />,
    },
    {
      title: 'SFX Tools',
      icon: <SFXToolsIcon />,
      component: <SFXTools />,
    },
  ];

  return (
    <StyledLandingAI>
      <StyledBackgroundEllipse
        $size="34.375rem"
        $color="#5937B0"
        $top="3%"
        $right="16%"
        $blur="24.125rem"
      />
      <StyledBackgroundEllipse
        $size="21.875rem"
        $color="#4661CE"
        $bottom="20%"
        $left="15%"
      />
      <div className="section-header">
        <h2>Persona AI Saves You Time & Hassle</h2>
        <h4>
          {/* Re-enable the last background item in LandingBackground */}
          Our Integrated AI Tools speed up the process of sculpting stunning
          soundscapes
        </h4>
      </div>
      <SectionTabs sections={sections} />
    </StyledLandingAI>
  );
};

export default AISection;
