import { StyledLandingUsedIn } from './UsedInSection.styled';
import InfiniteCarousel from 'components/InfiniteCarousel/InfiniteCarousel';
import scrollCards from 'assets/images/cards/scroll-cards';
import { mobile } from 'hooks/viewport.signal';

const UsedInSection = () => (
  <StyledLandingUsedIn>
    <div className="section-header">
      <h2>Sound Familiar? Here's Where You Heard It</h2>
      <h4>Meet the movies, shows, trailers, and YouTubers using our music</h4>
    </div>
    <div className="cards-row">
      <InfiniteCarousel
        $duration="250s"
        items={scrollCards[0]}
        $gap={mobile.value ? '0.5rem' : '1rem'}
      />
    </div>
    <div className="cards-row">
      <InfiniteCarousel
        $duration="250s"
        items={scrollCards[1]}
        $gap={mobile.value ? '0.5rem' : '1rem'}
        $reverse={1}
      />
    </div>
  </StyledLandingUsedIn>
);

export default UsedInSection;
