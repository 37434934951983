import { useState, cloneElement, useLayoutEffect } from 'react';
import {
  StyledSearchLoadingGroup,
  StyledSearchResultGroup,
} from './SearchResults.styled';

const SearchResultGroup = ({ title, count, loading, children }) => {
  const [limit, setLimit] = useState(count > 4);
  const childrenLimited = limit ? children.slice(0, 4) : children;
  const childrenWithLoading = childrenLimited.map((child) =>
    cloneElement(child, { loading })
  );

  useLayoutEffect(() => {
    if (!loading) setLimit(count > 4);
  }, [loading, count]);

  return !count ? null : loading ? (
    <StyledSearchLoadingGroup>
      <h5 className="content-loading">&nbsp;</h5>
      {childrenWithLoading}
      {(limit || count > 4) && (
        <p className="view-all">
          <span className="content-loading">&nbsp;</span>
        </p>
      )}
    </StyledSearchLoadingGroup>
  ) : (
    <StyledSearchResultGroup>
      <h5>
        {title} {limit && <span>({count})</span>}
      </h5>
      {childrenWithLoading}
      {limit ? (
        <p className="view-all" onClick={() => setLimit(false)}>
          View All {title}
        </p>
      ) : (
        count > 4 && (
          <p className="view-all" onClick={() => setLimit(count > 4)}>
            View Less ^
          </p>
        )
      )}
    </StyledSearchResultGroup>
  );
};

export default SearchResultGroup;
