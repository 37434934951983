import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setQueueAndPlay } from 'services/state/actions/playerActions';
import { preloadContent } from 'services/state/actions/contentActions';
import { StyledSearchResults } from './SearchResults.styled';
import SearchResultsList from './SearchResultsList';
import SearchResultsLoading from './SearchResultsLoading';
import { useAudio } from 'hooks';
import { getTrackById } from 'services/api/trackApi';
import useSearchPage from 'pages/SearchPage/useSearchPage';

const SearchResults = ({ results, term, tags, loading, handleClose }) => {
  const { applyTag } = useSearchPage();
  const { controls } = useAudio();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTrackPlay = (track) => {
    getTrackById(track.id).then((dlTrack) => {
      controls.load(dlTrack.asset_src);
      dispatch(setQueueAndPlay({ list: [dlTrack], index: 0 }));
    });
  };

  const handleNav = (type, item) => {
    dispatch(preloadContent({ type, item }));
    navigate(`/${type}/${item.id}`);
    handleClose();
  };

  const handleSearch = () => {
    navigate(`/search`);
  };

  const handleToggleTag = (tag) => {
    applyTag(tag, true);
    handleClose();
  };

  return (
    <StyledSearchResults>
      <div className="content-wrapper">
        <div className="content">
          {loading && !results ? (
            <SearchResultsLoading />
          ) : (
            results && (
              <SearchResultsList
                loading={loading}
                results={results}
                term={term}
                activeTags={tags}
                handleTrackPlay={handleTrackPlay}
                handleNav={handleNav}
                handleSearch={handleSearch}
                handleToggleTag={handleToggleTag}
              />
            )
          )}
        </div>
      </div>
      <div className="underlay-arriba" onClick={handleClose}></div>
    </StyledSearchResults>
  );
};

export default SearchResults;
