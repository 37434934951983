import { CheckboxContainer } from 'components/Form/Form.styled';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import { usageTooltips } from 'constants/tooltips';

const UsageTags = ({ tags, handleToggleTag }) => (
  <div className="checkboxes-wrapper">
    {tags.map((tag) => (
      <CheckboxContainer key={tag.id} disabled={tag.disabled}>
        <label>
          {tag.name}
          <input
            type="checkbox"
            id={tag.name}
            name={tag.name}
            checked={tag.checked}
            onChange={() => handleToggleTag(tag, true)}
            disabled={tag.disabled}
          />
          <span className="checkmark"></span>
          <InfoTooltip id={tag.name} tooltip={usageTooltips[tag.name]} />
        </label>
      </CheckboxContainer>
    ))}
  </div>
);

export default UsageTags;
