import styled from 'styled-components';

export const StyledPasswordRules = styled.div`
  font-size: 0.825rem;
  text-align: start;

  .rules-header {
    margin-block: 0.7rem 0.3rem;
  }

  ul {
    color: #555;
    padding-left: 8px;

    li {
      font-weight: 600;

      &.active {
        color: hsl(116deg 42% 41%);
      }
    }
  }
`;
