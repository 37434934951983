import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { device } from 'assets/styles/device';

export const StyledLandingSubscribe = styled(StyledLandingSection)`
  text-align: center;
`;

export const StyledSubscribeForm = styled.form`
  display: grid;
  grid-template-columns: 1fr min(11.5rem);
  gap: 1.5rem 1rem;
  inline-size: min(46.875rem, 100%);
  margin: auto;
  font-size: 0.875rem;

  input {
    block-size: 3.5rem;
    border-radius: 3.5rem;
    padding: 1.5rem;
    border: 1px solid rgba(51, 113, 197, 0.12);
    background-color: #101116;
    color: #fff;
    font-size: inherit;
  }

  button {
    font-size: 1.125rem;
    font-weight: 800;
    border-radius: 3.5rem;
    border: 1.5px solid currentColor;
    block-size: 3.5rem;
    inline-size: min(11.5rem, 100%);
    flex-shrink: 0;
    grid-area: 1/2;

    &:disabled {
      opacity: 0.5;
    }

    & > svg {
      margin: 0;
      inline-size: 1rem;
      margin-inline-start: 0.5rem;
    }
  }

  .confirmation {
    grid-column: 1/3;
  }

  .field-error {
    color: ${({ theme }) => theme.textError};
    grid-column: 1/3;
  }

  .info-safe {
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    grid-column: 1/3;
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    button {
      margin: 0.75rem auto 0;
    }

    .info-safe {
      grid-column: auto;
    }
  }
`;
