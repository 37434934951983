import { StyledButton } from './Button.styled';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $primary?: boolean;
  $outline?: boolean;
  $white?: boolean;
  $clear?: boolean;
  $danger?: boolean;
  $full?: boolean;
  $large?: boolean;
  $width?: string;
  $height?: string;
  children: React.ReactNode;
  loading?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ children, loading, ...props }) => (
  <StyledButton {...props}>{loading ? 'Loading...' : children}</StyledButton>
);

export default Button;
