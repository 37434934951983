import { ReactComponent as Check } from 'assets/images/icons/check.svg';
import { ReactComponent as Clear } from 'assets/images/icons/clear.svg';
import {
  StyledSearchResultPill,
  StyledSearchLoadingPill,
} from './SearchResults.styled';

const SearchResultPill = ({ title, active, loading, handleClick }) =>
  loading ? (
    <StyledSearchLoadingPill>
      <div className="details">
        <div className="title content-loading">&nbsp;</div>
      </div>
    </StyledSearchLoadingPill>
  ) : (
    <StyledSearchResultPill onClick={!loading ? handleClick : () => false}>
      <div className="details">
        {active && <Check className="active" />}
        <div className="title">{title}</div>
        {active && <Clear className="clear" />}
      </div>
    </StyledSearchResultPill>
  );
export default SearchResultPill;
