import useSubscribeForm from './SubscribeForm.hooks';
import { ReactComponent as Lock } from 'assets/images/icons/lock.svg';
import { StyledSubscribeForm } from './SubscribeSection.styled';
import { ReactComponent as Arrow } from 'assets/images/icons/arrow.svg';

const SubscribeForm = () => {
  const { formik, confirmation, loading, error } = useSubscribeForm();

  return (
    <StyledSubscribeForm onSubmit={formik.handleSubmit}>
      <input
        name="email"
        type="email"
        placeholder="Enter your email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {(formik.errors.email && formik.touched.email) || error ? (
        <div className="field-error">{formik.errors.email || error}</div>
      ) : null}
      {confirmation ? <h3 className="confirmation">{confirmation}</h3> : null}
      <button type="submit" disabled={loading}>
        {loading ? (
          'Saving...'
        ) : (
          <>
            Subscribe <Arrow />
          </>
        )}
      </button>
      <div className="info-safe">
        <Lock /> Your information is safe with us.
      </div>
    </StyledSubscribeForm>
  );
};

export default SubscribeForm;
