import { ReactComponent as Play } from 'assets/images/icons/play.svg';
import {
  StyledSearchLoadingItem,
  StyledSearchResultItem,
} from './SearchResults.styled';

const SearchResultItem = ({
  image,
  round,
  cinema,
  title,
  text,
  loading,
  handlePlay,
  handleClick,
}) =>
  loading ? (
    <StyledSearchLoadingItem>
      {image && <div className="thumbnail content-loading"></div>}
      <div className="details">
        <div className="title content-loading"></div>
        {text && <div className="text content-loading"></div>}
      </div>
    </StyledSearchLoadingItem>
  ) : (
    <StyledSearchResultItem $image={image} $round={round} $cinema={cinema}>
      {image && (
        <div className="thumbnail" onClick={handlePlay || handleClick}>
          {handlePlay && <Play />}
        </div>
      )}
      <div className="details" onClick={handleClick}>
        <div className="title">{title}</div>
        {text && <div className="text">{text}</div>}
      </div>
    </StyledSearchResultItem>
  );

export default SearchResultItem;
