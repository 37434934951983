import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSearchBar from '../useSearchBar';
import { mixpanel } from 'utils';

const useOpenInput = () => {
  const { trial, upgrade, permission } = useSearchBar();
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const handleChange = ({ target: { value } }) => {
    // if (value.length < 3 || value === " ") return;
    setValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();

    params.set('term', value);
    setValue('');
    mixpanel.track('Open Search Query', { Term: value });
    navigate(`/search?${params.toString()}`);
  };

  const handleClear = () => {
    setValue('');
  };

  return {
    value,
    expired: !(upgrade && +trial.open > 0) && permission < 3,
    handleChange,
    handleSubmit,
    handleClear,
  };
};

export default useOpenInput;
