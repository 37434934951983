import { forwardRef } from 'react';
import SpotifySearchResults from './Results/SpotifySearchResults';
import useSimilarityInput from './useSimilarityInput';
import { ReactComponent as Clear } from 'assets/images/icons/clear.svg';
import { ReactComponent as Search } from 'assets/images/icons/search.svg';
import { StyledSimilarityInput } from './SimilarityInput.styled';

const SimilarityInput = forwardRef((_, inputRef) => {
  const similarityProps = useSimilarityInput();
  const { expired, results, loading, term, debouncedValue } = similarityProps;
  const { handleChange, handleSubmit, handleClose } = similarityProps;

  return (
    <StyledSimilarityInput>
      <Search className="search-icon" />
      <input
        ref={inputRef}
        value={term}
        onChange={handleChange}
        placeholder={
          expired
            ? 'Please upgrade to continue using this feature'
            : 'Search for a song...'
        }
        disabled={expired}
      />
      {term && <Clear className="input-icon" onClick={handleClose} />}
      {(results || loading) && (
        <SpotifySearchResults
          results={results}
          term={debouncedValue}
          loading={loading}
          handleClose={handleClose}
          handleClick={handleSubmit}
        />
      )}
    </StyledSimilarityInput>
  );
});

export default SimilarityInput;
