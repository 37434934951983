import React from 'react';
import { StyledHeader } from './Header.styled';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/persona-logo.svg';
import SearchBar from 'components/SearchBar/SearchBar';
import { useSelector } from 'react-redux';
import Banner from './Banner/Banner';
import Nav from './Nav/Nav';
import SiteMenu from './Menu/SiteMenu';
import { mobile } from 'hooks/viewport.signal';
import { RootState } from 'services/state/store/configureStore';

const Header: React.FC = () => {
  const { user, permission } = useSelector((state: RootState) => state.auth);
  const { pathname } = useLocation();
  const minimal = pathname === '/pricing/upgrade';

  return (
    <>
      {!minimal && !user ? <Banner /> : null}
      <StyledHeader $authed={!!user ? 1 : undefined}>
        <div className="header-content-wrapper">
          <div className="header-content">
            {mobile.value ? <SiteMenu /> : null}
            <NavLink to="/" className="site-logo" aria-label="home">
              <Logo />
            </NavLink>
            {!user && minimal ? null : (
              <Nav
                user={user}
                minimal={!minimal}
                mobile={mobile.value}
                sfx={permission >= 2}
              />
            )}
          </div>
        </div>
        {!minimal && user ? <SearchBar /> : null}
      </StyledHeader>
    </>
  );
};

export default Header;
