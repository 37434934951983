import AnimateHeight from 'react-animate-height';
import TrackList from '../List/TrackList';
import { StyledTrackDrawer } from '../Track.styled';
import { ModalLink } from 'components/Button';
import React, { useEffect, useState } from 'react';

interface TrackDrawerProps {
  track: any;
  alts: any[];
  tags: any[];
  handleAlbum: (e: React.MouseEvent, album: any) => void;
  handleTag: (e: React.MouseEvent, tag: any) => void;
}

const TrackDrawer: React.FC<TrackDrawerProps> = ({
  track,
  alts,
  tags,
  handleAlbum,
  handleTag,
}) => {
  const { album_title, album_id, album_cover_src } = track;
  const { album_release_date, isrc, bpm, subtitle } = track;

  // TODO: Mid-step to account for both current and future implementations
  const alternates = alts.length ? alts : track.alternate_set;

  const [view, setView] = useState(false);

  useEffect(() => {
    setView(true);
  }, []);

  return (
    <StyledTrackDrawer>
      <AnimateHeight duration={160} height={view ? 'auto' : 0}>
        {!!alternates?.length && <TrackList tracks={alternates} alternate />}
        <div className="drawer-details">
          <div className="drawer-meta">
            {subtitle && (
              <div className="drawer-meta-item">
                <h4>Subtitle</h4>
                <div>{subtitle}</div>
              </div>
            )}
            <div className="drawer-meta-item">
              <h4>Album</h4>
              <ModalLink
                to={`/album/${album_id}`}
                className="album"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  handleAlbum(e, {
                    id: album_id,
                    cover_src: album_cover_src,
                    title: album_title,
                    artists: track.primary_artists,
                  });
                }}
              >
                {album_title}
              </ModalLink>
            </div>
            <div className="drawer-meta-item">
              <h4>BPM</h4>
              <div>{bpm || `N/A`}</div>
            </div>
            <div className="drawer-meta-item">
              <h4>Released</h4>
              <div>{album_release_date}</div>
            </div>
            {isrc && (
              <div className="drawer-meta-item">
                <h4>ISRC</h4>
                <div>{isrc}</div>
              </div>
            )}
          </div>
          {!!tags.length && (
            <div className="drawer-tags">
              <h4>Tags</h4>
              {tags.map((tag) => (
                <span
                  key={tag.id}
                  onClick={(e: React.MouseEvent) => handleTag(e, tag)}
                >
                  {tag.name}
                </span>
              ))}
            </div>
          )}
        </div>
      </AnimateHeight>
    </StyledTrackDrawer>
  );
};

export default TrackDrawer;
