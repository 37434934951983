import { getLsToken, parseJwt } from 'utils';
import { userSignOut } from 'services/state/actions/userActions';
import { getLsUser } from 'utils/localStorage';
import { Middleware } from 'redux';
import { RootState } from './configureStore';

export const session: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const { refresh } = getLsToken();
    const { user } = getLsUser();

    if (user && !refresh) {
      return store.dispatch<any>(userSignOut({ force: true }));
    }
    if (refresh) {
      const { exp }: any = parseJwt(refresh);

      if (exp * 1000 - Date.now() < 0) {
        return store.dispatch<any>(userSignOut({ force: true }));
      }
    }
    return next(action);
  };
