import marvelLogo from 'assets/images/logos/marvel.svg';
import hboLogo from 'assets/images/logos/hbo.svg';
import abcLogo from 'assets/images/logos/abc.svg';
import foxLogo from 'assets/images/logos/fox.svg';
import paramountLogo from 'assets/images/logos/paramount.svg';
import netflixLogo from 'assets/images/logos/netflix.svg';

const brands = [
  { name: 'Marvel', path: marvelLogo },
  { name: 'HBO', path: hboLogo },
  { name: 'ABC', path: abcLogo },
  { name: 'Fox', path: foxLogo },
  { name: 'Paramount Picture', path: paramountLogo },
  { name: 'Netflix', path: netflixLogo },
];
export default brands;
