import { FC } from 'react';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import ActionArrowButton from 'components/Button/ActionArrowButton/ActionArrowButton';

const AISearch: FC = () => {
  return (
    <div className="section-content">
      <div>
        <h2>Conversational AI Search</h2>
        <h4>Your Own AI-powered Music Search Assistant</h4>
        <div className="media mobile">
          <video controls>
            <source
              src="https://storage.googleapis.com/persona-music-prod-public/PERSONA%20AI%20DEMO%20VID_Edited.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>{' '}
        </div>
        <p>
          Find the music you’re looking for in minutes, not hours. Search
          Fatigue is a thing of the past.
        </p>
        <p>
          Harness the power of OpenAI in your search. Effortlessly find tunes
          with a conversational search interface that helps you pinpoint the
          exact sound you’re looking for - reducing traditional search times by
          93%.
        </p>
        <ActionArrowButton $primary>Start Free Now</ActionArrowButton>
        <AppSumoRating />
      </div>
      <div className="media desktop">
        <video controls>
          <source
            src="https://storage.googleapis.com/persona-music-prod-public/PERSONA%20AI%20DEMO%20VID_Edited.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>{' '}
      </div>
    </div>
  );
};

export default AISearch;
