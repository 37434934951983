import { ReactComponent as Favorite } from 'assets/images/icons/favorite.svg';
import { ReactComponent as FavoriteBorder } from 'assets/images/icons/favorite-border.svg';
import { ReactComponent as Plus } from 'assets/images/icons/plus.svg';
import { ReactComponent as Similar } from 'assets/images/icons/similar.svg';
import { ReactComponent as Download } from 'assets/images/icons/download.svg';
import { ReactComponent as Cart } from 'assets/images/icons/shopping-cart.svg';
import { ReactComponent as Chevron } from 'assets/images/icons/chevron-down.svg';
import { ReactComponent as Clear } from 'assets/images/icons/clear.svg';
import { ReactComponent as Stems } from 'assets/images/icons/stems.svg';
import { StyledUserControls } from '../Track.styled';
import { IconButton } from 'components/Button';
import Volume from 'components/Player/Volume/Volume';
import useTrackControls from './TrackControls.hooks';
import React from 'react';
import { Track } from 'types/track';

interface TrackControlsProps {
  track: Track;
  slim?: boolean;
  open?: boolean;
  volume?: number;
  mobile?: boolean;
  handleRemove?: () => void;
  handleDrawer?: () => void;
  handleVolume?: (volume: number) => void;
}

const TrackControls: React.FC<TrackControlsProps> = ({
  track,
  slim,
  open,
  volume,
  mobile,
  handleRemove,
  handleDrawer,
  handleVolume,
}) => {
  const {
    paid,
    trial,
    access,
    upgrade,
    favorite,
    downloading,
    handleFavorite,
    handleProject,
    handleSimilar,
    handleDownload,
    handleStems,
  } = useTrackControls(track);

  const alts = +track.alternate_set?.length || track.alternate_count;

  return (
    <StyledUserControls>
      {handleVolume && !mobile && (
        <Volume volume={volume} handleVolume={handleVolume} />
      )}
      <IconButton
        id={track.id + '-favorite'}
        aria-label="add to favorite"
        tooltip={`${!favorite ? 'Add to' : 'Remove from'} favorites`}
        handleClick={handleFavorite}
      >
        {favorite ? (
          <Favorite style={{ color: '#be0e0e' }} />
        ) : (
          <FavoriteBorder />
        )}
      </IconButton>
      <IconButton
        id={track.id + '-project'}
        aria-label="add to project"
        tooltip="Add to project"
        handleClick={handleProject}
      >
        <Plus />
      </IconButton>
      {access || upgrade ? (
        <>
          <IconButton
            id={track.id + '-similar'}
            aria-label="similar tracks"
            tooltip={upgrade ? <p>Similar tracks</p> : `Similar tracks`}
            handleClick={handleSimilar}
            disabled={!access}
            upgrade={upgrade}
            trialAttempts={trial?.similar_tracks}
          >
            <Similar />
          </IconButton>
          <IconButton
            id={track.id + '-stems'}
            aria-label="stems"
            tooltip={upgrade ? <p>Stems</p> : `Stems`}
            handleClick={handleStems}
            disabled={!access}
            upgrade={!!upgrade}
          >
            <Stems />
          </IconButton>
        </>
      ) : null}
      {track.is_premium && !paid ? (
        <IconButton
          id={track.id + '-purchase'}
          aria-label="purchase track"
          tooltip="Purchase"
          handleClick={handleDownload}
        >
          <Cart />
        </IconButton>
      ) : (
        <IconButton
          id={track.id + '-download'}
          aria-label="download track"
          tooltip="Download"
          handleClick={handleDownload}
        >
          <Download className={downloading ? 'loading' : undefined} />
        </IconButton>
      )}
      {!slim && !!handleDrawer && (
        <IconButton
          id={track.id + '-more'}
          aria-label="open more"
          tooltip="More"
          handleClick={handleDrawer}
        >
          {!!alts && <span className="badge">+{alts}</span>}
          <Chevron className={open ? 'open' : undefined} />
        </IconButton>
      )}
      {handleRemove && (
        <IconButton
          id={track.id + '-remove'}
          aria-label="remove track"
          tooltip="Remove track"
          handleClick={handleRemove}
        >
          <Clear />
        </IconButton>
      )}
    </StyledUserControls>
  );
};

export default TrackControls;
