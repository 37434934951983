export const size = {
  mobile: 768,
  laptop: 1440,
  desktop: 2560,
};

export const device = {
  mobile: `(max-width: ${size.mobile}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  desktop: `(max-width: ${size.desktop}px)`,
};
