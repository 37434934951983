import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { StyledMusic } from 'components/MusicComponent/Music.styled';
import { device } from 'assets/styles/device';

export const StyledLandingLatestTracks = styled(StyledLandingSection)`
  @media ${device.mobile} {
    ${StyledMusic} {
      & > div {
        padding-inline: 0;

        .list-controls {
          display: none;
        }
      }
    }
  }
`;
