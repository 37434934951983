import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from 'assets/styles/device';

export const StyledSearchButtonToolTip = styled.div`
  inline-size: min(20rem, 80vw);
  padding: 0.5rem;
  font-weight: normal;
  font-size: 0.875rem;

  & > *:last-child {
    margin-block-end: 0;
  }

  h3 {
    font-size: 1.2rem;
    text-align: center;
  }

  .underline {
    text-decoration: underline;
  }

  .attempts {
    font-weight: bold;
    text-wrap: pretty;
    text-align: center;
    background: #fff1;
    padding: 0.25rem;
    border-radius: 0.25rem;
    color: #fffa;
  }
`;

export const StyledSearchButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.875rem;
  background: #000;
  padding: 0.25rem;
  border-radius: 0.5rem;

  .search-button-tooltip {
    --rt-color-white: #999;
    --rt-color-dark: #282828;
    --rt-opacity: 1;
    --rt-transition-show-delay: 0s;
    --rt-transition-closing-delay: 0s;
    font-family: inherit;
    z-index: 1;
    font-weight: 700;
  }

  @media ${device.mobile} {
    justify-content: center;
    block-size: 100%;
  }
`;

export const StyledSearchButton = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.125rem;
  /* background-color: #55555544; */
  color: #ffffff88;
  padding: 0.2rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  text-align: start;
  margin: 0;
  opacity: 1;
  transition: 160ms;
  line-height: 1;
  white-space: nowrap;
  block-size: 100%;

  &.disabled {
    opacity: 0.6;
  }

  & .highlight {
    color: #3d60be;
  }

  & > .sub {
    font-weight: normal;
    font-size: 0.8em;
    opacity: 0.7;
  }

  &.active {
    background-color: #222;
    color: #ffffffbb;
    opacity: 1;
  }

  &:hover {
    color: #ffffffaa;

    &.active {
      color: #ffffffdd;
    }
  }

  @media ${device.mobile} {
    white-space: initial;
    font-size: 0.65rem;
    text-align: center;
    line-height: 1.2;
    padding-inline: 0.5rem;

    & > .sub {
      display: none;
    }
  }
`;
