const initialState = {
  favorites: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FAVORITES_LOADING':
    case 'ADD_FAVORITE_LOADING':
    case 'REMOVE_FAVORITE_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_FAVORITES_SUCCESS':
      return {
        ...state,
        favorites: action.payload,
        loading: false,
        error: null,
      };

    case 'ADD_FAVORITE_SUCCESS':
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
        loading: false,
        error: null,
      };

    case 'REMOVE_FAVORITE_SUCCESS':
      return {
        ...state,
        favorites: state.favorites.filter((id) => action.payload !== id),
        loading: false,
        error: null,
      };

    case 'GET_FAVORITES_ERROR':
    case 'ADD_FAVORITE_ERROR':
    case 'REMOVE_FAVORITE_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
