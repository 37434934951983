import usePathString from './usePathString';

const SvgWave = ({ peaks, progress, color, large, width = 600 }) => {
  const { pathString } = usePathString({ peaks, width, large });

  const pathProps = {
    stroke: color || 'currentColor',
    strokeWidth: large ? 0.2 : 0.6,
    opacity: 0.6,
    vectorEffect: 'non-scaling-stroke',
    fill: color || 'currentColor',
  };
  const progressProps = {
    ...pathProps,
    className: 'progress',
    stroke: color || 'currentColor',
    opacity: 1,
    style: {
      clipPath: `inset(0 ${(1 - progress) * 100}% 0 0)`,
    },
  };

  return (
    <svg
      height="100%"
      width="100%"
      viewBox={`0 0 ${width} ${large ? 50 : 30}`}
      preserveAspectRatio="none"
    >
      <path {...pathProps} d={pathString} />
      <path {...progressProps} d={pathString} />
    </svg>
  );
};

export default SvgWave;
