import { useDebounce } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearch, searchTerm } from 'services/state/actions/searchActions';

const useTextInput = () => {
  const { results, loading } = useSelector((state) => state.search);
  const { selectedTags } = useSelector((state) => state.searchTags);
  const [term, setTerm] = useState('');
  const debouncedValue = useDebounce(term, 500);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchTerm(debouncedValue));
  }, [dispatch, debouncedValue]);

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const handleClose = () => {
    setTerm('');
    dispatch(closeSearch());
  };

  return {
    results,
    loading,
    selectedTags,
    term,
    debouncedValue,
    handleChange,
    handleClose,
  };
};

export default useTextInput;
