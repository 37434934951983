import * as playlistApi from 'services/api/playlistApi.js';
import { openModal } from './modalActions';
import toastr from 'utils/toastr';

const types = {
  GET_PLAYLIST_LOADING: 'GET_PLAYLIST_LOADING',
  GET_PLAYLIST_SUCCESS: 'GET_PLAYLIST_SUCCESS',
  GET_PLAYLIST_ERROR: 'GET_PLAYLIST_ERROR',
  GET_PLAYLIST_PRELOAD: 'GET_PLAYLIST_PRELOAD',
  CLEAR_PLAYLIST: 'CLEAR_PLAYLIST',
};

export const getPlaylistById = (id) => {
  return (dispatch) => {
    dispatch({ type: types.GET_PLAYLIST_LOADING });
    return playlistApi
      .getPlaylist(id)
      .then((playlist) => {
        dispatch({ type: types.GET_PLAYLIST_SUCCESS, payload: playlist });
      })
      .catch((error) => {
        toastr.error(`There's no playlist with ID ${id}`);
        dispatch({ type: types.GET_PLAYLIST_ERROR, error: error.message });
        throw error;
      });
  };
};

export const preloadPlaylist = (payload) => (dispatch) => {
  dispatch({ type: types.GET_PLAYLIST_PRELOAD, payload: payload });
};

export const openPlaylist = (payload, values) => (dispatch) => {
  dispatch(preloadPlaylist(payload));
  dispatch(openModal('playlist', values));
  return dispatch(getPlaylistById(payload.id));
};

export const clearPlaylist = () => (dispatch) => {
  dispatch({ type: types.CLEAR_PLAYLIST });
};
