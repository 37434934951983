import * as albumApi from 'services/api/albumApi.js';
import * as trackApi from 'services/api/trackApi.js';
import { openModal } from './modalActions';
import toastr from 'utils/toastr';

const types = {
  GET_ALBUM_LOADING: 'GET_ALBUM_LOADING',
  GET_ALBUM_SUCCESS: 'GET_ALBUM_SUCCESS',
  GET_ALBUM_ERROR: 'GET_ALBUM_ERROR',
  GET_ALBUM_PRELOAD: 'GET_ALBUM_PRELOAD',
  GET_MORE_ALBUM_TRACKS_LOADING: 'GET_MORE_ALBUM_TRACKS_LOADING',
  GET_MORE_ALBUM_TRACKS_SUCCESS: 'GET_MORE_ALBUM_TRACKS_SUCCESS',
  GET_MORE_ALBUM_TRACKS_ERROR: 'GET_MORE_ALBUM_TRACKS_ERROR',
  CLEAR_ALBUM: 'CLEAR_ALBUM',
};

export const getAlbumById = (id) => (dispatch, getState) => {
  const { limit } = getState().album;

  dispatch({ type: types.GET_ALBUM_LOADING });
  return albumApi
    .getAlbum({ id, limit })
    .then((payload) => {
      dispatch({ type: types.GET_ALBUM_SUCCESS, payload });
    })
    .catch((error) => {
      toastr.error(`There's no album with ID ${id}`);
      dispatch({ type: types.GET_ALBUM_ERROR, error: error.message });
      throw error;
    });
};

export const getMoreAlbumTracks = (id) => (dispatch, getState) => {
  const { limit, nextPage } = getState().album;

  dispatch({ type: types.GET_MORE_ALBUM_TRACKS_LOADING });
  return trackApi
    .getTracks({ limit, nextPage, album: id })
    .then((results) => {
      dispatch({
        type: types.GET_MORE_ALBUM_TRACKS_SUCCESS,
        payload: results,
      });
    })
    .catch((message) => {
      dispatch({ type: types.GET_MORE_ALBUM_TRACKS_ERROR, error: message });
      return message;
    });
};

export const preloadAlbum = (payload) => (dispatch) => {
  dispatch({ type: types.GET_ALBUM_PRELOAD, payload });
};

export const openAlbum = (payload, values) => (dispatch) => {
  dispatch(preloadAlbum(payload));
  dispatch(openModal('album', values));
  return dispatch(getAlbumById(payload.id));
};

export const clearAlbum = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ALBUM });
};
