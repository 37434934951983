import AnimateHeight from 'react-animate-height';
import { StyledPasswordRules } from './PasswordRules.styled';

const rules = [
  { condition: 'At least 8 characters', regex: /^.{8,}$/ },
  { condition: 'At least 1 capital letter', regex: /[A-Z]/ },
  { condition: 'At least 1 number', regex: /[0-9]/ },
  {
    condition: 'At least 1 special character: !@#$%^&*-_',
    regex: /[!@#$%^&*\-_]/,
  },
];

const PasswordRules = ({ value, open }) => (
  <StyledPasswordRules>
    <AnimateHeight duration={160} height={open ? 'auto' : 0}>
      <div className="rules-header">Must include:</div>
      <ul>
        {rules.map((rule) => (
          <li
            key={rule.regex}
            className={rule.regex.test(value) ? 'active' : ''}
          >
            <span>{rule.condition}</span>
          </li>
        ))}
      </ul>
    </AnimateHeight>
  </StyledPasswordRules>
);

export default PasswordRules;
