import axios from './config/axiosConfig';

export const getPurchases = async () => {
  try {
    const res = await axios.get(`/api/accounts/purchases`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const purchaseTrack = async (track) => {
  try {
    const res = await axios.post(`/api/accounts/purchases`, {
      track_id: track.id,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
