import styled from 'styled-components';

export const StyledIconButton = styled.div`
  display: inline-block;
  text-align: center;

  .search-button-tooltip {
    --rt-color-white: #999;
    --rt-color-dark: #282828;
    --rt-opacity: 1;
    --rt-transition-show-delay: 0s;
    --rt-transition-closing-delay: 0s;
    font-family: inherit;
    z-index: 100;
    font-weight: 700;
  }

  & > button {
    position: relative;
    vertical-align: top;

    & > span {
      padding: 3px;
      display: block;
    }
    svg {
      width: 20px;
      height: 20px;
    }

    &:disabled > span,
    &.disabled > span {
      cursor: default;
      opacity: 0.5;
    }
  }
`;
