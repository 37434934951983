import { useDebounce } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  clearSearchBar,
  searchSpotify,
} from 'services/state/actions/searchBarActions';
import useSearchBar from '../useSearchBar';
import { mixpanel } from 'utils';

const useSimilarityInput = () => {
  const { spotifyResults, loading } = useSelector((state) => state.searchBar);
  const { trial, upgrade, permission } = useSearchBar();
  const [term, setTerm] = useState('');
  const debouncedValue = useDebounce(term, 500);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!debouncedValue?.length) return;
    dispatch(searchSpotify({ term: debouncedValue }));
  }, [dispatch, debouncedValue]);

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const handleClose = () => {
    setTerm('');
    dispatch(clearSearchBar());
  };

  const handleSubmit = (result) => {
    const params = new URLSearchParams();
    params.set('ssp', result.id);
    params.set('t', result.title);
    params.set('a', result.artist);

    handleClose();

    mixpanel.track('Sounds Similar Selection', {
      Track: `${result.title} by ${result.artist}`,
    });

    navigate(`/search?${params.toString()}`);
  };

  return {
    results: spotifyResults,
    expired: !(upgrade && +trial.similar > 0) && permission < 3,
    loading,
    term,
    debouncedValue,
    handleChange,
    handleSubmit,
    handleClose,
  };
};

export default useSimilarityInput;
