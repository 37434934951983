import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledBackButton = styled.div`
  padding-left: 4%;

  button {
    height: 60px;
    line-height: 60px;
    font-size: 0.925rem;
    display: flex;
    align-items: center;

    svg {
      height: 32px;
      width: 32px;
      margin-right: 8px;
    }
  }
`;

export interface StyledButtonProps {
  $primary?: boolean;
  $outline?: boolean;
  $white?: boolean;
  $clear?: boolean;
  $danger?: boolean;
  $full?: boolean;
  $large?: boolean;
  $width?: string;
  $height?: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
  --border-radius: 4px;
  --padding: 0 ${({ $large }) => ($large ? '2.3rem' : '1.5rem')};
  --height: ${({ $large, $height }) =>
    $large ? '3.5rem' : $height || '2.8rem'};
  --background-primary: ${({ theme }) =>
    `linear-gradient(
      59deg,
      ${theme.highlightSecondary},
      ${theme.highlightPrimary} 20%,
      ${theme.highlightPrimary} 48%,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 48px,
      hsla(0, 0%, 100%, 1) 52%,
      ${theme.highlightPrimary} 0,
      ${theme.highlightSecondary} 80%,
      ${theme.highlightSecondary}
    )`};
  --background-danger: ${({ theme }) => theme.textError};
  --background-white: #ffffff;
  --background-clear: rgba(255, 255, 255, 0.14);
  --background: ${({ $primary, $outline, $white, $clear, $danger }) =>
    $primary
      ? `var(--background-primary)`
      : $outline
      ? `transparent`
      : $white
      ? `var(--background-white)`
      : $clear
      ? `var(--background-clear)`
      : $danger
      ? `var(--background-danger)`
      : '#666'};
  position: relative;
  border: none;
  cursor: pointer;
  outline-offset: 4px;
  width: ${({ $full }) => ($full ? '100%' : 'auto')};
  min-width: ${({ $width }) => $width || 'initial'};
  font-size: 1.1rem;
  padding: var(--padding);
  height: var(--height);
  border-radius: var(--border-radius);
  color: ${({ $white }) => ($white ? '#000000' : '#ffffff')};
  background: var(--background);
  background-position: 0 50%;
  background-size: 270%;
  transition: filter 250ms, background-position 240ms, color 200ms,
    background-color 200ms;
  font-weight: ${({ $large }) => ($large ? '700' : '400')};
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ $outline }) =>
    $outline &&
    `
        box-shadow: 0 0 0 1px #ffffff;
    `}

  &:hover:not(:disabled) {
    filter: brightness(110%);
    background-position: 100% 50%;
    ${({ $outline, $white }) =>
      ($outline || $white) &&
      `
        background-color: #ffffff;
        color: #000000;
    `}
  }
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
  svg {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  @media ${device.mobile} {
    font-size: 1rem;
  }
`;
