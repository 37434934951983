import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { StyledActionArrowButton } from 'components/Button/ActionArrowButton/ActionArrowButton.styled';
import { device } from 'assets/styles/device';

export const StyledLandingUseCases = styled(StyledLandingSection)`
  .cases {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(11rem, auto));
    justify-content: space-between;
    font-weight: 400;
    gap: 1rem;

    & > div {
      display: flex;
      gap: 0.5rem;

      & > svg {
        flex-shrink: 0;
        color: #3371c5;
        margin: -2px;
      }
    }
  }

  ${StyledActionArrowButton} {
    margin: 4rem auto 1.625rem;
    display: block;
  }

  @media ${device.mobile} {
    .cases {
      grid-template-columns: repeat(3, auto);
      gap: 0.625rem;

      & > div {
        gap: 0.25rem;
      }
    }
  }
`;
