import styled from 'styled-components';
import { StyledButton, StyledButtonProps } from '../Button.styled';
import { device } from 'assets/styles/device';

export const StyledActionArrowButton = styled(StyledButton)<StyledButtonProps>`
  font-size: 1.5rem;
  font-weight: 800;
  border-radius: 4rem;
  margin-block-start: 1.5rem;
  block-size: 4rem;
  inline-size: min(24.125rem, 100%);
  margin-block-end: 1.625rem;

  & > svg {
    margin: 0;
    inline-size: 1rem;
    margin-inline-start: 0.5rem;
  }

  @media ${device.mobile} {
    font-size: 1.125rem;
    margin-block: 2rem;
    block-size: 3.5rem;
    inline-size: min(20rem, 100%);
    margin-inline: auto;
    display: block;
  }
`;
