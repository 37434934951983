import { StyledLargeSwitch } from "./LargeSwitch.styled";

const LargeSwitch = ({
  selectedIndex,
  items,
  account,
  disabled,
  handleChange,
}) => (
  <StyledLargeSwitch
    $items={items.length}
    $account={account}
    $selected={selectedIndex}
    $disabled={disabled}
  >
    <div className="switch">
      <div className="switch-color"></div>
    </div>
    {items.map((item, i) => (
      <div
        key={i}
        className={`switch-item${selectedIndex === i ? " active" : ""}`}
        onClick={() => (!disabled ? handleChange(i) : false)}
      >
        {item}
      </div>
    ))}
  </StyledLargeSwitch>
);

export default LargeSwitch;
