const initialState = {
  sending: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_MAIL_SENDING':
      return {
        ...state,
        sending: true,
      };

    case 'SEND_MAIL_SUCCESS':
      return {
        sending: false,
        error: null,
      };

    case 'SEND_MAIL_ERROR':
      return {
        sending: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
