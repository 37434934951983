import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { downloadTrack } from 'services/state/actions/downloadsActions';
import {
  addFavorite,
  removeFavorite,
} from 'services/state/actions/favoritesActions';
import { openModal } from 'services/state/actions/modalActions';
import { setProjectTrack } from 'services/state/actions/projectsActions';
import { RootState, useAppDispatch } from 'services/state/store/configureStore';
import { Track } from 'types/track';

const useTrackControls = (track: Track) => {
  const { user, permission } = useSelector((state: RootState) => state.auth);
  const { favorites } = useSelector((state: RootState) => state.favorites);
  const { downloading } = useSelector((state: RootState) => state.downloads);
  const { purchases } = useSelector((state: RootState) => state.purchases);
  const favorite = favorites.includes(track?.id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const upgrade =
    user?.appsumo?.id &&
    !['personamusic_tier3', 'personamusic_tier4_3'].includes(
      user?.subscription?.type
    );

  const paid = purchases.some((p: any) => p.id === track.id);

  const handleFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (favorite) {
      return dispatch(removeFavorite(track.id));
    }
    dispatch(addFavorite(track.id));
  };

  const handleProject = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setProjectTrack(track));
  };

  const handleSimilar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const params = new URLSearchParams();
    params.set('slb', track.id);
    params.set('t', track.title);
    params.set('a', track.primary_artists[0].name);

    navigate(`/search?${params.toString()}`);
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(downloadTrack(track));
  };

  const handleStems = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(openModal('stems', { track }));
  };

  return {
    paid,
    upgrade,
    access: permission >= 2,
    favorite,
    downloading: downloading === track.id,
    trial: user?.subscription?.trial,
    handleFavorite,
    handleProject,
    handleSimilar,
    handleDownload,
    handleStems,
  };
};

export default useTrackControls;
