import AppSumoRating from 'components/AppSumo/AppSumoRating';
import InfiniteCarousel from 'components/InfiniteCarousel/InfiniteCarousel';
import { ReactComponent as Check } from 'assets/images/icons/check-wavy.svg';
import { ReactComponent as StarCircle } from 'assets/images/icons/star-circle.svg';
import { ReactComponent as ArrowDownload } from 'assets/images/icons/arrow-download.svg';
import brands from 'constants/brands';
import { StyledLandingHero } from './HeroSection.styled';
import ActionArrowButton from 'components/Button/ActionArrowButton/ActionArrowButton';
import BulletPoint from 'components/BulletPoint/BulletPoint';
import { mobile } from 'hooks/viewport.signal';

const HeroSection = () => (
  <StyledLandingHero>
    <div className="section-content">
      <div className="hero-content">
        <AppSumoRating />
        <h1>Royalty-Free Music Used By Hollywood Studios & Hit TV Shows</h1>
        <BulletPoint icon={Check}>
          <strong>Primetime-Quality: </strong>Heard on Netflix, Marvel, HBO &
          more.
        </BulletPoint>
        <BulletPoint icon={StarCircle}>
          <strong>No Copyright Claims: </strong>Soundtrack your creativity
          without copyright worries.
        </BulletPoint>
        <BulletPoint icon={ArrowDownload}>
          <strong>Impress Your Audience: </strong>Professional audio to increase
          reach & retention.
        </BulletPoint>
        <ActionArrowButton $primary>Start Free Now</ActionArrowButton>
      </div>
    </div>
    <div className="used-by">
      <h3 className="section-content">Our Music Is Used By</h3>
      <div className="brands">
        <InfiniteCarousel
          items={brands.map((b) => b.path)}
          $gap={mobile.value ? '1.74rem' : '4.5rem'}
        />
      </div>
    </div>
  </StyledLandingHero>
);

export default HeroSection;
