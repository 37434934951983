import { Suspense, lazy } from 'react';
import Modal from 'components/Modal/Modal';

// No lazy load for these to ensure no rerender of parent modal of lazy loaded component
import SignInModal from 'components/SignInModal/SignInModal';
import SubscribeModal from 'components/SubscribeModal/SubscribeModal';
import ChannelModal from 'components/ChannelModal/ChannelModal';
import ProjectModal from 'components/ProjectModal/ProjectModal';
import DownloadModal from 'components/DownloadModal/DownloadModal';
import PurchaseModal from 'components/PurchaseModal/PurchaseModal';

const SignUpModal = lazy(() => import('components/SignUpModal/SignUpModal'));
const ArtistModal = lazy(() => import('pages/ArtistModalPage/ArtistModal'));
const AlbumModal = lazy(() => import('pages/AlbumModalPage/AlbumModal'));
const PartnerModal = lazy(() => import('pages/PartnerModalPage/PartnerModal'));
const PlaylistModal = lazy(() =>
  import('pages/PlaylistModalPage/PlaylistModal')
);
const ContactModal = lazy(() => import('components/ContactModal/ContactModal'));
const StemsModal = lazy(() => import('components/StemsModal/StemsModal'));

export const modals = [
  { type: 'signup', component: SignUpModal },
  { type: 'signin', component: SignInModal },
  { type: 'subscribe', component: SubscribeModal },
  { type: 'channel', component: ChannelModal },
  { type: 'download', component: DownloadModal },
  { type: 'purchase', component: PurchaseModal },
  { type: 'artist', component: ArtistModal, justify: 'top', width: '80%' },
  { type: 'album', component: AlbumModal, justify: 'top', width: '80%' },
  { type: 'creator', component: PartnerModal, justify: 'top', width: '80%' },
  { type: 'playlist', component: PlaylistModal, justify: 'top', width: '80%' },
  { type: 'playlist', component: PlaylistModal, justify: 'top', width: '80%' },
  { type: 'project', component: ProjectModal },
  { type: 'contact', component: ContactModal },
  { type: 'stems', component: StemsModal },
];

export const modalss = {
  signup: SignUpModal,
  signin: SignInModal,
  subscribe: SubscribeModal,
  channel: ChannelModal,
  download: DownloadModal,
  purchase: PurchaseModal,
  artist: ArtistModal,
  creator: PartnerModal,
  project: ProjectModal,
  contact: ContactModal,
  stems: StemsModal,
};

const Modals = () => (
  <Suspense fallback={<div></div>}>
    {modals.map((modal, i) => (
      <Modal key={i} {...modal}></Modal>
    ))}
  </Suspense>
);

export default Modals;
