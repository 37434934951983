import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledFooter = styled.footer`
  hr {
    margin: 0;
    border: 0;
    border-top: 1px solid #2d2d2d;
  }

  & > div {
    inline-size: min(1246px, 90%);
    margin-inline: auto;
  }

  .footer-content {
    font-size: 0.875rem;
    padding-block: 4.5rem;
    display: flex;
    gap: 4rem;

    .company {
      flex: 1;

      .site-logo {
        block-size: 1.875rem;
        margin-block-end: 2rem;
        display: block;

        & > svg {
          block-size: 100%;
        }
      }

      p {
        line-height: 140%;
        max-inline-size: 50ch;

        &:last-of-type {
          margin-block-end: 0;
        }
      }
    }

    .link-set {
      .links-category {
        font-size: 1rem;
        font-weight: 700;
        margin-block-end: 1rem;
      }

      ul li {
        margin-block-end: 0.5rem;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-top: 1px solid #2d2d2d;
    padding-block: 2.125rem;
    font-size: 1rem;

    .methods > *:not(:first-child) {
      margin-block-start: 0.25rem;
    }
  }

  @media ${device.mobile} {
    hr {
      display: none;
    }

    .footer-content {
      flex-direction: column;
      text-align: center;
      gap: 1.5rem;
      padding-block: 3rem 1.5rem;

      p {
        margin-inline: auto;
      }

      .link-set {
        .links-category {
          margin-block-end: 0.5rem;
        }

        ul li {
          margin-block-end: 0.25rem;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }

    .copyright {
      border-top: none;
      flex-direction: column;
      padding-block: 0 3rem;
    }
  }
`;
