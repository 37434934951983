const cases = [
  'YouTube',
  'TikTok',
  'Twitch',
  'Websites',
  'Applications',
  'Film Festivals & Cinema',
  'Facebook',
  'Twitter',
  'Commercials',
  'Weddings',
  'Podcasts',
  'Instagram',
  'Vimeo',
  'Broadcast',
  'Games',
  'Presentations',
  'VOD',
  'Any Future Platforms',
];

export default cases;
