const initialState = {
  access: false,
  coupon: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INVITE_CODE_LOADING':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'INVITE_CODE_SUCCESS':
      return {
        ...state,
        access: true,
        coupon: action.payload || state.coupon,
        loading: false,
        error: null,
      };

    case 'INVITE_CODE_ERROR':
      return {
        ...state,
        access: false,
        coupon: null,
        loading: false,
        error: action.error,
      };

    case 'REMOVE_CODE':
      return initialState;

    default:
      return state;
  }
};

export default reducer;
