import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import StoreProvider from 'services/providers/StoreProvider';
import { ThemeProvider } from 'services/providers/ThemeProvider';

interface ProvidersProps {
  children: ReactNode;
}

const Providers = ({ children }: ProvidersProps) => (
  <StoreProvider>
    <HelmetProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </HelmetProvider>
  </StoreProvider>
);

export default Providers;
