import { useEffect, useState } from 'react';
import { StyledModalContent } from 'components/Modal/Modal.styled';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/Button';
import { clearActiveTrack } from 'services/state/actions/downloadsActions';
import PurchaseForm from './PurchaseForm';
import StripeWrapper from 'components/Stripe/StripeWrapper';
import {
  purchaseError,
  purchaseLoading,
  purchaseTrack,
} from 'services/state/actions/purchasesActions';
import { openModal } from 'services/state/actions/modalActions';

const PurchaseModal = () => {
  const { active } = useSelector((state) => state.downloads);
  const { loading } = useSelector((state) => state.purchases);
  const { user } = useSelector((state) => state.auth);
  const [payment, setPayment] = useState(false);
  const [dollars, cents] = active.price.split('.');
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearActiveTrack());
      setPayment(false);
    };
  }, [dispatch]);

  const handlePurchase = (intent) => {
    dispatch(purchaseTrack({ track: active, intent }));
    dispatch(openModal('download'));
  };

  const handleLoading = () => {
    dispatch(purchaseLoading());
  };

  const handleError = (error) => {
    dispatch(purchaseError(error));
  };

  return (
    <StyledModalContent>
      <div className="modal-header">
        <h2>Purchase Track</h2>
        <p style={{ margin: '36px 0' }}>
          Purchase <strong>{active.title}</strong> by{' '}
          <strong>{active.primary_artists[0].name}</strong> for
        </p>
        <h3 className="price" style={{ margin: '36px 0 64px' }}>
          <sup>$</sup>
          {dollars}.<sup>{cents}</sup>
        </h3>
      </div>
      {payment ? (
        <StripeWrapper>
          <PurchaseForm
            track={active}
            email={user.email}
            loading={loading}
            handlePurchase={handlePurchase}
            handleLoading={handleLoading}
            handleError={handleError}
          />
        </StripeWrapper>
      ) : (
        <Button
          $outline
          onClick={() => setPayment(true)}
          style={{ margin: '16px auto' }}
        >
          Purchase
        </Button>
      )}
    </StyledModalContent>
  );
};

export default PurchaseModal;
