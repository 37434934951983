import image1 from './01Indi5.jpeg';
import image2 from './04thebatman.jpeg';
import image3 from './8GeBQWQOTu7yLwNfCV6iWyL8xNf.jpeg';
import image4 from './9jkThAGYj2yp8jsS6Nriy5mzKFT.jpeg';
import image5 from './Indiana_Jones_and_the_Dial_of_Destiny-145866922-large.jpeg';
import image6 from './12womanking.jpg';
import image7 from './13blackadam.jpeg';
import image8 from './471bobafett.jpeg';
import image9 from './18emancipation.jpeg';
import image10 from './29jurrassicworlddominion.jpeg';
import image11 from './38johnwick4.jpeg';
import image12 from './42strangerthings.jpeg';
import image13 from './51GoT.jpeg';
import image14 from './60soul.jpeg';
import image15 from './68dune.jpeg';
import image16 from './90lalaland.jpeg';
import image17 from './91cgg0QAjaL._AC_UF1000,1000_QL80_.jpg';
import image18 from './110thehobbit.jpeg';
import image19 from './131fury.jpeg';
import image20 from './145interstellar.jpeg';
import image21 from './174madmax.jpeg';
import image22 from './212starwarstheforceawakens.jpeg';
import image23 from './265wonderwoman.jpeg';
import image24 from './266worldwarcraft.jpeg';
import image25 from './269pets.jpeg';
import image26 from './342despicableme3.jpeg';
import image27 from './368avatar2.jpeg';
import image28 from './370wrinkletime.jpeg';
import image29 from './380assassinscreedodyssey.jpeg';
import image30 from './384missionimpossiblefallout.jpeg';
import image31 from './392destiny2forsaken.jpeg';
import image32 from './415topgun.jpeg';
import image33 from './420nope.jpeg';
import image34 from './425fordferrari.jpeg';
import image35 from './470notimetodie.jpeg';
import image36 from './Savages_poster.jpeg';
import image37 from './484westworld.jpeg';
import image38 from './496avengersageultron.jpeg';
import image39 from './499toystory4.jpeg';
import image40 from './507encanto.jpeg';
import image41 from './517spiderman2.jpeg';
import image42 from './518aquaman.jpeg';
import image43 from './523coco.jpeg';
import image44 from './525crimesgrindewald.jpeg';
import image45 from './526mandalorian.jpeg';
import image46 from './535CoDBOIII.jpeg';
import image47 from './540diabloIII.jpeg';
import image48 from './644foundation.jpeg';
import image49 from './ABSOLUTE MOTIVATION 1.4M.jpeg';
import image50 from './chopped-tv-movie-poster-2009-1020693183.jpeg';
import image51 from './Comedians-in-Cars-310x459.ad073423.jpg';
import image52 from './contender_ver3_xlg.1400x1400.jpg';
import image53 from './GALADON GAMING 1.9M.jpeg';
import image54 from './iEidqf0FmnTOFCTkbIzk4uPH2zq.jpeg';
import image55 from './U.S AIRFORCE RECRUITING 165K.jpeg';
import image56 from './JON SIMO 98.7K.jpeg';
import image57 from './MAKAVELI MOTIVATION 869K.jpeg';
import image58 from './MATTHEW BEEM 4.99M.jpeg';
import image59 from './MOTIVERSITY 3.23M.jpeg';
import image60 from './MrsePQb-show-poster2x3-A4XL49y.jpeg';
import image61 from './MV5BMTkxNzk1MDQ1NV5BMl5BanBnXkFtZTgwNzc3MzA0NzM@._V1_.jpg';
import image62 from './MV5BMzI0NmQxZGUtN2MzZS00OTFmLTkyNjAtNDhmOTRjNzk0ZjBiXkEyXkFqcGdeQXVyMzAxNTY5MzY@._V1_.jpg';
import image63 from './MV5BN2Y1ZDMzOTYtMjM0Zi00MDg1LTkzYTktMGIwMGRjMGZhYjkyXkEyXkFqcGdeQXVyMTUwMzM5ODkz._V1_FMjpg_UX1000_.jpg';
import image64 from './MV5BN2YyZjQ0NTEtNzU5MS00NGZkLTg0MTEtYzJmMWY3MWRhZjM2XkEyXkFqcGdeQXVyMDA4NzMyOA@@._V1_.jpg';
import image65 from './MV5BNGVhNTQ5NDQtY2M5OC00OGRkLThlOWEtOTg5YjY0NGM3MjA1XkEyXkFqcGdeQXVyODc0OTEyNDU@._V1_.jpg';
import image66 from './MV5BNTAzNTYzNDI0N15BMl5BanBnXkFtZTgwMDczNDgwNzM@._V1_.jpg';
import image67 from './MV5BOGYxOTM2MjgtZWFlOC00Y2UwLWI3MzItMWMyNjY0YWY5NmEyXkEyXkFqcGdeQXVyMTAwMzM3NDI3._V1_.jpg';
import image68 from './MV5BOTc2YmEyMDctZWJlMi00MDU2LWE1N2UtN2FjMTA2NTI5MjM2XkEyXkFqcGdeQXVyMTY2MzQzMzU2._V1_.jpg';
import image69 from './MV5BY2I5OGQ1ZWMtNjgxNi00ZjQ4LTk3YmQtZGYzMGNkMDkwNjllXkEyXkFqcGdeQXVyNjMxMDgyNzQ@._V1_FMjpg_UX1000_.jpg';
import image70 from './MV5BYjdlZjRiYTUtN2NlNC00MDkxLWI0MmItOTUwNTg1YTU4MWUxXkEyXkFqcGdeQXVyMTA1OTEwNjE@._V1_FMjpg_UX1000_.jpg';
import image71 from './MV5BYjI3ZTg3M2MtZDc5ZC00N2JmLWFmMWUtZDc4ZGQzNTI3Y2ViXkEyXkFqcGdeQXVyNzM3MjY2MjU@._V1_.jpg';
import image72 from './MV5BYzEwYmJiZDgtYTliMi00ZDA2LWEwNzEtYmZjMDZlYjFmMWVkXkEyXkFqcGdeQXVyMTA4NzY0NTU@._V1_FMjpg_UX1000_.jpg';
import image73 from './MV5BZjYzZjY2MTktZmI0NC00MjJhLWFjMjQtMDJmZDhiNDAxM2NhXkEyXkFqcGdeQXVyODk4Nzg5NjE@._V1_.jpg';
import image74 from './NEW ROCKSTARS 3.99M.jpeg';
import image75 from './p16471848_b_v10_ad.jpeg';
import image76 from './Rango-Movie-Poster-2.jpeg';

const row1 = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
  image32,
  image33,
  image34,
  image35,
  image36,
  image37,
  image38,
  image39,
];

const row2 = [
  image40,
  image41,
  image42,
  image43,
  image44,
  image45,
  image46,
  image47,
  image48,
  image49,
  image50,
  image51,
  image52,
  image53,
  image54,
  image55,
  image56,
  image57,
  image58,
  image59,
  image60,
  image61,
  image62,
  image63,
  image64,
  image65,
  image66,
  image67,
  image68,
  image69,
  image70,
  image71,
  image72,
  image73,
  image74,
  image75,
  image76,
];

const combined = [row1, row2];

export default combined;
