import { useState, useEffect, useRef, useMemo, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import {
  closeModal,
  closeSecondaryModal,
} from 'services/state/actions/modalActions';
import { StyledModal } from './Modal.styled';
import ModalClose from './ModalClose';
import { modals } from 'components/Modal/Modals/Modals';
import SecondaryModal from './SecondaryModal';

const Modal = ({ component: Component, type, justify, width }) => {
  const { values, secondary, secondaryValues } = useSelector(
    (state) => state.modal
  );
  const open = useSelector((state) => state.modal[type]);
  const { track } = useSelector((state) => state.player);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const modalRef = useRef();

  const internalModal = modals.find((mod) => mod.type === secondary)?.component;
  const modalElement = document.getElementById('modal');

  const handeClose = useMemo(
    () => () => {
      modalElement.classList.remove('backdrop');
      setReady(false);

      setTimeout(() => {
        dispatch(closeModal());
        modalElement.style.display = 'none';
      }, 300);
    },
    [dispatch, modalElement]
  );

  useEffect(() => {
    const topLevelModal = document.getElementById('modal');

    if (secondary) {
      return topLevelModal.classList.add('secondary');
    }
    topLevelModal.classList.remove('secondary');
  }, [secondary]);

  useLayoutEffect(() => {
    if (open) {
      document.getElementById('modal').style.display = 'block';

      setTimeout(() => {
        document.getElementById('modal').classList.add('backdrop');
        setReady(true);
      }, 100);
    }
    return () => {
      if (open) {
        const query = new URLSearchParams(location.search);

        if (query.has(type)) {
          query.delete(type);

          navigate(location.pathname, {
            search: query.toString(),
            state: { noScroll: true },
            replace: true,
          });
        }
      }
      setTimeout(() => {
        document.getElementById('modal').scrollTo(0, 0);
      }, 10);
      setReady(false);
    };
  }, [open, type, location, navigate]);

  useEffect(() => {
    const closeExternal = (e) => {
      const excludeContainers =
        modalRef.current?.contains(e.target) ||
        document.getElementById('player')?.contains(e.target);

      if (!excludeContainers) handeClose();
    };
    if (modalRef.current && open) {
      document.addEventListener('mousedown', closeExternal);
    }
    return () => document.removeEventListener('mousedown', closeExternal);
  }, [modalRef, dispatch, open, handeClose]);

  const secondaryClose = () => {
    dispatch(closeSecondaryModal());
  };

  if (open) {
    return ReactDOM.createPortal(
      <StyledModal
        className={`${justify ? ' ' + justify : ''}${ready ? ' ready' : ''}`}
        $width={width}
        $secondary={!!internalModal}
        $ready={ready ? 1 : undefined}
      >
        <div
          className="modal loading"
          ref={modalRef}
          style={{
            width: width && width,
            marginBottom: track && '120px',
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ModalClose handleClick={handeClose} />
          {secondary && (
            <SecondaryModal
              values={secondaryValues}
              component={internalModal}
              handleClose={secondaryClose}
            />
          )}
          <Component {...values} handleClose={handeClose} />
        </div>
      </StyledModal>,
      document.getElementById('modal')
    );
  }
  return null;
};

export default Modal;
