import { defaultOrder } from 'constants/trackSorting';

const initialState = {
  playlists: {
    recommended: [],
    curated: [],
    music: [],
  },
  tracks: [],
  partners: [],
  count: 0,
  limit: 10,
  nextPage: null,
  ordering: defaultOrder,
  loading: {
    playlists: false,
    tracks: false,
    partners: false,
  },
  error: {
    playlists: null,
    tracks: null,
    partners: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_HOME_SORT_ORDER':
      return { ...state, tracks: [], ordering: action.payload };

    case 'GET_HOME_PARTNERS_LOADING':
      return {
        ...state,
        loading: { ...state.loading, partners: true },
        error: { ...state.error, partners: null },
      };

    case 'GET_HOME_PLAYLISTS_LOADING':
      return {
        ...state,
        loading: { ...state.loading, playlists: true },
        error: { ...state.error, playlists: null },
      };

    case 'GET_HOME_TRACKS_LOADING':
    case 'GET_MORE_HOME_TRACKS_LOADING':
      return {
        ...state,
        loading: { ...state.loading, tracks: true },
        error: { ...state.error, tracks: null },
      };

    case 'GET_HOME_PARTNERS_SUCCESS':
      return {
        ...state,
        partners: action.payload,
        loading: { ...state.loading, partners: false },
        error: { ...state.error, partners: null },
      };

    case 'GET_HOME_PLAYLIST_SUCCESS':
      return {
        ...state,
        playlists: { ...state.playlists, [action.category]: action.payload },
      };

    case 'GET_HOME_PLAYLISTS_SUCCESS':
      return {
        ...state,
        loading: { ...state.loading, playlists: false },
        error: { ...state.error, playlists: null },
      };

    case 'GET_HOME_TRACKS_SUCCESS':
      return {
        ...state,
        tracks: action.payload.results,
        count: action.payload.count,
        nextPage: action.payload.track_next,
        loading: { ...state.loading, tracks: false },
        error: { ...state.error, tracks: null },
      };

    case 'GET_MORE_HOME_TRACKS_SUCCESS':
      return {
        ...state,
        tracks: [...state.tracks, ...action.payload.results],
        count: action.payload.count,
        nextPage: action.payload.track_next,
        loading: { ...state.loading, tracks: false },
        error: { ...state.error, tracks: null },
      };

    case 'GET_HOME_PARTNERS_ERROR':
    case 'GET_HOME_PLAYLISTS_ERROR':
    case 'GET_HOME_TRACKS_ERROR':
    case 'GET_MORE_HOME_TRACKS_ERROR':
      return {
        ...state,
        loading: initialState.loading,
        error: action.error,
      };

    case 'CLEAR_HOME_TRACKS':
      return {
        ...initialState,
        loading: state.loading,
      };

    default:
      return state;
  }
};

export default reducer;
