import { newsletterSubscribe } from 'services/api/userApi';
import { useFormik } from 'formik';
import { useState } from 'react';

const initialValues = {
  email: '',
};

const validate = (values: typeof initialValues) => {
  const errors: Partial<typeof initialValues> = {};

  if (!values.email) {
    errors.email = 'An email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const useSubscribeForm = () => {
  const [confirmation, setConfirmation] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (
    values: typeof initialValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    if (confirmation) setConfirmation(null);
    if (error) setError(null);

    setLoading(true);

    try {
      const res = await newsletterSubscribe({ email: values.email });
      const message = res?.created
        ? `Thank you for subscribing!`
        : `You're already subscribed!`;
      setConfirmation(message);
      resetForm();
    } catch (err) {
      setError(err as string);
    }
    setLoading(false);
  };

  const formik = useFormik({ initialValues, validate, onSubmit });

  return { formik, confirmation, loading, error };
};

export default useSubscribeForm;
