import React from 'react';
import { ReactComponent as AppSumo } from 'assets/images/logos/appsumo.svg';
import { StyledAppSumoRating } from './AppSumoRating.styled';
import Stars from './Stars';

interface AppSumoRatingProps {
  best?: boolean;
}

const AppSumoRating: React.FC<AppSumoRatingProps> = ({ best }) => (
  <StyledAppSumoRating className={best ? 'best' : undefined}>
    <Stars className={best ? 'best' : undefined} />
    Rated <strong>4.9/5</strong> by <strong className="desktop">2,300+</strong>{' '}
    Creators{' '}
    {best ? (
      <>
        <br className="mobile" />
        <span className="desktop">|</span>{' '}
        <strong className="best-text">Best of 2023</strong>{' '}
      </>
    ) : null}
    <span className="overflow-gap">on</span>
    <AppSumo className="appsumo-logo" />
  </StyledAppSumoRating>
);

export default AppSumoRating;
