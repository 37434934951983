const getLsUser = () => {
  const store =
    localStorage.getItem('persona_store') !== 'undefined'
      ? JSON.parse(localStorage.getItem('persona_store') || '{}')
      : null;

  return { user: store?.auth?.user };
};

const getLsToken = () => {
  const access = localStorage.getItem('access_token');
  const refresh = localStorage.getItem('refresh_token');

  return { access, refresh };
};

interface LsTokenProps {
  access?: string;
  refresh?: string;
}

const setLsToken = ({ access, refresh }: LsTokenProps) => {
  if (access) {
    localStorage.setItem('access_token', access);
  }
  if (refresh) {
    localStorage.setItem('refresh_token', refresh);
  }
};

const clearLsToken = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

const clearLsStore = () => {
  clearLsToken();
  localStorage.removeItem('persona_store');
};

export { getLsUser, getLsToken, setLsToken, clearLsToken, clearLsStore };
