import React from 'react';
import { StyledIconButton } from './IconButton.styled';
import { mobile } from 'hooks/viewport.signal';
import { Tooltip } from 'react-tooltip';
import FeatureDisabled from '../../FeatureDisabled/FeatureDisabled';
import BasicModal from 'components/BasicModal/BasicModal';
import useModalControls from 'components/BasicModal/ModalControls.hooks';
import { StyledSearchButtonToolTip } from '../SearchButton/SearchButtons.styled';

interface IconButtonProps {
  id: string;
  tooltip?: React.ReactNode;
  children: React.ReactNode;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  upgrade?: boolean;
  trialAttempts?: number;
}

const IconButton: React.FC<IconButtonProps> = ({
  id,
  tooltip,
  children,
  handleClick,
  upgrade,
  disabled,
  trialAttempts,
  ...props
}) => {
  const { open, handleOpen, handleClose } = useModalControls();

  const TooltipContent = () => {
    if (!tooltip) return null;

    if (!mobile.value) {
      return (
        <Tooltip
          id={id}
          className="search-button-tooltip"
          clickable={!!upgrade}
        >
          {tooltip}
          {disabled ? (
            <FeatureDisabled upgrade={upgrade} attempts={trialAttempts} small />
          ) : null}
        </Tooltip>
      );
    }

    return (
      <BasicModal open={open} handleClose={handleClose}>
        <StyledSearchButtonToolTip>
          {tooltip}
          {disabled ? (
            <FeatureDisabled upgrade={upgrade} attempts={trialAttempts} small />
          ) : null}
        </StyledSearchButtonToolTip>
      </BasicModal>
    );
  };

  return (
    <StyledIconButton>
      <button
        type="button"
        onClick={disabled && !trialAttempts ? handleOpen : handleClick}
        className={disabled && !trialAttempts ? 'disabled' : undefined}
        disabled={!mobile.value && disabled && !trialAttempts}
        {...props}
      >
        {tooltip && !mobile.value ? (
          <span data-tooltip-id={id}>{children}</span>
        ) : (
          <span>{children}</span>
        )}
      </button>
      <TooltipContent />
    </StyledIconButton>
  );
};

export default IconButton;
