import React from 'react';
import { StyledBulletPoint } from './BulletPoint.styled';

interface BulletPointProps {
  icon: React.FC;
  children: React.ReactNode;
}

const BulletPoint: React.FC<BulletPointProps> = ({ icon: Icon, children }) => (
  <StyledBulletPoint>
    <Icon />
    <span>{children}</span>
  </StyledBulletPoint>
);

export default BulletPoint;
