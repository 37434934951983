import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { userForgotPass } from 'services/state/actions/userActions';
import { useAppDispatch } from 'services/state/store/configureStore';

const initialValues = {
  email: '',
};

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const useForgotForm = ({ handleClose }) => {
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values, { setErrors }) => {
    try {
      await dispatch(userForgotPass(values));
      handleClose();
    } catch (error) {
      if (Array.isArray(error)) {
        error.forEach((err) => setErrors({ [err[0]]: err[1] }));
      }
    }
  };

  const formik = useFormik({ initialValues, validate, onSubmit: handleSubmit });

  return { formik, loading };
};

export default useForgotForm;
