import { StyledSearchBar } from './SearchBar.styled';
import SearchButtons from 'components/Button/SearchButton/SearchButtons';
import useSearchBar from './useSearchBar';
import TextInput from './TextSearch/TextInput';
import SimilarityInput from './SimilaritySearch/SimilarityInput';
import OpenInput from './OpenSearch/OpenInput';

const SearchBar = () => {
  const { loading, inputRef, selected, permission, upgrade, handleSelect } =
    useSearchBar();

  const inputLookup: {
    [key: string]: React.ForwardRefExoticComponent<React.RefAttributes<any>>;
  } = {
    general: TextInput,
    open: OpenInput,
    similar: SimilarityInput,
  };
  const SearchInput = inputLookup[selected];

  return (
    <StyledSearchBar $loading={loading}>
      {SearchInput ? <SearchInput ref={inputRef} /> : null}
      {permission >= 2 || upgrade ? (
        <SearchButtons
          selected={selected}
          handleSelect={handleSelect}
          access={permission >= 2}
          upgrade={upgrade}
        />
      ) : null}
    </StyledSearchBar>
  );
};

export default SearchBar;
