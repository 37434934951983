import { Children } from 'react';
import { StyledSnapCarousel } from './SnapCarousel.styled';
import { ReactComponent as Chevron } from 'assets/images/icons/chevron-left.svg';
import useSnapCarousel from './useSnapCarousel';

const SnapCarousel = ({ cells, children }) => {
  const { cRef, disabled, handleScroll, handleCheck } = useSnapCarousel();

  return (
    <StyledSnapCarousel
      $cells={cells}
      $total={Children.count(children)}
      onScrollCapture={handleCheck}
    >
      <button
        className="scroll next"
        onClick={() => handleScroll(true)}
        disabled={disabled.next}
      >
        <Chevron />
      </button>
      <button
        className="scroll"
        onClick={() => handleScroll(false)}
        disabled={disabled.prev}
      >
        <Chevron />
      </button>
      <div className="carousel-content" ref={cRef}>
        {children}
      </div>
    </StyledSnapCarousel>
  );
};

export default SnapCarousel;
