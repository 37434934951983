import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import image from 'assets/images/backgrounds/sign-up-background.jpg';
import { device } from 'assets/styles/device';
import { StyledActionArrowButton } from 'components/Button/ActionArrowButton/ActionArrowButton.styled';

export const StyledLandingSignUp = styled(StyledLandingSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  isolation: isolate;
  padding-block: 9%;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    max-inline-size: 16ch;
    margin-block: 1rem;
    line-height: 120%;
  }

  &::before {
    background: linear-gradient(
        90deg,
        rgba(2, 3, 4, 0.9) 32.96%,
        rgba(0, 0, 0, 0.71) 45.52%,
        rgba(0, 0, 0, 0.1) 57.26%
      ),
      linear-gradient(
        0deg,
        #030306 0%,
        rgba(2, 2, 3, 0.74) 19.49%,
        rgba(0, 0, 0, 0) 41.57%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
      url(${image});
    background-size: cover, cover, cover, 80%;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    z-index: -1;
    position: absolute;
    content: '';
    inset: 0;
  }

  @media ${device.mobile} {
    padding-block: 12.3rem 0;
    .section-content {
      text-align: center;
    }

    h1 {
      text-align: center;
      font-size: 1.75rem;
      margin: 1.5rem auto;
    }

    ${StyledActionArrowButton} {
      margin-block-end: 0;
    }

    &::before {
      inset-block-start: 0;
      content: '';
      inline-size: 100%;
      aspect-ratio: 1.5;
      background-image: linear-gradient(
          0deg,
          #030306 0%,
          rgba(2, 2, 3, 0.74) 25.36%,
          rgba(0, 0, 0, 0) 54.09%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
        url(${image});
      background-size: contain;
      background-position: 0 0;
    }
  }
`;
