import React from 'react';
import LoadingTrack from './LoadingTrack';

interface NoTracksProps {
  loading?: boolean;
  count: number;
}

const NoTracks: React.FC<NoTracksProps> = ({ loading, count }) => {
  return loading ? (
    <>
      {[...Array(count)].map((_, i) => (
        <LoadingTrack key={i} index={i} />
      ))}
    </>
  ) : (
    <p
      className={'no-content' + (loading ? ' blur' : '')}
      style={{ marginTop: 48 }}
    >
      No tracks available
    </p>
  );
};

export default NoTracks;
