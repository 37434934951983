import styled from "styled-components";

export const StyledSiteWrapper = styled.div`
  opacity: ${({ $ready }) => ($ready ? 1 : 0)};
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    flex: 1;
    overflow: auto;
  }
`;
