import { useLocation } from 'react-router';

export const useQuery = () => {
  const { search } = useLocation();

  if (!search) return {};

  return search
    .replace('?', '')
    .split('&')
    .reduce((obj, str) => {
      const splitStr = str.split('=');
      return {
        ...obj,
        [splitStr[0]]: splitStr[1].includes(',')
          ? splitStr[1].split(',')
          : splitStr[1],
      };
    }, {});
};
