import styled from 'styled-components';
import { device } from 'assets/styles/device';

interface StyledBackgroundEllipseProps {
  $size: string;
  $color: string;
  $top?: string;
  $bottom?: string;
  $left?: string;
  $right?: string;
  $blur?: string;
}

export const StyledLandingPage = styled.div`
  color: #fff;
  font-size: 1rem;
  position: relative;
  isolation: isolate;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-block: 1rem;
    line-height: 120%;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 120%;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 500;
    margin-block-end: 3rem;
  }

  p {
    margin-block-end: 1.25rem;
  }

  @media ${device.mobile} {
    font-size: 0.875rem;

    h2 {
      font-size: 1.5rem;
      line-height: 140%;
      margin-block-end: 1rem;
    }

    h3 {
      font-size: 1rem;
      margin-block-end: 1rem;
    }

    h4 {
      font-size: 0.75rem;
      margin-block-end: 2rem;
    }

    p {
      margin-block-end: 1.25rem;
    }
  }
`;

export const StyledLandingSection = styled.section`
  margin-block: 6.5rem;
  position: relative;
  overflow-x: clip;

  .section-header {
    text-align: center;
    margin-inline: auto;
    margin-block-end: 4rem;
    inline-size: min(1246px, 90%);
  }

  .section-content {
    margin-inline: auto;
    inline-size: min(1246px, 90%);
  }

  @media ${device.mobile} {
    margin-block: 3rem;

    .section-header {
      margin-block-end: 2rem;
      inline-size: calc(100% - 2.5rem);
    }

    .section-content {
      inline-size: calc(100% - 2.5rem);
    }
  }
`;

export const StyledBackgroundEllipse = styled.div<StyledBackgroundEllipseProps>`
  --ellipse-size: ${({ $size }) => $size};
  inline-size: var(--ellipse-size);
  aspect-ratio: 1;
  border-radius: 60rem;
  background-color: ${({ $color }) => $color};
  inset-block-start: ${({ $top }) => $top || 'initial'};
  inset-block-end: ${({ $bottom }) => $bottom || 'initial'};
  inset-inline-start: ${({ $left }) => $left || 'initial'};
  inset-inline-end: ${({ $right }) => $right || 'initial'};
  filter: blur(${({ $blur }) => $blur || '22.875rem'});
  flex-shrink: 0;
  position: absolute;
  animation: rotation 20s ease-in-out infinite;
  transform-origin: 70% 30%;
  z-index: -1;
  translate: ${({ $top, $left }) =>
    `${$left ? '-50%' : '50%'} ${$top ? '-50%' : '50%'}`};

  @keyframes rotation {
    from {
      rotate: 0deg;
    }
    to {
      rotate: 360deg;
    }
  }
`;
