import styled from 'styled-components';
import { device } from 'assets/styles/device';

export const StyledInfiniteCarousel = styled.div`
  --gap: ${({ $gap }) => $gap || '1.5rem'};
  block-size: 100%;
  inline-size: 100%;
  overflow: hidden;
  margin-inline: auto;

  .carousel-track {
    inline-size: max-content;
    block-size: 100%;
    display: flex;
    flex-wrap: nowrap;
    animation: scroll ${({ $duration }) => $duration || '40s'} linear infinite
      ${({ $reverse }) => $reverse && 'reverse'};
    gap: ${({ $gap }) => $gap || '1.5rem'};

    & > img {
      height: 100%;
    }
  }

  @keyframes scroll {
    to {
      transform: translateX(calc(-50% - var(--gap) / 2));
    }
  }

  @media ${device.mobile} {
    .carousel-track {
      animation-duration: 100s;
    }
  }
`;
