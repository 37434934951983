import Form, { InputField, FormError, SubmitButton } from 'components/Form';
import useSignInForm from './useSignInForm';

const SignInForm = ({
  loading,
  error,
  handleForgot,
  handleSignup,
  handleSubmit,
}) => {
  const formik = useSignInForm(handleSubmit);

  return (
    <Form handleSubmit={formik.handleSubmit}>
      <InputField label="Email" name="email" type="email" formik={formik} />
      <InputField
        label="Password"
        name="password"
        type="password"
        formik={formik}
      />
      <FormError error={error} />
      <SubmitButton loading={loading}>Continue</SubmitButton>
      <p className="alternate">
        <span className="link" onClick={handleForgot}>
          Forgot Password
        </span>
      </p>
      <p className="alternate">
        Don't have an account?{' '}
        <span className="link" onClick={handleSignup}>
          Sign up
        </span>
      </p>
    </Form>
  );
};

export default SignInForm;
