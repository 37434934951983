import styled from 'styled-components';

export const StyledPlanPrice = styled.div`
  text-align: center;
  width: 100%;

  .plan-pricing-content {
    flex: 1;
    margin: 12px 0;

    .contact {
      color: ${({ theme }) => theme.textHighlight};
      font-weight: 600;
      font-size: 1.7rem;
      line-height: 130%;
    }

    .price {
      font-size: 3.5rem;
      font-weight: 900;
      line-height: 40px;
      color: ${({ theme }) => theme.textHighlight};

      sub {
        font-size: 1.2rem;
        vertical-align: baseline;
        font-weight: 400;
      }
      sup {
        font-size: 1.2rem;
        vertical-align: top;
        line-height: 0.7;
        font-weight: 400;
        margin-right: 4px;
      }
    }
    p {
      font-size: 1.2rem;

      span {
        font-size: 1rem;
        opacity: 0.6;
      }
    }
  }
`;
