import { StyledFooter } from './Footer.styled';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/persona-logo.svg';
import { ReactComponent as Cards } from 'assets/images/icons/payment-cards.svg';
import privacy from 'assets/docs/privacy-policy.pdf';
import conditions from 'assets/docs/terms-conditions.pdf';

const Footer = () => {
  return (
    <StyledFooter>
      <hr />
      <div className="footer-content">
        <div className="company">
          <Link to="/" className="site-logo" aria-label="home">
            <Logo />
          </Link>
          <p>
            We give creators unlimited access to music used in Hollywood movies
            and primetime TV shows, helping them take their content to the next
            level.
          </p>
        </div>

        <div className="link-set">
          <div className="links-category">Quick Links</div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/music">Music</Link>
            </li>
            <li>
              <Link to="/playlists">Playlists</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
          </ul>
        </div>

        {/* <div className="link-set">
          <div className="links-category">AI Tools</div>
          <ul>
            <li>
              <Link to="/search">Similar Sound Search</Link>
            </li>
            <li>
              <Link to="/aisearch">Open AI powered Search</Link>
            </li>
            <li>
              <Link to="/music">On Demand Stem Creation</Link>
            </li>
          </ul>
        </div> */}

        <div className="link-set">
          <div className="links-category">Help & Support</div>
          <ul>
            <li>
              <Link to="/faq">Faq</Link>
            </li>
            <li>
              <Link to={privacy} target="_blank">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to={conditions} target="_blank">
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="copyright">
        Copyright@2023 Persona <Cards />
      </div>
    </StyledFooter>
  );
};

export default Footer;
