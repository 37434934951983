import { FC } from 'react';
import AppSumoRating from 'components/AppSumo/AppSumoRating';
import ActionArrowButton from 'components/Button/ActionArrowButton/ActionArrowButton';
import keywordImage from 'assets/images/keyword.png';

const KeywordSearch: FC = () => {
  return (
    <div className="section-content">
      <div>
        <h2>AI Analyzed Filter and Keyword Tags</h2>
        <h4>
          Putting the “cherry on top” of the most robust search experience
          imaginable
        </h4>
        <div className="media mobile">
          <img src={keywordImage} alt="Keyword Search" />
        </div>
        <p>
          If you are inclined to keep it old-school. Get music suggestions that
          are precisely aligned with your filter tag criteria, eliminating
          guesswork while allowing you to paint in broad strokes with your
          search.
        </p>
        <p>
          Our AI filter tags analyze hundreds of acoustic features like key,
          tempo, Sonic Fingerprint, and instruments to create perfect metadata
          tags.Spend less time searching and more time creating, thanks to the
          most accurate and detailed tagging system in the industry.
        </p>
        <ActionArrowButton $primary>Start Free Now</ActionArrowButton>
        <AppSumoRating />
      </div>
      <div className="media desktop">
        <img src={keywordImage} alt="Keyword Search" />
      </div>
    </div>
  );
};

export default KeywordSearch;
