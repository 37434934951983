import styled from 'styled-components';
import { StyledLandingSection } from 'pages/LandingPage/LandingPage.styled';
import { device } from 'assets/styles/device';

export const StyledLandingAI = styled(StyledLandingSection)`
  .section-content {
    display: grid;
    grid-template-columns: 0.6fr 0.94fr;
    align-items: center;
    gap: 0 5.875rem;

    h2 {
      margin-block-start: 0;
    }

    .media {
      inline-size: 100%;
      aspect-ratio: 16/9;
      border-radius: 2rem;
      overflow: hidden;
      border: 1px solid #ffffff2b;
      background: #000;

      &.mobile {
        display: none;
      }

      video,
      img {
        inline-size: 100%;
        block-size: 100%;
        object-fit: contain;
      }

      video {
        object-fit: contain;
      }
    }
  }

  @media ${device.mobile} {
    text-align: center;

    .section-content {
      display: block;
      gap: 0;

      .bullet-points {
        margin-block: 0.75rem 0;
      }

      .media {
        block-size: auto;
        margin-block-end: 2rem;
        grid-column: auto;
        grid-row: auto;
        aspect-ratio: 1.675;
        border-radius: 0.5rem;

        &.mobile {
          display: block;
        }

        &.desktop {
          display: none;
        }
      }
    }
  }
`;
