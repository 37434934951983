import { useAudio } from 'hooks';
import { StyledProgress, StyledBarProgress } from '../Player.styled';
import Waveform from 'components/Tracks/Waveform/Waveform';
import { numToTime } from 'utils';

const ProgressComponents = ({ track, handleSeek, mobile, hasSegment }) => {
  const { currentTime } = useAudio(true);

  const segmentStart =
    hasSegment && track.segment_start !== undefined
      ? track.segment_start
      : null;

  return (
    <StyledProgress>
      <StyledBarProgress $progress={(currentTime / +track.length) * 100} />
      {!mobile && (
        <>
          <Waveform
            large
            id={track.id}
            peaks={track.waveform}
            trackLength={+track.length}
            progress={currentTime / +track.length}
            handleSeek={(e, start) => handleSeek(start)}
            segmentStart={segmentStart}
          />
          <div className="time">
            {/* {numToTime(currentTime) + ' / ' + numToTime(+track.length)} */}
            {numToTime(currentTime)}
          </div>
        </>
      )}
    </StyledProgress>
  );
};

export default ProgressComponents;
