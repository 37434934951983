const initialState = {
  album: null,
  tracks: [],
  limit: 30,
  nextPage: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALBUM_PRELOAD':
      return {
        ...state,
        album: action.payload,
      };

    case 'GET_ALBUM_LOADING':
    case 'GET_MORE_ALBUM_TRACKS_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'GET_ALBUM_SUCCESS':
      const {
        results: { tracks, ...album },
        track_next,
      } = action.payload;

      const imageDetails = {
        cover_src: album.cover_src || state.album?.cover_src,
        defaultImg: !album.cover_src,
      };

      return {
        ...state,
        album: { ...album, ...imageDetails },
        tracks,
        nextPage: track_next,
        loading: false,
        error: null,
      };

    case 'GET_MORE_ALBUM_TRACKS_SUCCESS':
      return {
        ...state,
        tracks: [...state.tracks, ...action.payload.results],
        nextPage: action.payload.track_next,
        loading: false,
        error: null,
      };

    case 'GET_ALBUM_ERROR':
    case 'GET_MORE_ALBUM_TRACKS_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case 'CLEAR_ALBUM':
      return {
        ...state,
        album: null,
      };

    default:
      return state;
  }
};

export default reducer;
