import { SubscriptionType } from 'types/user';

export const SUBSCRIPTION_TYPES: Record<string, SubscriptionType[]> = {
  socialcreator: [
    {
      name: 'Social Creator',
      text: `Perfect for any type of social media content creator`,
      features: `Music + keyword search`,
      type: 'personamusic_tier1',
      priceMonth: 4.17,
      priceAnnual: 50.0,
      covers: [
        'YouTube',
        'Facebook',
        'Instagram',
        'Twitch',
        'Podcasts',
        'TikTok',
      ],
    },
    {
      name: 'Social Creator Plus',
      text: `Perfect for any type of social media content creator`,
      features: `Music + keyword search + AI suite of: Conversational AI Search, Free Search Sounds Similar Search, On Demand Stem Creation`,
      type: 'personamusic_tier1_2',
      priceMonth: 9.72,
      priceAnnual: 116.67,
      covers: [
        'YouTube',
        'Facebook',
        'Instagram',
        'Twitch',
        'Podcasts',
        'TikTok',
      ],
    },
    {
      name: 'Social Creator Premium',
      text: `Perfect for any type of social media content creator`,
      features: `Music + keyword search + AI suite of: Conversational AI Search, Free Search Sounds Similar Search, On Demand Stem Creation + SFX`,
      type: 'personamusic_tier1_3',
      priceMonth: 12.5,
      priceAnnual: 149.99,
      covers: [
        'YouTube',
        'Facebook',
        'Instagram',
        'Twitch',
        'Podcasts',
        'TikTok',
      ],
    },
  ],
  creatorpro: [
    {
      name: 'Creator Pro',
      text: `A creator or team of 2 with multiple accounts per social platform`,
      features: `Music + keyword search`,
      type: 'personamusic_tier2',
      priceMonth: 7.08,
      priceAnnual: 84.99,
      covers: [
        'Unlimited social platforms',
        'Paid ads',
        'Client work',
        'Commercial work',
        'Podcasts',
        'Websites & all online platforms',
      ],
      highlight: true,
    },
    {
      name: 'Creator Pro Plus',
      text: `A creator or team of 2 with multiple accounts per social platform`,
      features: `Music + keyword search + AI suite of: Conversational AI Search, Free Search Sounds Similar Search, On Demand Stem Creation`,
      type: 'personamusic_tier2_2',
      priceMonth: 15.97,
      priceAnnual: 191.65,
      covers: [
        'Unlimited social platforms',
        'Paid ads',
        'Client work',
        'Commercial work',
        'Podcasts',
        'Websites & all online platforms',
      ],
    },
    {
      name: 'The Omnipotent Node Package',
      text: `A creator or team of 2 with multiple accounts per social platform`,
      features: `Music + keyword search + AI suite of: Conversational AI Search, Free Search Sounds Similar Search, On Demand Stem Creation + SFX`,
      type: 'personamusic_tier2_3',
      priceMonth: 20.83,
      priceAnnual: 249.99,
      covers: [
        'Unlimited social platforms',
        'Paid ads',
        'Client work',
        'Commercial work',
        'Podcasts',
        'Websites & all online platforms',
      ],
    },
  ],
  omnipotentnode: [
    {
      name: 'The Omnipotent Node Package',
      text: `A creator or team of 2 with multiple accounts per social platform`,
      features: `Music + keyword search + AI suite of: Conversational AI Search, Free Search Sounds Similar Search, On Demand Stem Creation + SFX`,
      type: 'personamusic_tier3',
      priceMonth: 20.83,
      priceAnnual: 249.99,
      covers: [
        'Unlimited social platforms',
        'Paid ads',
        'Client work',
        'Commercial work',
        'Podcasts',
        'Websites & all online platforms',
      ],
    },
  ],
  enterprise: [
    {
      name: 'Enterprise',
      text: `A team of more than 2 people or require broadcast, VOD & theatrical`,
      features: `Music + keyword search`,
      type: 'enterprise',
      covers: [
        'Unlimited social platforms',
        'Paid ads',
        'Client work',
        'Commercial work',
        'Podcasts',
        'Websites & all online platforms',
        'Broadcast & TV',
      ],
      disabled: true,
    },
  ],
};

export const SUBSCRIPTION_APPSUMO_UPGRADE: SubscriptionType = {
  ...SUBSCRIPTION_TYPES['creatorpro'][2],
  type: 'personamusic_tier4_3',
  priceMonth: 12.5,
  priceAnnual: 149.99,
  compareAtMonth: SUBSCRIPTION_TYPES['creatorpro'][2].priceMonth,
  compareAtAnnual: SUBSCRIPTION_TYPES['creatorpro'][2].priceAnnual,
};

const PersonalTooltip = () => (
  <>
    <h3>{SUBSCRIPTION_TYPES.socialcreator[0].name}</h3>
    <p>
      For usage on all social and user generated content platforms, including:
    </p>
    <ul>
      <li>Unlimited use</li>
      <li>Full access to music library</li>
      <li>Full access to top creator playlists</li>
      <li>Tracks downloadable by stems for easy mixing</li>
      <li>New music and playlists dropping all the time</li>
      <li>
        All video types and platforms except for feature film, Broadcast TV, and
        VOD
      </li>
    </ul>
  </>
);

const CreatorTooltip = () => (
  <>
    <h3>{SUBSCRIPTION_TYPES.creatorpro[0].name}</h3>
    <p>
      For usage on all social and user generated content platforms, including:
    </p>
    <ul>
      <li>Unlimited use</li>
      <li>Full access to music library</li>
      <li>Full access to top creator playlists</li>
      <li>Tracks downloadable by stems for easy mixing</li>
      <li>New music and playlists dropping all the time</li>
      <li>
        All video types and platforms except for feature film, Broadcast TV, and
        VOD
      </li>
    </ul>
  </>
);

export const getSubscriptionByType = (sub: SubscriptionType) => {
  const allPlans = Object.values(SUBSCRIPTION_TYPES).flat();
  const activePlan = [...allPlans, SUBSCRIPTION_APPSUMO_UPGRADE].find(
    (plan) => plan.type === sub?.type
  );

  if (!activePlan) return null;

  return { ...activePlan, ...sub };
};

export const SUBSCRIPTION_TOOLTIPS = {
  personal: PersonalTooltip,
  creator: CreatorTooltip,
};
